import Constants from '../../lib/constants'
import RestHelperDetail from "../../lib/api/resthelperdetail.class";
import moment from "moment-timezone";

class Controller {
    constructor($scope, $http, $controller, DataStore, WebinarService) {
        'ngInject';
        //@todo: i think its better torequest the gorup memberships only if needed;
        this.$http = $http;
        $scope.DataStore = DataStore
        $scope.Constants = Constants
        this.$scope = $scope
        this.WebinarService = WebinarService
        this.em = null
        this.$controller = $controller
        this.webinars = []
        this.allWebinars = []


        this.enrollment = null;
        this.webinar = null;

    }

    $onInit() {
        let options = {
            name: 'User',
            defaultValues: {},
            params: { 'includes[]': ['enrollments', 'enrollments.webinars'] }
        };

        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/users', options);
        em.setDetailId(this.userid);
        em.run()
        this.em = em

        this.WebinarService.getUpcomingWebinars()
            .then((res) => this.allWebinars = res.data)


        // @todo: get webinars


    }

    updateWebinars() {
        if (!this.enrollment) {
            this.webinars = [];
            return;
        }
        let programId = this.enrollment.program.id;
        let role = this.enrollment.role;
        // @todo it should also filter mentor
        this.webinars = _.filter(this.allWebinars, (w) => w.programId === programId && w.targetAudience === role)
    }

    registerForWebinar(enrollmentId, webinarId) {

        let url = 'admin/enrollments/' + enrollmentId + '/enrollWebinar/' + webinarId
        this.$http.post(url, {})
            .then(() => this.em.refresh())

    }


}

const component = {
    template: require('./user.webinars.component.html'),
    controller: Controller,
    bindings: {
        userid: '<',
        defaultType: '<'
    }
}

export default  component;

