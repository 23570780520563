import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $stateParams, $httpParamSerializer) {
        'ngInject';
        let options = {
                name: 'Appointment',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '',
                    'includes[]': ['mentorship', 'mentorship.mentorParticipation', 'mentorship.menteeParticipation', 'mentorship.program'],

                },
                sort: { 'planned_start': 'desc' },

            }
        ;
        this.processFilter($stateParams.filter, options);
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/appointments', options);
        em.run();

    }

    processFilter(filter,options) {
        if (!filter) {
            return;
        }

        switch (filter) {
            // case 'first':
            //     options.forceFilter =
            //     [{ key: 'plannedStart', value: start, operator: 'gt' },
            //         { key: 'plannedStart', value: end, operator: 'lt' }]
            // ;
            // break;

            case 'today':
                var start = new Date();
                start.setHours(0, 0, 0, 0);

                var end = new Date();
                end.setHours(23, 59, 59, 999);

                options.sort = { 'planned_start': 'asc' };
                options.forceFilter =
                    [{ key: 'plannedStart', value: start, operator: 'gt' },
                        { key: 'plannedStart', value: end, operator: 'lt' }]
                ;
                break;
        }


    }

    isPast(app) {
        if (!app.plannedStart) {
            return false
        }
        return (!(new Date(app.plannedStart).getTime() >= new Date().getTime()))
    }
}

const component = {
    template: require('./appointments.component.html'),
    controller: Controller,
}

export default  component;
