'use strict';


//
// @todo
//


class CurrentUserService {
    constructor($rootScope, $q, $log, $state, $http, AuthenticationService, AlertsService, UserOnlineService) {
        'ngInject';

        const self = this;
        this.$logger = $log.getInstance('CurrentUserService');
        this.$http = $http;
        this.$rootScope = $rootScope;
        this.AlertService = AlertsService;
        self.currentUser = null;

        $rootScope.$on('auth.authenticated', () => {
            self.$logger.info('authenticated')
            self.refreshCurrentUser();
        });
        $rootScope.$on('auth.deauthenticated', () => {
            self.currentUser = null;
        });
        if (AuthenticationService.hasCredentials()) {
            self.refreshCurrentUser();
        }
    }
    hasRole(role){
        role = role.toLowerCase();

        return this.currentUser && this.currentUser.roles && this.currentUser.roles.indexOf(role) !== -1
    }
    hasOneRole(roles){
        return _.find(roles, r => this.hasRole(r)) !== undefined
    }

    getUser() {
        // @todo attach methods to suer
        return this.currentUser;
    }

    inject(scope) {
        scope.currentUser = this;
    }

    refreshCurrentUser() {

        const self = this
        this.currentUser = this.$http.get('/users/me')
            .then((response) => {
                let user = response.data.data;

                self._attachMethods(user);
                self.currentUser =  user;

                self.$logger.info('go new user', user);
                self.$rootScope.$emit('auth.userupdate', user);

            })

    }

    _attachMethods(user) {
        // user.hasRole = () => {} etc;

    }

    get(target, prop) {
        if (!this.currentUser) {
            return undefined;
        }
        return this.currentUser[prop];
    }

    resetPassword(request) {
        this.$http.post('/admin/reset-password/',  request)
            .then((res) => this.AlertService.showConstant('success', 'Password Reset'))
    }

}


export default  CurrentUserService;
