'use strict';


export default  AuthenticationService;

function AuthenticationService($rootScope, $q, $log, $state, $http, Restangular) {
    //$localStorage
    'ngInject';

    const self = this;
    const $logger = $log.getInstance('Authentication');

    if (hasCredentials()) {
        bootstrap()
    }

    return {
        bootstrap: bootstrap,
        login: login,
        logout: logout,
        requireAuth: requireAuth,
        getAccessToken: getAccessToken,
        hasCredentials: hasCredentials,

    };

    function bootstrap() {
        $logger.info('bootstrap');
        if (_updateHeaders()) {
            $logger.info('auth:autneticated event')
            $rootScope.$emit('auth.authenticated')
            return $q.when()
        }
        return $q.reject()
    }



    function requireAuth() {
        if (getCredentials() == null) {
            $logger.info('missign session, redirecting to login');
            $state.go('auth.login');
            return;
        }
        // @todo: fetch user?
        return $q.when();
    }

    function getCredentials() {
        var json = localStorage.getItem('credentials')
        if (!json)
            return null;
        return JSON.parse(json)
    }

    function hasCredentials() {
        return getCredentials() !== null;
    }

    function setCredentials(credentials) {
        if (credentials === null) {
            localStorage.removeItem('credentials');
        }
        return localStorage.setItem('credentials', JSON.stringify(credentials)) || null;

    }

    function login(data) {
        logout();
        return $http.post('APIBASE/api/v2/auth', data)
            .then((response) => {
                if (!response) {
                    return $q.reject();
                }
                setCredentials(response.data);
                _updateHeaders();

                $rootScope.$broadcast('auth.authenticated', response.data);
            });
    }

    function logout() {
        setCredentials(null);
        _updateHeaders();
        return $q.when();

    }

    function _updateHeaders() {
        let credentials = getCredentials();

        if (!credentials || credentials.access_token === '') {
            delete $http.defaults.headers.common['access_token'];

            // RestangularProvider.setDefaultHeaders({access_token: ""});
            return false;
        }
        if ($http.defaults.headers.common['access_token'] !== credentials.access_token) {
            $http.defaults.headers.common['access_token'] = credentials.access_token
            // RestangularProvider.setDefaultHeaders({access_token: credentials.access_token});
            $logger.info('new header', $http.defaults.headers.common)
            return true
        }
        return true;

    }


    function getAccessToken() {
        let credentials = getCredentials();
        return (credentials ? credentials.access_token : null);
    }


};
