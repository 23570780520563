class Controller {

    convert(e) {
        if (!e) {
            return 'N/A';
        }
        let daysDiff = this.diff(new Date(e), new Date());

        if (this.untilMode) {
            daysDiff = daysDiff * -1;
        }
        this.daysDiff = daysDiff;

        return this.daysDiff;

    }

    diff(date1, date2) {   //Get 1 day in milliseconds
        var one_day = 1000 * 60 * 60 * 24;    // Convert both dates to milliseconds
        var date1_ms = date1.getTime();
        var date2_ms = date2.getTime();    // Calculate the difference in milliseconds
        var difference_ms = date2_ms - date1_ms;        // Convert back to days and return
        return Math.round(difference_ms / one_day);
    }
}


const component = {
    template: `
        <span class="badge" ng-class="{'badge-danger': $ctrl.daysDiff<0}" uib-tooltip="{{$ctrl.date | date}}"><i class="fa fa-clock"></i> {{$ctrl.convert($ctrl.date)}}</span>
`,
    bindings: {
        date: '<',
        untilMode: '<'
    },
    controller: Controller


};


export default  component;
