    import Constants from '../lib/constants'

/*
'userStates': ['NEW','REGISTERED','QUIT','BANNED'],
    'webinarStates': ['NEW','PLANNED','CANCELED','SUCCESS'],
    'mentorshipStates': ['NEW','UNCONFIRMED','CONFIRMED','REJECTED','ACTIVE','PAUSED','DONE','CANCELED'],
    'appointmentStates': ['NEW','PLANNED','CANCELED','NOSHOW','ONLINE','NOATTENDANCE','SUCCESS','HANGUP'],
    'organizationStates': ['NEW','READY','ACTIVE','EXIT'],
    'enrollmentStates': ['NEW','AVAILABLE','QUIT','ACTIVE','POSTPONED'],


 */
class Controller {

    constructor() {

    }

    $onInit(){
        this.states = this.getStatesForEntity(this.entityname.toLowerCase())
    }

    /**
     *
     * @param entityName
     * @returns {*}
     */
    getStatesForEntity(entityName){
        let states = Constants(entityName + 'ProcessStates');
        if (!states){
            console.error('[entityfunnel] unknown entity', entityName);
        }
        return states;

    }
    getClass(entityName, state){
        // @todo
    }
}

const component = {
    template: `
    <div ng-if="$ctrl.states">
        <span 
            class="label"  
            ng-if="$ctrl.states.indexOf($ctrl.state) !== -1" 
            ng-repeat="step in $ctrl.states" 
            uib-tooltip="{{step}}"
            ng-class="{'label-info': step == $ctrl.state, 'label-default': step != $ctrl.state}">
            <span ng-if="!$ctrl.hideLabels">{{step}}</span>&nbsp;
         </span>
        <span uib-tooltip="{{$ctrl.state}}" class="label label-info" 
            ng-if="$ctrl.states.indexOf($ctrl.state) === -1">{{$ctrl.state || 'noState'}}
        </span>
    </div>
`,

    //One-way (“<”) data bindings; Expressions (“&”) bindings
    // Evaluated DOM attribute (“@”) bindings
    // = two way binding
    bindings: {
        entityname: '<',
        hideLabels: '<',
        state:'<'
    },
    controller: Controller
};


export default  component;
