import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $scope, $rootScope, $http, $uibModalStack, $stateParams, MentorshipService, StateMachineService, AlertsService) {
        'ngInject';
        let options = {
            name: 'Mentorship',
            defaultValues: {},
            params: {'includes[]': ['mentorParticipation', 'menteeParticipation', 'mentorParticipation.enrollment', 'menteeParticipation.enrollment', 'licences']}
        };

        this.$http = $http;
        this.$uibModalStack = $uibModalStack;
        this.StateMachineService = StateMachineService;
        this.AlertsService = AlertsService

        this.id = $stateParams.id;

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/mentorships', options);
        em.setDetailId($stateParams.id);
        em.run()


        this.$scope = $scope;
        this.$rootScope = $rootScope;

        this.mentorshipTransition = null;
        this.participationTransitions = {}
        this.userComment = null;
        this.step = 0;

        this.$scope.model
        this.em = em;
    }

    next() {
        this.step++;
    }

    back() {
        this.step--;
    }

    applyEnrollmentTransition(id, action) {
        return this.StateMachineService.transition('enrollment', id, action);
    }

    applyMentorshipTransition(id, action) {
        return this.StateMachineService.transition('mentorship', id, action);
    }

    async processParticipation(id, action) {
        const part = this.$scope.model.mentorParticipation.id === id
            ? this.$scope.model.mentorParticipation
            : this.$scope.model.menteeParticipation;

        switch (action) {
            case 'rematch':
                return this.applyEnrollmentTransition(id, 'participate');
            case 'cancel':
                if (part.enrollment.state === 'QUIT') {
                    return;
                }
                return this.applyEnrollmentTransition(id, 'quit');
            case 'noreply':
                if (part.enrollment.state === 'NOREPLY') {
                    return;
                }
                return this.applyEnrollmentTransition(id, 'noreply');
            default:
                throw new Error('Custom: unexpected action')
        }
    }

    async submit() {
        if (Object.keys(this.participationTransitions).length < 2 || !this.mentorshipTransition) {
            return;
        }

        const promises = [];
        for (let id in this.participationTransitions) {
            promises.push(this.processParticipation(id, this.participationTransitions[id]))
        }

        const mentorshipId = this.$scope.model.id;
        promises.push(this.applyMentorshipTransition(mentorshipId, this.mentorshipTransition))

        await Promise.all(promises);
        this.AlertsService.showConstant('success', 'Success');
        this.$uibModalStack.dismissAll();
    }
}

const component = {
    template: require('./cancel-mentorships-wizard.component.html'),
    controller: Controller
};
export default component;
