import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, DataStore, ConferenceService, $http, $scope) {
        'ngInject';

        $scope.DataStore = DataStore;

        this.$http = $http;
        this.$scope = $scope;
        this.program = null;
        this.ConferenceService = ConferenceService;
    }

    /**
     *
     * @param programid optional
     */
    async updateProgram(programid) {
        const self = this;
        var pgid = programid || (self.program ? self.program.id : null);


        this.urls = await this.ConferenceService.getPreviewRoomUrlForRoomId(programid, true)
        this.betaUrls = await this.ConferenceService.getBetaUrlForProgram(programid, true);
        this.$scope.$apply();
        ;


    }

    copyToClipboard(text) {


        var inp = document.createElement('input');
        document.body.appendChild(inp);//
        inp.value = text; //that.textContent
        inp.select();
        document.execCommand('copy', false);
        inp.remove();


    }

    openPreviewRoom() {
        let self = this;

        this.ConferenceService.getPreviewRoomUrl(roomId, true)
            .then((url) => {
                self.roomUrl = url;
                window.open(url);
                return url;
            });
    }


}

const component = {
    template: require('./doDemo.component.html'),
    controller: Controller
};
export default component;
