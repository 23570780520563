import angular from 'angular';

const MODULE_NAME = 'admin-lecture';

import lecturer from './lecturer.component'
import lecture from './lecture.component'

angular.module(MODULE_NAME, [])
    .component('adminlecturer', lecturer )
    .component('adminlecture', lecture)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
    //@todo there has to be a smarter way
        .state('admin.lecturer', {
            abstract: true,
            url: '/lecturer',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.lecturer.index', {
            url: '/list',
            component: 'adminlecturer'
        })
        .state('admin.lecturer.detail', {
            url: '/:id',
            component: 'adminlecture'
        })
}


export default MODULE_NAME;