import Constants from '../lib/constants'

class Controller {

    constructor() {

    }

    $onInit() {
        this.isOpen = false;

        this.dateOptions = {
            isOpen: false
        };

    }

    openDatepicker() {
        this.dateOptions.isOpen = true;
    }

    onChooseEvent($item) {
        this.model = $item;

        if ($item && this.onChoose) {
            this.onChoose({ model: $item })
        }
    }
    setDateInWeeks(weeks){

        var date = new Date();
        date.setDate(date.getDate() + (weeks * 7));

        this.model = date;
        this.onChooseEvent(date);


    }

    setDateInDays(days){

        var date = new Date();
        date.setDate(date.getDate() + (days));

        this.model = date;
        this.onChooseEvent(date);


    }


}

const component = {
    template: `
  <div class=" ">
        <style>
        .uib-daypicker table tbody tr td.uib-day {
            padding:0;
            
        }
</style>
            <div class="input-group">
                <div class="form-control" ng-click="$ctrl.openDatepicker()">
                    {{$ctrl.model | date}}
                 </div>
                <input type="text" 
                              style="display: none;"
                       uib-datepicker-popup
                        ng-change="$ctrl.onChooseEvent($ctrl.model)"
                       ng-model="$ctrl.model"
                       is-open="$ctrl.dateOptions.isOpen"
                       datepicker-options="$ctrl.dateOptions"
                       close-text="Close"
                />
                <span class="input-group-btn">
                <button type="button"
                        class="btn btn-default"
                        ng-click="$ctrl.openDatepicker()"><i
                        class="fas fa-calendar"></i></button>
                </span>
            </div>

               <div class="btn-group" ng-if="$ctrl.futureDateHelper">
               <button class="btn btn-default btn-xs"
                                        ng-click="$ctrl.setDateInWeeks(0)"
                                >now</button>
                        <button class="btn btn-default btn-xs"
                                        ng-click="$ctrl.setDateInDays(2)"
                                >in 2 Days</button>

                                <button class="btn btn-default btn-xs"
                                        ng-click="$ctrl.setDateInDays(5)"
                                >in 5 Days</button>

                                <button class="btn btn-default btn-xs"
                                        ng-click="$ctrl.setDateInDays(10)"
                                >in 10 Days </button>
                                <button class="btn btn-default btn-xs"
                                        ng-click="$ctrl.setDateInWeeks(4)"
                                >in 4 wks </button>

                    </div>

            </div>


`,

    bindings: {
        model: '=',
        onChoose: '&',
        futureDateHelper: '<'

    },
    controller: Controller
};


export default  component;


