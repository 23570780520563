import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $http) {
        'ngInject';
        $scope.endpoint = 'users/me/availabilities';
        this.$http = $http;
        this.response = null;
        this.methods = ['GET','POST','DELETE'];
        this.method  = this.methods[0];
        this.url = '';

    }

    submitRequest() {

        const self = this
        this.$http.get(this.url)
            .then((res) => {
                self.response = res.data;
            })
            .catch((err) => self.response = err)
    }
}

const component = {
    template: require('./requesthelper.component.html'),
    controller: Controller,
}

export default  component;
