import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller,$state,$stateParams, DataStore) {
        'ngInject';
        this.$controller = $controller
        this.$scope = $scope;
        this.DataStore = DataStore;
        this.$stateParams = $stateParams;
        this.em;
        this.$state = $state;
    }

    async addNewDocument() {
        const {key} = this;

        const newModel = {
            key: key,
            internalTitle: 'Add internl title here',
            type: 'article',
            language: 'en',
            content: 'n/a',
        };
        const created = await this.em.createModel(newModel);
        const id = created.data.id;
        this.$state.go('^.edit', {key, id});
        this.em.refresh();
    }

    $onInit() {
        this.key = this.$stateParams.key;

        let options = {
                name: 'Brandeddocument',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '', 'includes[]': ['program'],
                    limit: 100,
                    sort: {'key': 'asc'},

                },
                forceFilter: [{key: 'key', value: this.key, 'operator': 'eq'}]
            }
        ;
        let em = this.$controller(RestHelperList);
        em.setup(this.$scope, 'admin/brandeddocuments', options);
        em.run();
        this.em = em;
    }


}

const component = {
    template: require('./documentsByKey.component.html'),
    controller: Controller,
    bindings: {
        filter: '<',
        embededView: '<'
    }
}

export default component;
