
import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $uibModal, $stateParams, $httpParamSerializer) {
        'ngInject';

        this.$uibModal = $uibModal;

        let options = {
                name: 'Enrollment',
                defaultValues: {},
                sort: { 'createdAt': 'desc' },
                params: {
                    usr_state: 'any', search: '',
                    'includes[]': ['program','user']
                }
            }
            ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/enrollments', options);
        em.run();


        this.sortOptions = [
            { label: 'Last state change', field: 'createdAt' },
            /*{ label: 'Organisation', field: 'organization_id' },
            { label: 'First Name', field: 'firstName' },
            { label: 'Last Name', field: 'lastName' }, */
            { label: 'Role', field: 'primaryRole' }
        ];



    }
}

const component = {
    template: require('./enrollments.component.html'),
    controller: Controller,
}

export default  component;
