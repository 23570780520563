'use strict';


class MentorshipService {
    constructor($rootScope, $q, $log, $state, $http, AlertsService, StateMachineService) {
        'ngInject';

        const self = this;
        this.$logger = $log.getInstance('MentorshipService');
        this.$http = $http;
        this.$rootScope = $rootScope;
        this.AlertsService = AlertsService;
        this.StateMachineService = StateMachineService;

        return this;

    }

    cancelMentorship(id, comment) {
        return  this.$http.post('admin/mentorships/' + id + '/cancel', { comment: comment })
            .then(() => this.AlertsService.showConstant('success', 'Mentorship canceled'));
    }

    refreshSessionCounter(id) {
        return this.$http.post('admin/mentorships/' + id + '/refreshSessionCount')
            .then(() => this.AlertsService.showConstant('success', 'Licence activated'));
    }

    mismatchMentorship(id) {
        return this.$http.post('admin/mentorships/' + id + '/mismatch')
            .then(() => this.AlertsService.showConstant('success', 'Mentorship was set to mismatch state'));
    }

    transition(id, action) {
        return this.StateMachineService.transition('mentorship', id, action)

    }

    attachLicence(id){
        return this.$http.post(`admin/licences/${id}/attach-licence`)
            .then(() => this.AlertsService.show('success', 'Licence was attached to a mentorship!'));
    }

}


export default  MentorshipService;
