import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

import moment from "moment";


class Controller {
    constructor($controller, DataStore, $scope, $http, $stateParams) {
        'ngInject';

        //
        // let lastQuarterNumber = 1;
        // let lastQuarterStart = moment().subtract(lastQuarterNumber, 'quarter').startOf('quarter');
        //
        // let lastQuarterEnd = moment().subtract(lastQuarterNumber, 'quarter').endOf('quarter');
        //
        // // Start date of current quarter = moment().startOf('quarter');
        // //
        // // End date of current quarter = moment().endOf('quarter');
        //
        // this.startDate = lastQuarterStart;
        // this.endDate = lastQuarterEnd;
        //
        // this.dateOptions = {
        //     maxDate: new Date(),
        //     datepickerMode:'month'
        // };


        $scope.DataStore = DataStore;
        this.DataStore = DataStore;

        this.$http = $http;
        this.$scope = $scope;
        this.params = {};
        this.funnelResults = {};


        this.visibleStates = {
            enrollment: ['NEW', 'ACTIVE'],
            mentorships: ['UNCONFIRMED', 'CONFIRMED', 'ACTIVE', 'PAUSED', 'DONE', 'CANCELED'],
        };
        this.refresh();

    }

    fetchProgramFunnel(program) {

    }

    refresh() {
        let programs = this.DataStore.get('programs');
        const self = this;

        _.each(programs, (program) =>
            this.$http.get('admin/reporting/programs/' + program.id + '/kpis')
                .then(res => self.process(program, res.data))
        );


        // let options = {
        //     params: {
        //         startDate: this.startDate,
        //         endDate: this.endDate,
        //     }
        // };
        // this.$http.post('APIadmin/reporting/investorkpis', options.params)
        //     .then((res)=> this.result = res.data)

    }

    process(program, data) {

        let programData = { enrollments: {}, mentorships: {}, matchable: {} };

        _.each(data.enrollmentStates, (row) => {
            _.set(programData.enrollments, row.state + '.' + row.role, row.total)
        })
        _.each(data.mentorshipStates, (row) => {
            _.set(programData.mentorships, row.state, row.total)
        })
        _.each(data.matchableParticipations, (row) => {
            _.set(programData.matchable, row.role, row.total)
        })


        /*
        "enrollmentStates": [
      {
        "total": 4,
        "role": "mentee",
        "state": "ACTIVE"
      },

         */


        this.funnelResults[program.id] = programData


    }
}

const component = {
    template: require('./funnelReport.component.html'),
    controller: Controller
};
export default  component;
