import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $http, $scope, Upload, $stateParams, BitlyService, ConferenceService, settings, DataStore) {
        'ngInject';
        let options = {
            name: 'Program',
            defaultValues: { language: 'en' },
            params: { 'includes[]': ['courses'] }
        };
        this.apiurl = settings.apiFullUrl;

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/programs', options);

        em.setDetailId($stateParams.id);
        em.addHook('AFTER_SINGLE_GET', (e) => {
            $scope.documentFilter = { 'program_id': e.id };
        });

        em.run()

        this.DataStore = DataStore;

        this.settings = settings;
        this.Upload = Upload;
        this.$http = $http;
        this.ConferenceService = ConferenceService;
        this.BitlyService = BitlyService;
        this.$scope = $scope;
        this.selectedCourse = null;

        this.em = em

        this.linkTypes = ['EVALUATION_LINK', 'WEBINAR_VIDEO', 'WEBINAR_DIALIN_LINK'];


    }


    upload(program, file) {
        let uploadUrl = this.apiurl + 'programs/' + program.id + '/cover';
        this.Upload.upload({
            url: uploadUrl,
            data: { file: file }
        }).then(function (resp) {

        }, function (resp) {
            console.error('Error status: ' + resp.status);
        }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        });
    }

    checkKey() {
        let self = this;

        this.ConferenceService.getCourseByKey(this.$scope.model.conferenceEntryUrl)
            .then((result) => {
                self.selectedCourse = result.data;
                return course;
            });

    }

    //
    getDemoRoom(roomId) {

        const self = this;

        this.ConferenceService.getPreviewRoomUrl(roomId, true)
        // .then((url) => self.BitlyService.shortUrl(url))
            .then((url) => self.roomUrl = url);
        alert('Please use our bit.ly account to shorten the URL before you send it. this very long URL sometimes gets broken in email tools.');

    }

    createPreviewRoom(program) {
        return this.ConferenceService.createPreviewRoom(program)
            .then(() => this.em.refresh())
            .then(() => alert('new demo room created'))


    }

    enforceCode(model) {
        model.code = model.code || ''
        model.code = model.code.replace(/[^\w\s!?]/g, '').toUpperCase();
    }

    removeLink($index) {

        this.$scope.model.customLinks.splice($index, 1);


    }

    addLink() {
        this.$scope.model.customLinks = this.$scope.model.customLinks || [];
        this.$scope.model.customLinks.push({});

    }
}

const component = {
    template: require('./program.component.html'),
    controller: Controller
};
export default  component;
