import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

import moment from "moment";


class Controller {
    constructor($controller, $scope, $http, settings, DataStore, $stateParams) {
        'ngInject';


        this.DataStore = DataStore;
        $scope.DataStore = DataStore;
        this.organization_id = null;


        // Start date of current quarter = moment().startOf('quarter');
        //
        // End date of current quarter = moment().endOf('quarter');

        this.dateOptions = {
            maxDate: new Date(),
            datepickerMode: 'month'
        };
        let latestMonth = moment().startOf('month').add(1, 'month');
        this.allMonth = [];
        for (let i = 0; i < 18; i++) {
            this.allMonth.push(latestMonth.subtract(1, 'month').format('YYYY-MM'));
            if (latestMonth.format('MM') === '01') {
                this.allMonth.push(latestMonth.format('YYYY'));
            }
        }

        this.allYears = [];

        for (let i = 0; i < 3; i++) {
            this.allYears.push(new Date().getFullYear() - i);
        }

        this.result = {};
        this.$http = $http;
        this.refresh();

        this.settings = settings;


    }

    getYearlyValue(program, year) {

        if (program == null) {
            let sum = 0;
            for (let program of this.programs) {
                sum += this.getYearlyValue(program, year);
            }
            return sum;

        }
        let total = 0;
        year = year + '';
        if (this.result[program.id]) {
            _.each(this.result[program.id], (value, key) => {
                if (key.substr(0, 4) === year) {
                    total += value.count;

                }
            })
        }
        return total;

    }

    getMonthlyValue(program, month) {
        if (month.length === 4) {
            return this.getYearlyValue(program, month);
        }
        if (program == null) {
            let sum = 0;
            _.each(this.result, (programNumbers) => {
                if (programNumbers[month]) {
                    sum += programNumbers[month].count;
                }
            });
            return sum;
        }
        if (this.result[program.id] && this.result[program.id][month]) {
            return this.result[program.id][month].count;
        }
        return '-';
    }

    /**
     *
     * @param quarter moment
     */
    setQuarter(quarter) {

        this.startDate = moment(quarter);
        this.endDate = moment(quarter).endOf('quarter');
        this.refresh();


    }

    refresh() {

        let programs = this.DataStore.get('programs');
        this.programs = programs;
        const self = this;

        _.each(programs, (program) =>
            this.$http({
                    method: "GET",
                    url: 'admin/reporting/programs/' + program.id + '/licences',
                    params: { organization: this.organization_id }
                }
            )
                .then(res => self.process(program, res.data))
        );


    }

    process(program, data) {
        this.result[program.id] = _.keyBy(data.result, (r) => r.month.substr(0, 7));
    }

    processData(data) {
        const self = this;
        this.result = data;
        // newRegistrationsByRole
        this.registrations = {};

        _.each(data.newRegistrationsByRole, (r) => {
            if (!self.registrations[r.primaryRole]) {
                self.registrations[r.primaryRole] = {};
            }
            self.registrations[r.primaryRole].newRegistrations = r.total;
        });
        _.each(data.registeredUsersByRole, (r) => {
            if (!self.registrations[r.primaryRole]) {
                self.registrations[r.primaryRole] = {};
            }
            self.registrations[r.primaryRole].total = r.total;
        });

    }
}

const component = {
    template: require('./licenceReport.component.html'),
    controller: Controller
};
export default  component;
