import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'
import RestHelperList from "../../lib/api/resthelperlist.class";


class Controller {
    constructor($controller, $scope, $http) {
        'ngInject';
        let date = new Date();
        date.setHours(date.getHours() - 3);
        this.forceFilter = [{ key: 'plannedStart', value: date, operator: 'gt' }];
        this.$http = $http;
        let options = {
                name: 'User',
                defaultValues: {},
                forceFilter: this.forceFilter,
                params: {
                    limit: 30,
                    usr_state: 'any',
                    'includes[]': ['mentorship', 'mentorship.mentorParticipation', 'mentorship.menteeParticipation', 'mentorship.program'],
                    // @todo: sort where plannedStatrt > now()
                },
                sort: { 'plannedStart': 'asc' },
            }
        ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/appointments', options);
        em.run();
        this.$scope = $scope;
        this.em = em
        this.$scope.countForReport =function(app) {
            return (app.mentorship.mentorParticipation && app.mentorship.mentorParticipation.enrollment.user.countForReporting)
                && (app.mentorship.menteeParticipation && app.mentorship.menteeParticipation.enrollment.user.countForReporting)
        }
    }

    filterFirstAppointments() {
        if (this.forceFilter.filter(e => (e.key==='lections_completed' && e.value===0 && e.operator==='eq')).length === 0){
            this.forceFilter.push({ key: 'lections_completed', value: 0,operator: 'eq'  });
        }
        this.em.setForceFilter(this.forceFilter);
        this.em.run()
    }

    refresh() {
        this.em.refresh();
    }
}

let template = `
<div class="card card-default">
            <div class="card-header">
                    <div class="pull-right">                        
                        <reloadbutton refresh="$ctrl.refresh"></reloadbutton>
                    </div>
                    
                    <div class="card-title">
                            Upcoming Appointments 
                            <button class="btn btn-sm" uib-tooltip="Show all appointments" ui-sref="admin.appointments.index"><i class="fa fa-clock"></i></button>
                            <button class="btn btn-sm" uib-tooltip="Show todays appointments" ui-sref="admin.appointments.index({filter:'today'})"><i class="fa fa-calendar"></i></button>
                            <button class="btn btn-sm" uib-tooltip="Show only first" ng-click="$ctrl.filterFirstAppointments()"><i class="fa fa-exclamation-triangle"></i></button>
                        </div>
                    </div>
                    <div class="panel-body">
                    
                <div id="upcomingAppointments">
                
                <div ng-show="isLoading" class="text-center loadingIndicator">
                        <spinner></spinner>
                    </div>
                    
                <table  ng-if="rows" class="table-clickable table table-slim" >
                        <tr>
                        <th width="20"></th>
                            <th>Name</th>    
                            <th>Date</th>
                            <th>Program</th>
                            <th>Mentor</th>
                            <th>Mentee</th>
                        </tr>
                        <tr  ng-repeat="app in rows" ng-if="countForReport(app)" >
                        <td>
                            <i class="fa  fa-exclamation-triangle" uib-tooltip="First appointment!" 
                                ng-if="app.mentorship.lectionsCompleted === 0"
                            ></i>
                            <span class="badge badge-default"
                                     ng-if="app.mentorship.lectionsCompleted > 0"
                            >{{app.mentorship.lectionsCompleted}}</span>
                            
                            
                        
                        </td>
                            <td>
                                                

                                <span ng-if="app.type !=='ONLINE'" class="badge badge-info">{{app.type}}</span>
                                <entityfunnel ng-if="app.type === 'ONLINE'" hide-labels="true" entityname="'appointment'" state="app.state"></entityfunnel>
                            </td>
                            <td><a ui-sref="admin.appointments.detail({id: app.id})">{{app.plannedStart | date:'short'}}</a></td>
                            <td>{{app.mentorship.program.code}}</td>
                            <td>
                                <span ng-if="app.mentorship.mentorParticipation">
                                    <username user="app.mentorship.mentorParticipation.user"></username>
                                </span>
                                <span ng-if="!app.mentorship.mentorParticipation"> Nobody</span>
                            </td>
                            <td>
                                <span ng-if="app.mentorship.menteeParticipation">
                                    <username user="app.mentorship.menteeParticipation.user"></username>
                                </span>
                                <span ng-if="!app.mentorship.menteeParticipation"> Nobody</span>
                            </td>
                       </tr>
                   </table>
               </div> 
             </div>
            </div>

`;

const component = {
    template: template,
    controller: Controller

};


export default  component;
