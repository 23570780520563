import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'

// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };
class Controller {
    constructor() {

        // let options = {
        //     name: 'User',
        //     defaultValues: {},
        //     params: {'includes[]': ['organization']}
        // };
        //
        // let em = $controller(RestHelperDetail);
        // em.setup($scope, 'admin/users', options);
        //
        // $scope.genders = Constants('genders')
        //
        // console.log('id', $stateParams.id)
        // em.setDetailId($stateParams.id);
        // em.run()

    }

    copyToClipboard(text) {


        var inp = document.createElement('input');
        document.body.appendChild(inp);//
        inp.value =text; //that.textContent
        inp.select();
        document.execCommand('copy', false);
        inp.remove();


    }
}

const component = {
    template: require('./shortprofile.component.html'),
    controller: Controller,
    bindings: {
        user: '<',
        view: '<' // admin || needed?
    },

};


export default  component;
