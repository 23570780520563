class BitlyService {
    constructor($log, $http, settings) {
        'ngInject';
        // @otod: waiting for access token
        this.config = {
            apikey: 'R_657cd25ec61c447097b38e3bbead31c0',
            // login: 'volunteervision'
        };
        this.$http = $http;
    }

    shortUrl(url) {
        var apikey = this.config.apikey;

        return this.$http.get('https://api-ssl.bitly.com/v3/shorten?access_token=' + apikey + '&longUrl=http%3F%2A%2Agoogle.com%2A')
            .then((res) => console.log(res))
    }


};

export default  BitlyService;
