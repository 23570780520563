export default function ($parse) {
    'ngInject';
    return {
        replace: true,
        template: `<div> 
            <ui-view></ui-view>
            
            <button ng-if="EntityHelper.save" ng-click="EntityHelper.save()" class="btn btn-primary"><i class="fa fa-save"></i></button>
            <button ng-if="EntityHelper.save" ng-click="EntityHelper.saveAndNew()" class="btn btn-secondary" uib-tooltip="Save and new" tooltip-placement="bottom"><i class="fa fa-plus-square"></i></button>
            <button  ng-if="EntityHelper.refresh" ng-click="EntityHelper.refresh()" class="btn btn-btn-white"><i class="fa fa-sync"></i></button>
            <button ng-if="EntityHelper.delete && model.id" ng-click="EntityHelper.delete()"  class="btn btn-danger"><i class="fa fa-trash"></i></button>
            <button ng-click="EntityHelper.dismiss()" class="btn btn-default"><i class="fa fa-times"></i></button>
        </div>`,
        restrict: 'E'
    }
}

