import RestHelperList from "../../lib/api/resthelperlist.class";

class Controller {
    constructor($controller, $state, $scope, $http) {
        'ngInject';
        this.$scope = $scope;
        this.$state = $state;
        this.$http = $http;
        this.currentState = this.$state.name;


    }

    isCurrentState(name) {
        return (this.$state.current.name === name);
    }

    goto(name) {
        this.$state.go(name, { id: this.organizationid });
    }
}

// <b>{{$ctrl.user.displayName}}<i class="fa fa-star" class="text-success" ng-if="$ctrl.user.servicelevel > 3.indexOf('PLUS') !== -1"></i></b>
let template = `
            <div>
                <button ng-click="$ctrl.goto('admin.organizations.detail')"
                        uib-tooltip="Organization View"
                        tooltip-placement="bottom"
                        class="btn" ng-class="{ 'btn-info': $ctrl.isCurrentState('admin.organizations.detail')}"
                         >
                        <i class="fa fa-building"></i>
                </button>
              <button 
                        ng-click="$ctrl.goto('admin.organizations.jf')"   
                        uib-tooltip="JF view"
                        tooltip-placement="bottom"
                        class="btn" ng-class="{ 'btn-info': $ctrl.isCurrentState('admin.organizations.jf')}"
                        ><i class="fa fa-handshake"></i>
                </button>
                <button 
                        ng-click="$ctrl.goto('admin.organizations.reporting')"
                        uib-tooltip="Reporting View"
                        tooltip-placement="bottom"
                        class="btn" ng-class="{ 'btn-info': $ctrl.isCurrentState('admin.organizations.reporting')}"
                        ><i class="fa fa-chart-line"></i>
                </button>
                
               </div>

`;

const component = {
    template: template,
    controller: Controller,
    bindings: {
        organizationid: '<',
    },


};


export default  component;
