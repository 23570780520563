export default function () {

    return {
        replace: true,
        scope: {
            items: '=',
            headers: '=',
            pagination: '=',
            total: '=',
            onPaginationUpdate: '&',
        },
        controller: function ($scope, $rootScope) {
            'ngInject';
            $scope.changePage = function (index) {
                $scope.pagination.page = index + 1
                this.onPaginationUpdate({
                    updatedPagination: $scope.pagination
                });
            }
            $scope.nextPage = function () {
                if ($scope.pagination.current_page < $scope.pagination.total_pages){
                    $scope.pagination.page = $scope.pagination.current_page +1;
                    this.onPaginationUpdate({
                        updatedPagination: $scope.pagination
                    });
                }
            }
            $scope.prevPage = function () {
                if ($scope.pagination.current_page > 1){
                    $scope.pagination.page = $scope.pagination.current_page -1;
                    this.onPaginationUpdate({
                        updatedPagination: $scope.pagination
                    });
                }
            }
        },
        template: require('./dataTable.template.html'),
        restrict: 'E'
    }
}


