// import angular from 'angular';

const MODULE_NAME = 'admin-report';
import 'ngmap'
import reports from './reports.component'

angular.module(MODULE_NAME, ['ngMap'])
    .component('adminreports', reports)

    .component('mapcomponent', require('./map.component').default)
    .component('investorReportComponent', require('./investorReport.component').default)
    .component('funnelReportComponent', require('./funnelReport.component').default)
    .component('licenceReportComponent', require('./licenceReport.component').default)
    .component('bookingReportComponent', require('./bookingReport.component').default)
    .component('happinessReportComponent', require('./happinessReport.component').default)
    .component('todoreportComponent', require('./todoReport.component').default)
    .component('userTrainingReportComponent', require('./userTrainingReport.component').default)
    .config(config)
;


function config($stateProvider) {
    'ngInject';
    $stateProvider
    //@todo there has to be a smarter way
        .state('admin.reports', {
            abstract: true,
            url: '/reports',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['programs'])
            }

        })
        .state('admin.reports.map', {
            abstract: true,
            url: '/map',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations', 'roles'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.reports.map.index', {
            url: '/index',
            component: 'mapcomponent'
        })
        .state('admin.reports.index', {
            url: '/list',
            component: 'adminreports'
        })
        .state('admin.reports.investorreport', {
            url: '/investors',
            component: 'investorReportComponent'
        })
        .state('admin.reports.licencereport', {
            url: '/licencereport',
            component: 'licenceReportComponent',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
            }
        })
        .state('admin.reports.bookingreport', {
            url: '/bookingreport',
            component: 'bookingReportComponent',
            
        })
        
        .state('admin.reports.todoreport', {
            url: '/todoreport',
            component: 'todoreportComponent',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
            }
        })
        .state('admin.reports.usersTraining', {
            url: '/users-trainings-report',
            component: 'userTrainingReportComponent',
        })
        .state('admin.reports.funnelreport', {
            url: '/funnel',
            component: 'funnelReportComponent'
        })
        .state('admin.reports.happinessreport', {
            url: '/happiness',
            component: 'happinessReportComponent',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
            }
        })
        .state('admin.reports.detail', {
            url: '/:id',
            component: 'adminreport'
        })
}


export default MODULE_NAME;
