export default function ($parse) {
    'ngInject';

    return {
        replace: true,
        template: `<div>
                <i class="pg-search"></i>
                <input type="input" ng-model="params.search" ng-model-options="{ debounce: 1000 }" ng-change="EntityHelper.refresh()" class="form-control" placeholder="Search">
                
                </div>`,
        restrict: 'E'
    }
}


