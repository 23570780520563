import Constants from '../../lib/constants'
import _ from 'lodash'
import RestHelperDetail from '../../lib/api/resthelperdetail.class'

class Controller {
    constructor($controller, $http, $scope) {
        'ngInject';
    }

    $onInit() {

        // let options = {
        //         name: 'Supportanswer',
        //         defaultValues: {},
        //         params: {
        //             // usr_state: 'any', search: '',
        //             'includes[]': ['relations'],
        //         }
        //     }
        // ;
        // this.em = this.$controller(RestHelperDetail);
        // this.em.setup(this.$scope, 'admin/supportanswers', options);
        // this.em.setDetailId(this.id);
        // this.em.run();
    }




}

const component = {
    template: require('./inbound.component.html'),
    controller: Controller,
    bindings: {
    }
};
export default  component;
