import BaseBreadService from '../../services/BaseBreadService'


class RegistrationCodeService extends BaseBreadService {
    constructor($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService) {
        'ngInject';
        super($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService);
        this.$log = $log
        this.endpoint = 'admin/registrationcodes';
    }

    getForOrganization(organizationId) {

        const filters = [{ "key": "code_organization", "value": organizationId, "operator": "eq" }];

        // @todo: move this into the getList function
        const filtergroups = JSON.stringify([{
            'filters': filters
        }]);

        return this.getList({ query: { filter_groups: filtergroups } });
    }


};

export default  RegistrationCodeService;
