class Controller {

    constructor($scope, $http, $uibModalStack, AlertsService) {
        'ngInject';

        this.$http = $http
        this.$uibModalStack = $uibModalStack
        this.detectedMCB = ''
        this.mcbs = null
        this.selectedMCB = null
        this.editMCB = false
        this.isLoading = true
        this.AlertsService = AlertsService

    }

    $onInit() {
        this.participation1 = this.resolve.participation1
        this.participation2 = this.resolve.participation2
        this.fetchAllMCBs().then(() => this.detectMCB()
            .then(() => this.isLoading = false))

    }

    fetchAllMCBs(){
        return this.$http.get('admin/mcbs/').then((res) => {
            this.mcbs = _.map(res.data.data, (row) => row)
        })
    }
    detectMCB() {
       return this.$http.post('admin/participations/' + this.participation1.id + '/mcb', {participationId: this.participation2.id}).then((res) => {
            this.selectedMCB = res.data.data
            this.detectedMCB = res.data.data
        })

    }
    confirm() {
        const self = this
        const mcb = this.selectedMCB === 'ignore' ? this.selectedMCB : this.selectedMCB.id
        this.matchParticipations(this.participation1, this.participation2, mcb).then(function () {
            return self.close();
        })
    };
    matchParticipations(a, b, mcb) {
        return this.$http.post('admin/participations/' + a.id + '/match', {participationId: b.id, mcb: mcb})
            .then((res) => {
                    this.AlertsService.showConstant('success', 'Successfully matched');
                }
            )
            .catch((res) => {

            })


    }

    cancel() {
        this.dismiss()
    };

}

const component = {
    template: require('./confirm-mentorship.component.html'),
    controller: Controller,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
}

export default  component;
