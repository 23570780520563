import Constants from '../lib/constants'


class Controller {

    constructor($http) {
        'ngInject';
        this.languages = ['en', 'es', 'de'];
        this.currentValues = {};
    }


    $onInit() {
        try {
            this.currentValues = JSON.parse(this.textjson);
        } catch (e) {

        }
        this.currentValues = this.currentValues || {};
    }

    update(lang, value) {
        let json = {};
        try {
            json = JSON.parse(this.textjson);
        } catch (e) {
        }
        json = json || {};
        json[lang] = value;

        this.textjson = JSON.stringify(json);
    }

}

const component = {
    template: `
    <div>
    
        <div ng-repeat="lang in $ctrl.languages"
        class="row"
        >
            <div class="col-md-2">{{lang}}</div>
            <div class="col-md-10">
                <input type="text" ng-if="$ctrl.type != 'textarea'" class="form-control" ng-model="$ctrl.currentValues[lang]" ng-change="$ctrl.update(lang, $ctrl.currentValues[lang])">
                <textarea row="3" ng-if="$ctrl.type == 'textarea'" class="form-control" ng-model="$ctrl.currentValues[lang]" ng-change="$ctrl.update(lang, $ctrl.currentValues[lang])">
            </div>
        </div>
        
    </div>
`,

    bindings: {
        textjson: '=',
        type:'<'
    },
    controller: Controller
};


export default  component;
