import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'

import _ from 'lodash'

class Controller {
    constructor($controller, $scope, $stateParams) {
        'ngInject';

        let options = {
            name: 'Lead',
            defaultValues: {
                language:'de'
            }
        };
        this.locales = Constants('locales')

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/userleads', options);
        em.setDetailId($stateParams.id);
        em.run()
    }
}

const component = {
    template: require('./lead.component.html'),
    controller: Controller
};
export default  component;
