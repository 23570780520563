import _ from 'lodash';
import Constants from '../constants'

/****
 * Explanation:
 *  -> Abstraction for CRUD Operations
 *  -> is Made for 'list' and 'detail' views
 *  -> sample: see webinars.controller
 *  -> provides 'editModal'

 */
/**HOOKS:
 *
 * em.addHook('AFTER_SINGLE_GET', fn)
 *  events:
 *  AFTER_SINGLE_GET
 *  AFTER_LIST_GET
 *  AFTER_SAVE
 *  ...
 *
 *
 * @type {{}}
 */
// here we locally store parameter so if I go on a page and than back and forth  the parameter stays on an endpoint basis;/
// I wonder if this has any implications on predefiend filters?

let STORED_PARAMETER = {};

class RestHelper {
    constructor($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService) {
        'ngInject';
        this.$log = $log;
        this.$state = $state;
        this.$confirm = $confirm;
        this.DataStore = DataStore


        // this.$logger = $log.getInstance('RestHelper');
        // this.$logger.info('resthelper loaded');
        this.options = {
            name: 'unknown',
            defaultValues: {},
            useModal: true,
            params: { page: 0, limit: 10 },

        };
        this.Restangular = RestangularFull;
        this.AlertsService = AlertsService;

        // -- internal --
        this._setup = {
            endpoint: null, // http endpoint
            restclient: null, // restangular modell
            $scope: null, // scope we are working in
            includes: []
        };
        this._setupHooks();
        this.refreshHook = $rootScope.$on('refresh:all', () => this.refresh())
        this.refreshHook2 = $rootScope.$on('refresh:model', () => this.refresh())

    }

    getQuery(query) {
        return query;

    }

    getScope() {
        if (!this._setup.$scope) {
            throw new Error('Scope not set!');
        }

        return this._setup.$scope;
    }

    run() {
    }

    inject() {
        let self = this
        let scope = this.getScope()
        scope.DataStore = this.DataStore
        scope.Constants = Constants
        scope.EntityHelper = this;
        scope.$on('$destroy', () => {
            self.refreshHook()
            self.refreshHook2()
        });

    }

    setup($scope, endpoint, options) {
        const self = this;

        self._setup.endpoint = endpoint;
        self._setup.$scope = $scope;
        self.options = _.extend(self.options, options);
        self.defaultOptions = angular.copy(self.options);

        $scope.isLoading = true;

        // has stored parameter?
        if (!self.options.forceResetParameter && STORED_PARAMETER[self._setup.endpoint]) {
            self.options.params = STORED_PARAMETER[self._setup.endpoint];
        }

        $scope.params = self.options.params;
    }

    _setupHooks() {

        let _hooks = {};
        let self = this;
        this.addHook = function (eventName, fn) {
            if (typeof(fn) !== 'function') {
                self.$logger.error('addHook: Hook has to be a function');
                return;
            }
            _hooks[eventName] = fn;
        };

        this._emitEvent = function (eventName, parameter, parameter2) {
            if (_hooks[eventName]) {
                return _hooks[eventName](parameter, parameter2);
            }
            return true;

        }
    }
}


export default  RestHelper;
