import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, DataStore) {
        'ngInject';
        this.$controller = $controller
        this.$scope = $scope;
        this.DataStore = DataStore;
    }

    $onInit() {

        let options = {
                name: 'Brandeddocument',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '', 'includes[]': ['program'],
                    limit: 100,
                    sort: { 'key': 'asc' },

                },
                filter: this.filter
            }
        ;
        let em = this.$controller(RestHelperList);
        em.setup(this.$scope, 'admin/brandeddocuments', options);
        em.run();

    }

    selectLeft(row) {
        this.documentLeft = row;
    }

    selectRight(row) {
        this.documentRight = row;
    }

    reset() {
        this.documentRight = null;
        this.documentLeft = null;
        this.$scope.filter.key = '';


    }

}

const component = {
    template: require('./multidocument.component.html'),
    controller: Controller,
    bindings: {
        filter: '<',
        embededView: '<'
    }
}

export default  component;
