import angular from 'angular';

const MODULE_NAME = 'admin-course';

import courses from './courses.component'
import course from './course.component'
import CourseService from './course.service'
import courseSections from './course.sections.component'

angular.module(MODULE_NAME, [])
    .component('admincourses', courses)
    .component('admincourse', course)
    .service('CourseService', CourseService)
    .component('coursesections', courseSections)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
    //@todo there has to be a smarter way
        .state('admin.courses', {
            abstract: true,
            url: '/courses',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations','programs'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.courses.index', {
            url: '/list',
            component: 'admincourses'
        })
        .state('admin.courses.detail', {
            url: '/:id',
            component: 'admincourse'
        })
}


export default MODULE_NAME;
