import RestHelperList from '../../lib/api/resthelperlist.class'
import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import constants from '../../lib/constants'
import moment from "moment-timezone";


let template = `
<div>
    <table ng-if="$ctrl.data">
        <tr ng-repeat="n in model.notifications">
            <td>{{n.createdAt | date}}</td>
            <td>{{n.type}}</td>
        </tr>
    </table>
    <p ng-if="model.notifications.length == 0"> No notifications send yet.</p>
</div>
`;


class Controller {
    constructor($scope, $http, $controller) {
        'ngInject';
        this.$http = $http;
        this.$scope = $scope;
        this.$controller = $controller;
    }

    $onInit() {

        let options = {
            name: 'User',
            defaultValues: {},
            params: { 'includes[]': ['notifications'] }
        };

        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/users', options);

        em.setDetailId(this.id);
        em.run()


    }

    refresh() {
        this.$http.get('/admin/users/' + this.id + '?includes[]=notifications')
            .then((res) => this.data = res.data);
    }


}

const component = {
    template: template,
    controller: Controller,
    bindings: {
        type: '<',
        modelid: '<'
    }
}

export default  component;


/**
 .directive('betterScheduleIntl', ['$parse', function ($parse) {
    return {
      templateUrl: 'app/conference/schedule/betterScheduleIntl.html',
      scope: {
        entries: '=',
        onChoose: '=',
        mode: '=',
        update: '='
      },
      restrict: 'E',
      controller: function ($scope, $log, $translate, $confirm, AlertsService) {




        let updateCallback = $scope.update || function () {
            $log.error('[schedule] please overwrite update method')
          };

        function timeToFloat(time) {
          let hhmm  = this.time.split(':');
          return parseInt(hhmm[0]) + parseInt(hhmm[1])/60;
        }
        function floatToTime(time){
            return parseInt(time) + ':' + (time % 1 * 60);
        }




        $scope.timeFormat = function(time){
          let slotTime = 60; // minutes
          let starthour = parseInt(time);
          let startMinutes = (time % 1) * 60;
          let endMinutes = (startMinutes + slotTime ) % 60;
          let endHour = (startMinutes >= endMinutes ? starthour + 1 : starthour);
          return this.stringPad(starthour,2) + ':' + this.stringPad(startMinutes,2) + ' - ' + this.stringPad(endHour,2) + ':' + this.stringPad(endMinutes,2) ;
        };




        let onChoose = $scope.onChoose || function () {
            $log.error('[better schedule] overwrite onChoose attribute')
          };

        let mode = $scope.mode || 'registration';


        $scope.isSelected = isSelected;
        $scope.select = select;

        function

        function };

        function

      }
    }
 }]);
 **/
