
;

const component = {
    template: require('./adminlayout.component.html'),
    controller() {

    }
};



export default  component;
