import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'


import _ from 'lodash'

function isObject(o) {
    return o instanceof Object && o.constructor === Object;
}

class Controller {


    constructor($q, $controller, $scope, $stateParams, RestangularFull) {
        'ngInject';

        this.allLocales = Constants('locales')
        this.currentNode = null;
        this.translationsOfCurrentNode = null;
        this.models = null;
        this.Restangular = RestangularFull;
        this.scope = $stateParams.id;
        this.$q = $q;
        this.germanMissingKeys = [];
        this.spanishMissingKeys = [];

        //https://jsfiddle.net/kdhky4v9/2/
        this.jsoneditorOptions = {}
        $scope.EntityHelper = {
            refresh: () => this.refresh(),
            save: () => this.save(),
            dismiss: () => history.back()
        }

        return;
    }


    $onInit() {
        this.refresh();
    }

    importJson(insertJson, target) {

        let newValues = JSON.parse(insertJson);
        if (!newValues) {
            alert('Json from insert wrong');
        }
        _.each(newValues, (element, key) => {

            // { "common.labels.registration": "Registration" }
            if (typeof element === 'string' && key.indexOf('.') !== -1) {
                delete newValues[key];
                _.set(newValues, key, element)

            }

        });

        target.json_value = _.merge(newValues, target.json_value)


    }

    plotTree(inputData) {

        this.treeOptions = {
            nodeChildren: "children",
            dirSelectable: true,
            injectClasses: {
                ul: "a1",
                li: "a2",
                liSelected: "a7",
                iExpanded: "a3",
                iCollapsed: "a4",
                iLeaf: "a5",
                label: "a6",
                labelSelected: "a8"
            }
        };

        function convertIntoTree(root, languageFile) {
            let result = [];
            _.mapValues(languageFile, (value, key) => {
                let localkey = (root ? root + '.' : '') + key;
                let row = {
                    key: localkey,
                    title: key,
                    children: []
                };
                if (isObject(value)) {
                    row.children = convertIntoTree(localkey, value);
                }
                result.push(row);
            })
            return result

        };


        // this.fulltree = convertIntoTree(null, $scope.models[0].json_value);

        this.models = _.keyBy(inputData, 'locale');

        if (!this.models['en']) {
            alert('no data for english entries');
            return;
        }

        // @todo: maybe better way to find the fulltree.. anyway..
        this.fulltree = convertIntoTree(null, this.models['en'].json_value);

    }

    findMissingValues() {
        let json1 = this.models['en'].json_value;
        let json2 = this.models['de'].json_value;
        this.compareJSONRecursive(json1, json2,[], 'de')

        json2 = this.models['es'].json_value;
        this.compareJSONRecursive(json1, json2,[], 'es')

    }

    compareJSONRecursive(json1, json2, objectsDiffering, language) {
        for(let prop in json1) {
            if(json2.hasOwnProperty(prop)) {
                if (typeof(json1[prop])==="object"){
                    this.compareJSONRecursive(json1[prop], json2[prop], objectsDiffering, language);
                }
            }
            else {
                if (language==='de'){
                    this.germanMissingKeys.push({[prop]: json1[prop]});
                }
                if (language==='es'){
                    this.spanishMissingKeys.push({[prop]: json1[prop]});
                }
            }
        }
    }

    _getAllExistingKeys() {


        function deepMap(obj, iterator, context) {
            return _.transform(obj, function (result, val, key) {
                result[key] = _.isObject(val) /*&& !_.isDate(val)*/ ?
                    deepMap(val, iterator, context) :
                    iterator.call(context, val, key, obj);
            });
        }

        _.mixin({
            deepMap: deepMap
        });

        let allKeys = [];


        // Does  not wok1
        throw new Error("invali deepmap");
        // _.deepMap(this.fulltree, (value, obj) => {
        //     if (obj === 'key') {
        //         allKeys.push(value);
        //     }
        // });
        return _.uniq(allKeys);

    }

    refresh() {
        const self = this
        // [{ "filters": [{ "key": "primaryRole", "value": "mentor", "operator": "eq" }] }]
        let filters = [{
            filters:
                [
                    {
                        key: 'scope',
                        value: this.scope,
                        operator: 'eq'
                    }
                ]
        }];
        let query = {
            filter_groups: JSON.stringify(filters)
        };
        this.Restangular.all('admin/translations').getList(query)
            .then((response) => {
                let rows = _.map(response.data, (l) => l)
                self.plotTree(rows)
            })

    }

    search(searchvalue) {

        const self = this
        this.searchResult = [];
        if (searchvalue.length < 3){
            return;
        }

        function search(e) {
            if (e.children && e.children.length > 0) {
                _.each(e.children, search)
            }
            if (e.key.indexOf(searchvalue) !== -1 || e.title.toLowerCase().indexOf(searchvalue) !== -1) {
                self.searchResult.push(e);
            }
        }

        _.each(this.fulltree, search);
    }

    selectNode(node) {

        this.currentNode = node;
        this.translationsOfCurrentNode = {}
        if (this.currentNode.children.length == 0) {

            _.each(this.allLocales, (l) => {
                this.translationsOfCurrentNode[l.iso] = _.get(this.models[l.iso], 'json_value.' + node.key);
            })
        }

    }

    addSubnode(node, key) {
        //@todo: find if current node has translations and if so, cancel.

        key = key || '';
        key = key.replace(/[^\w\s!?]/g, '').toUpperCase();
        // node.children.
        node.children = node.children || [];
        node.children.push({
            "key": node.key + '.' + key,
            "title": key,
            "children": []
        });


    }

    updateEntry(key, isoLang, value) {

        if (!this.models[isoLang]) {
            this.models[isoLang] = {}
        }
        key = 'json_value.' + key;

        if (!value || value === '') {
            _.unset(this.models[isoLang], key);
            return;
        }
        _.set(this.models[isoLang], key, value);
    }

    saveAndRefresh(iso) {
        return this.models[iso].save()
            .then(() => this.refresh())

    }

    save() {

        return this.$q.all(_.mapValues(this.models, (lang, iso) => lang.save()));

    }
}


const
    component = {
        template: require('./translation.component.html'),
        controller: Controller
    };


export default component;
