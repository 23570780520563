import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'
import CourseService from "../courses/course.service";

class Controller {
    constructor($controller, $scope, $stateParams, CourseService) {
        'ngInject';

        this.$scope = $scope;
        let options = {
            name: 'Section',
            defaultValues: {},
            params: { 'includes[]': [] }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/videos', options);

        this.CourseService = CourseService;
        this.em = em;
        this.types = ['vimeo'];


    }

    $onInit() {

        this.em.setDetailId(this.id);
        this.em.run()

    }

    parseVideoUrl(url) {
        const videoId = vimeo_parser(url);

        if (!videoId) {
            return;
        }
        this.$scope.model.videoType = this.types[0]; //vimeo
        this.$scope.model.videoId = videoId;


    }

    addVideo() {
        // const sectionId = this.$scope.model.id;
        // this.CourseService.createVideo({ sectionId })
        //     .then(() => this.em.refresh());
    }
}

const component = {
    template: require('./section.video.component.html'),
    controller: Controller,
    bindings: {
        id: '='
    }
};
export default  component;


function vimeo_parser(url) {
    // https://vimeo.com/279525653

    // Look for a string with 'vimeo', then whatever, then a
    // forward slash and a group of digits.

    const matchPrivate = /vimeo.*\/(\d+)\/.*/i.exec(url);
    if (matchPrivate) {
        return matchPrivate[1];
    }

    // If the match isn't null (i.e. it matched)
    const match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
        // The grouped/matched digits from the regex
        return match[1];
    }
    return false;


}
