import SocketIoClient from './socketioclient'


class UserOnlineService {
    constructor($rootScope, $log, UserStore, settings, AuthenticationService) {
        'ngInject';

        this.socket = null;
        this.$logger = $log.getInstance('UserOnlineService');

        this.AuthenticationService = AuthenticationService;
        this.UserStore = UserStore;
        this.$rootScope = $rootScope

        const self = this

        this.onlineUsers = [];
        this.onlineUsersId = [];
        this.conferenceOnlineIds = [];


        if (!settings.onlineUserServiceUrl) {
            this.$logger.warn('OnlineUserService URL not set! Disable online user functionality')
            return
        }

        var options = {
            host: settings.onlineUserServiceUrl
        }

        this.client = new SocketIoClient(options)

        self.init();

    }

    getClient(){
        return this.client;
    }

    authenticate() {

        let token = this.AuthenticationService.getAccessToken();
        if (!token) {
            return;
        }
        let options = {
            userToken: token
        }
        this.client.login(options)
        this.$logger.info('authenticated')

    }

    init() {
        //

        this.$logger.info('[online service] connecting to online service');

        const self = this

        this.$rootScope.$on('auth.authenticated', () => {
            self.authenticate()
        });
        this.$rootScope.$on('auth.deauthenticated', () => {
            self.client.goodbye()
        })

        self.client.onUpdate = (users) => {

            self.onlineUsers = [];
            self.onlineUsersId = _.map(users, u => parseInt(u.id));
            let conferenceOnlineIds = [];
            let promises = [];

            _.each(users, userWrapper => {
                let p = self.UserStore.getUserById(userWrapper.id)
                    .then(u =>  {
                        let userCopy = angular.copy(u);
                        userCopy.type = userWrapper.type;
                        userCopy.device = userWrapper.device;
                        if (userCopy.type === 'conference'){
                            conferenceOnlineIds.push(parseInt(u.id));
                        }
                        self.onlineUsers.push(userCopy)
                    });
                promises.push(p);
            });

            self.conferenceOnlineIds = conferenceOnlineIds;

            self.$rootScope.$emit('onlineusers.update', self.onlineUsersId);
            Promise.all(promises).then(() =>{
                self.$rootScope.$emit('onlineusers.update', self.onlineUsersId);
            });

        }

        this.authenticate();
    }


}


export default  UserOnlineService;
