import BaseBreadService from '../../services/BaseBreadService'
import _ from "lodash";


/**
 * Plese note:
 *  bread service is not used yet;
 *
 */
class UserService extends BaseBreadService {

    constructor($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService, $http, settings) {
        'ngInject';
        super($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService, $http, settings);
        this.settings = settings;
        this.$http = $http;
        this.$log = $log
        this.endpoint = 'admin/users';
    }

    impersonateUser(id) {
        const frontendUrl = this.settings.frontendUrl + '/auth?adminview=true&token=';
        let url = 'admin/users/' + id + '/impersonate/';
        this.$http.post(url)
            .then((res) => {
                const url = frontendUrl + res.data.access_token;
                window.open(url);
            });

    }

    async findUser(search, includes) {
        const res = await this.$http({
            url: '/admin/users',
            method: "GET",
            params: {search, 'includes[]': includes}
        });
        return res.data.data;
    }
};
export default UserService;
