import RestHelperList from '../../lib/api/resthelperlist.class'

import constants from '../../lib/constants'


// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };
class Controller {
    constructor($scope, $controller, settings, $http) {
        'ngInject';
        let options = {
                name: 'Messaging',
                defaultValues: {},
                params: {
                    // limit:50,
                    usr_state: 'any',
                    search: '',
                    'includes[]': ['user'],
                },
                sort: { createdAt: 'desc' }
            }
        ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/smslogs', options);
        em.run();

        $scope.availableRoles = constants('roles');

        this.channels = ['sms', 'whatsapp'];
        this.directions = ['incoming', 'outgoing'];
        this.settings = settings;
        this.$http = $http;
        this.em = em;
        this.$scope = $scope;



    }
    isUnansweredFilter() {
        return this.$scope.filter.reviewed_at === 'null';

    }
    resetFilter() {
        this.$scope.filter = {};
        this.em.refresh();
    }
    showUnanswered() {

        this.$scope.filter.reviewed_at = 'null';
        this.$scope.filter.direction = 'incoming';
        this.em.refresh();
    }


}

const component = {
    template: require('./messaging.component.html'),
        controller: Controller,


}
;


export default  component;
