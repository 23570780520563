import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'
import RestHelperList from "../../lib/api/resthelperlist.class";

class Controller {
    constructor($controller, $scope) {
        'ngInject';

        this.$scope = $scope
        this.$controller = $controller

        this.$scope.sort = { updatedAt: 'DESC' };
        this.$scope.sortOrder = 'createdAt';

        this.sortOptions = [
            { label: 'Registration date', field: 'createdAt' },
            { label: 'Last Activity', field: 'updatedAt' },
            { label: 'Organisation', field: 'organization_id' },
            { label: 'First Name', field: 'firstName' },
            { label: 'Last Name', field: 'lastName' },
            { label: 'Role', field: 'primaryRole' }
        ];
    }

    $onInit() {

        //@ todo where oraanization =
        let options = {
                name: 'User',
                defaultValues: {},
                filter: {
                    'organization_id': this.organizationId,
                },
                params: {
                    // limit:50,
                    search: '',
                    'includes[]': [
                        'enrollments',
                        'enrollments.participations',
                        'enrollments.participations.mentorMentorship',
                        'enrollments.participations.mentorMentorship.appointments',
                        'enrollments.participations.menteeMentorship',
                        'enrollments.participations.menteeMentorship.appointments'
                    ],
                }
            }
        ;
        let em = this.$controller(RestHelperList);


        em.setup(this.$scope, 'admin/users', options);
        em.resetStoredParameter();

        em.run();

        this.em = em
    }

}

const component = {
    template: require('./reporting.users.component.html'),
    controller: Controller,
    bindings: {
        organizationId: '<'
    }
};
export default  component;
