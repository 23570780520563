import angular from 'angular';

const MODULE_NAME = 'admin-operations';



angular.module(MODULE_NAME, [])

    .component('pipelineOverview', require('./pipeline-overview.component').default)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.operations', {
            abstract: true,
            url: '/operations',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations','programs'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.operations.index', {
            url: '/index',
            component: 'matching'
        })

        .state('admin.operations.pipeline', {
            url: '/pipeline',
            component: 'pipelineOverview'
        })

        .state('admin.operations.webinars', {
            url: '/webinars',
            component: 'adminwebinars'
        })
        .state('admin.operations.webinars.detail', {
            url: '/webinars/:id',
            component: 'adminwebinar'
        })

}


export default MODULE_NAME;
