import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $scope, $stateParams, $http) {
        'ngInject';

        let options = {
            name: 'VideoQuestions',
            defaultValues: {},
            params: {'includes[]': ['video', 'user','answers']}
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/videoquestions', options);


        em.setDetailId($stateParams.id);
        em.run()
        this.$http = $http;
        this.$scope = $scope;
        this.em = em;

        this.id = $stateParams.id;
        this.answer = ''

    }

    async submitAnswer() {
        const url = `/training-center/questions/${this.id}/answers`;

        await this.$http.post(url, {text: this.answer})
        return this.em.refresh();
    }


}

const component = {
    template: require('./videoquestion.component.html'),
    controller: Controller
};
export default component;
