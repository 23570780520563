import angular from 'angular';

const MODULE_NAME = 'adminusers';

import users from './users.component'
import user from './user.component'
import shortprofile from './shortprofile.component'
import userselector from './userselector.component'
import userLatestRegistrations from './userlatestregistrations.component'
import usersonline from './usersonline.component'
import username from './username.component'
import useravatar from './useravatar.component'
import userRole from './userrole.directive'
import availabilityEditor from "./availbilityEditor.component";
import userGroupEditor from "./user.groupeditor.component";
import profileFields from "./user.profilefileds.component";
import userWebinars from "./user.webinars.component";
import userTechnicalView from "./usertechnicalview.component";
import userHistory from "./userhistory.component";
import messagingComponent from "./messaging.component";
import userEnrollments from "./user.enrollments.component";
import userautocomplete from "./userautocomplete.component";
import multimessage from "./multiMessage.component";
import sendwhatsapp from "./sendwhatsapp.component";
import whatsappStatus from "./whatsappStatus.component";
import UserStore from './users.store';
import UserOnlineService from '../../services/useronline.service'
import UserService from './user.service'
import notificationlogs from './notificationlogs.component'


angular.module(MODULE_NAME, [])
    .component('adminusers', users)
    .component('adminuser', user)
    .component('shortprofile', shortprofile)
    .component('userselector', userselector)
    .component('multimessage', multimessage)
    .component('messagingComponent', messagingComponent)
    .component('userhistory', userHistory)
    .component('userSendWhatsapp', sendwhatsapp)
    .component('usertechnicalview', userTechnicalView)
    .component('usersLatestRegistrations', userLatestRegistrations)
    .component('usersOnline', usersonline )
    .component('username', username)
    .component('whatsappStatus', whatsappStatus)
    .component('useravatar', useravatar)
    .service('UserStore', UserStore)

    .directive('userrole', userRole)
    .component('availabilityEditor', availabilityEditor)
    .component('notificationlogs', notificationlogs)
    .component('userGroupEditor', userGroupEditor)
    .component('userProfileFields', profileFields)
    .component('userWebinars', userWebinars)
    .component('userEnrollments', userEnrollments)
    .component('userautocomplete', userautocomplete)
    .service('UserOnlineService',UserOnlineService)
    .service('UserService',UserService)

    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.users', {
            abstract: true,
            url: '/users',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: /* @ngInject */ (DataStore) => DataStore.ensureEntities(['organizations','groups','roles','programs'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.users.index', {
            url: '/list',
            component: 'adminusers'
        })
        .state('admin.users.multimessage', {
            url: '/multimessage',
            component: 'multimessage'
        })
        .state('admin.users.messaging', {
            url: '/messaging',
            component: 'messagingComponent'
        })
        .state('admin.users.detail', {
            url: '/:id',
            component: 'adminuser'
        })
}


export default MODULE_NAME;
