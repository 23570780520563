import Constants from '../../lib/constants'
import _ from 'lodash'
import RestHelperDetail from '../../lib/api/resthelperdetail.class'

class Controller {
    constructor($controller, $http, $scope, Upload, $stateParams, settings) {
        'ngInject';
        this.$http = $http;
        this.$scope = $scope;
        this.$controller = $controller;

    }

    $onInit() {

        let options = {
                name: 'Supportanswer',
                defaultValues: {},
                params: {
                    // usr_state: 'any', search: '',
                    'includes[]': ['relations'],
                }
            }
        ;
        this.em = this.$controller(RestHelperDetail);
        this.em.setup(this.$scope, 'admin/supportanswers', options);
        this.em.setDetailId(this.id);
        this.em.run();
    }


    answerClick(answer) {
        this.$scope.model.answers.forEach(m => m.clicked = false);

        this.$scope.isSelected = true;
        answer.clicked = true;
        this.onSelect({ id: answer.linksTo })
    }


}

const component = {
    template: require('./supportanswerview.component.html'),
    controller: Controller,
    bindings: {
        id: '<',
        onSelect: '&'
    }
};
export default  component;
