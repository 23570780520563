import angular from 'angular';

const MODULE_NAME = 'admin-organization';

import organizations from './organizations.component'
import organization from './organization.component'
import organizationjf from './organization.jf.component'
import organizationreporting from './organization.reporting.component'
import reportingUsers from './reporting.users.component'
import organizationManager from './orgnanization.managers.component'
import organizationInteractionLog from './organizationInteractionLog.component'
import anonymizeUsers from './anonymizeUsers.component'


angular.module(MODULE_NAME, [])
    .component('adminorganizations', organizations )
    .component('adminorganization', organization)
    .component('adminorganizationreporting', organizationreporting)
    .component('organizationInteractionLog', organizationInteractionLog)
    .component('adminorganizationjf', organizationjf)
    .component('organizationmanager', organizationManager)
    .component('reportingUsers', reportingUsers)
    .component('anonymizeUsers', anonymizeUsers)
    .component('organizationkpis', require('./organizationkpi.component').default)
    .component('organizationbookings', require('./orgnanization.bookings.component').default)
    .component('organizationMiniMenu', require('./organizationMiniMenu.component').default)
    .service('OrganizationsService', require('./organizations.service').default)

    .config(config)


function config($stateProvider) {
    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.organizations', {
            abstract: true,
            url: '/organizations',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['programs'])
                //ensureEntities(['Program', 'Company', 'Group']) //,''
            }

        })
        .state('admin.organizations.index', {
            url: '/list',
            component: 'adminorganizations'
        })
        .state('admin.organizations.detail', {
            url: '/:id',
            component: 'adminorganization'
        })
        .state('admin.organizations.reporting', {
            url: '/:id/reporting',
            component: 'adminorganizationreporting'
        })
        .state('admin.organizations.jf', {
            url: '/:id/jf',
            component: 'adminorganizationjf'
        })
}


export default MODULE_NAME;
