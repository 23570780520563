import io from 'socket.io-client'


const CLIENT_EVENTS = {
    USER_HELLO_EVENT: 'USER_HELLO_EVENT',
    USER_GOODBYE_EVENT: 'USER_GOODBYE_EVENT',
};

const SERVER_EVENTS = {

    USER_HELLOSUCCESS_EVENT: 'USER_HELLOSUCCESS_EVENT',
    USER_HELLOFAIL_EVENT: 'USER_HELLOFAIL_EVENT',


    PING_EVENT: 'PING_EVENT',
    PONG_EVENT: 'PONG_EVENT',
    SET_USERS_ONLINE: 'SET_USERS_ONLINE',
    INCOMING_CALL: 'INCOMING_CALL',


    CALL_NOTIFICATION: 'CALL_NOTIFICATION',
    CALL_NOT_REACHABLE: 'CALL_NOT_REACHABLE',
    USER_CHAT_EVENT: 'USER_CHAT_EVENT',


    CHAT_MESSAGE: 'CHAT_MESSAGE',
    CHAT_NOT_REACHABLE: 'CHAT_NOT_REACHABLE',
    CHAT_UPDATE: 'CHAT_UPDATE',
}

class SocketIoClient {
    constructor(options) {
        var self = this;
        self.socket = null;

        self.socket = io.connect(options.host);
        self.setResponseListeners(self.socket);

        self.login = function (data) {
            self.socket.emit(CLIENT_EVENTS.USER_HELLO_EVENT, data);
        };


        //
        self.goodbye = function () {
            self.socket.emit(CLIENT_EVENTS.USER_GOODBYE_EVENT);
        };
    }

    onUpdate() {
        // overwrite
    }

    setResponseListeners(socket) {
        const self = this

        self.socket.on(SERVER_EVENTS.USER_HELLOSUCCESS_EVENT, function (username) {
            // self.users.trigger(EVENT.USER_HELLOSUCCESS_EVENT, username);

        });

        self.socket.on(SERVER_EVENTS.SET_USERS_ONLINE, function (onlineUsers) {
            console.log({onlineUsers});
            self.onUpdate(onlineUsers)
        });
        

        self.socket.on(SERVER_EVENTS.USER_HELLOFAIL_EVENT, function (err) {
            // self.users.trigger(EVENT.USER_HELLOFAIL_EVENT, err);
            console.error('online users', SERVER_EVENTS.USER_HELLOFAIL_EVENT, err)
        });

    };

    emit(name, parameters) {
        console.log('socketIoClient, emitting event', name, parameters);
        this.socket.emit(name, parameters);
    }

    listenToEvent(name, callback) {
        console.log('socketIoClient, listing to event', name);
        return this.socket.on(name, callback);

    }

};

export default  SocketIoClient;
