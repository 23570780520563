import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

import moment from "moment";


class Controller {
    constructor($controller, $scope, $http, settings, DataStore, $stateParams) {
        'ngInject';


        this.DataStore = DataStore;
        $scope.DataStore = DataStore;
        this.organization_id = null;

        this.result = {};
        this.$http = $http;
        this.refresh();

        this.settings = settings;
        this.organizationId = null;


    }

    refresh() {

        const url = this.organizationId ? 'admin/organizations/' + this.organizationId + '/happiness' : 'admin/reporting/happiness';

        this.$http({ url, method: 'GET', params: { role: this.role } })
            .then((response) => this.processResponse(response.data));

    }

    perc2color(percentage) {
        if (percentage < 0 || percentage === 0) {
            return 'transparent';
        }

        function shadeRGBColor(color, percent) {
            var f = color.split(","), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent,
                R = parseInt(f[0].slice(4)), G = parseInt(f[1]), B = parseInt(f[2]);
            return "rgb(" + (Math.round((t - R) * p) + R) + "," + (Math.round((t - G) * p) + G) + "," + (Math.round((t - B) * p) + B) + ")";
        }

        return shadeRGBColor('rbg(143, 205, 214)', percentage);


    }


    processResponse(data) {
        let happiness = data.happiness;
        let tableStructure = {};

        // getArrayOfAll Questioncodes:
        this.questionCodes = _.uniq(_.map(happiness, 'question_code')).sort();

        _.each(happiness, (row) => {
            tableStructure[row.month] = tableStructure[row.month] || { month: new Date(row.month) };
            tableStructure[row.month][row.question_code] = { count: row.count, rating: row.rating };
        });


        tableStructure = _.map(tableStructure, obj => obj); // convert object to array
        // sort
        this.happiness = _.sortBy(tableStructure, 'month');

        return happiness;
    }
}

const component = {
    template: require('./happinessReport.component.html'),
    controller: Controller
};
export default  component;
