class UserStore {
    constructor($http, $log, settings) {
        'ngInject';
        this.$http = $http;
        this.userCache = {};

        this.$log = $log
    }

    getUserById(id){

        const self = this;
        if (this.userCache[id]){
            return Promise.resolve(this.userCache[id]);
        }

        return this.$http.get('APIadmin/users/' + id )
            .then((result)=> self.userCache[id] = result.data.data)
            .then(() => self.userCache[id])
            ;

    }


    getAllUsers(view) {

        return this.$http.get('APIadmin/users?limit=10000&view=' + view )
            .then((result)=> result.data.data)


    }

};

export default  UserStore;
