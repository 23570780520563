
import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $stateParams, $httpParamSerializer) {
        'ngInject';

        let options = {
                name: 'Course',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '', 'includes[]': [],
                }
            }
            ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/courses', options);
        em.run();

    }
}

const component = {
    template: require('./courses.component.html'),
    controller: Controller,
}

export default  component;
