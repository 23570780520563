import angular from 'angular';

const MODULE_NAME = 'admin-enrollment';

import enrollments from './enrollments.component'
import adminenrollmentsreview from './enrollmentsReview.component'
import enrollment from './enrollment.component'
import findMentorshipComponent from './findMentorship.component'


angular.module(MODULE_NAME, [])
    .component('adminenrollments', enrollments )
    .component('adminenrollmentsreview', adminenrollmentsreview )
    .component('adminenrollment', enrollment)
    .component('findMentorshipComponent', findMentorshipComponent)
    .service('EnrollmentsService', require('./enrollment.service').default)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
        .state('admin.enrollments', {
            abstract: true,
            url: '/enrollments',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations','programs'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }
        })
        .state('admin.enrollments.index', {
            url: '/list',
            component: 'adminenrollments'
        })
        .state('admin.enrollments.detail', {
            url: '/:id',
            component: 'adminenrollment'
        })
        .state('admin.enrollments.review', {
            url: '/review',
            component: 'adminenrollmentsreview'
        })
}


export default MODULE_NAME;
