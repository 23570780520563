import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $scope, $stateParams, $http, OrganizationsService, CurrentUserService) {
        'ngInject';
        let options = {
            name: 'Organization',
            defaultValues: {
                "name": "",
                "keyaccountId": CurrentUserService.id,
                "servicelevel": "normal",
                "licence_booked": "0",
                "type": "company",
                privateWebinars: false
            },
            params: {
                'includes[]': ['programs', 'keyaccount'],
            }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/organizations', options);
        em.setDetailId($stateParams.id);


        em.run()

        this.id = $stateParams.id
        this.kpis = null
        this.em = em
        this.$http = $http
        this.$scope = $scope;
        this.OrganizationsService = OrganizationsService;

        $scope.newJf = {
            type: 'JF'
        };

        // @todo: move to constants
        this.classifications = {
            'A': '>50.000 employees',
            'B': '>10.000 employees',
            'C': '<10.000 employees'
        };


    }

    downloadReport() {
        this.OrganizationsService.downloadXlsxReport( this.id , 'external');
    }


    saveJf() {

    }

    refresh() {
        this.em.refresh();

    }

    transition(webinar, name) {
        const self = this
        this.StateMachineService.transition('webinar', webinar.id, name)
        self.em.refresh();
    }


    addProgramm(id) {
        // @todo: check if already exists?
        this.em.getModel().post('programs/' + id)
            .then(() => this.em.refresh())
    }

    removeProgramm(id) {
        this.em.getModel().one('programs', id).remove()
            .then(() => this.em.refresh())
    }

}

const component = {
    template: require('./organization.jf.component.html'),
    controller: Controller
};
export default  component;
