class Controller {
    constructor($state, $rootScope, CurrentUserService, settings) {
        'ngInject';
        this.isActive = (route) => {
            let path = route.basePath || route.path;
            return $state.includes('admin.' + path);
        };
        this.CurrentUserService = CurrentUserService;
        this.settings = settings

        this.open = (link) => {

            let newState = link.path;
            if (newState.indexOf('admin.') === -1) {
                newState = 'admin.' + link.path + '.index';
            }

            $state.go(newState);
        };

        $rootScope.$on('auth.userupdate', () => this.refresh());
        if (CurrentUserService.getUser()) {
            this.refresh();
        }
    }

    init() {
        let links = [];

        links.push(
            {
                name: 'Service',
                links: [
                    {
                        name: 'Live Dashboard',
                        icon: 'tachometer-alt',
                        path: 'dashboard',
                        requireRoles: ['COORDINATOR', 'ADMIN']
                    },
                    {
                        name: 'Messaging',
                        icon: 'chat',
                        path: 'admin.support.inbound',
                        requireRoles: ['COORDINATOR', 'ADMIN']
                    },
                    { name: 'Todos', path: 'todos', icon: 'fas fa-tasks', requireRoles: ['COORDINATOR', 'ADMIN'] },
                    // { name: 'Feedback', icon: 'star', path: 'userfeedbacks', requireRoles: ['COORDINATOR', 'ADMIN'] }
                ]
            },
            {
                name: 'Operations',
                links: [
                    {
                        name: 'Pipeline',
                        icon: 'tachometer-alt',
                        path: 'admin.operations.pipeline',
                        requireRoles: ['COORDINATOR', 'ADMIN']
                    },
                    { name: 'Leads', icon: 'user', path: 'leads', requireRoles: ['COORDINATOR', 'ADMIN'] },

                    {
                        name: 'Enrollments-Review',
                        icon: 'handshake',
                        path: 'admin.enrollments.review',
                        requireRoles: ['COORDINATOR', 'ADMIN']
                    },
                    {
                        name: 'Webinars',
                        path: 'admin.operations.webinars',
                        icon: 'fas fa-tasks',
                        requireRoles: ['COORDINATOR', 'ADMIN']
                    },

                ]
            });
        links.push({
            name: 'PartnerMgmt',
            links:
                [
                    {
                        name: 'Demos',
                        icon: 'desktop',

                        path: 'demo',
                        requireRoles: ['COORDINATOR', 'ADMIN', 'EDITOR']
                    },
                    {
                        name: 'Reports',
                        icon: 'building',
                        path: 'reports',
                        requireRoles: ['ADMIN']
                    },
                    {
                        name: 'Organization',
                        icon: 'building',
                        path: 'organizations',
                        requireRoles: ['COORDINATOR', 'ADMIN']
                    },
                    {
                        name: 'Registration Links',
                        icon: 'link',
                        path: 'registrationcodes',
                        requireRoles: ['COORDINATOR', 'ADMIN']
                    },


                ]
        });

        links.push({
            name: 'Platform data',
            links: [
                { name: 'Users', icon: 'user', path: 'users', requireRoles: ['COORDINATOR', 'ADMIN'] },
                {
                    name: 'Enrollments',
                    icon: 'handshake',
                    path: 'enrollments',
                    requireRoles: ['COORDINATOR', 'ADMIN']
                },
                { name: 'Webinars', icon: 'microphone', path: 'webinars', requireRoles: ['COORDINATOR', 'ADMIN'] },
                {
                    name: 'Participations',
                    icon: 'handshake',
                    path: 'participations',
                    requireRoles: ['COORDINATOR', 'ADMIN']
                },

                {
                    name: 'Mentorships',
                    icon: 'fas fa-user-friends',
                    path: 'mentorships',
                    requireRoles: ['COORDINATOR', 'ADMIN']
                },
                {
                    name: 'Testimonials',
                    icon: 'fas fa-user-friends',
                    path: 'stories',
                    requireRoles: ['COORDINATOR', 'ADMIN']
                },

                // { name: 'Map', icon: 'map', path: 'char', requireRoles: ['ADMIN'] }

                //@todo vl. emails etc.
                //baseState: 'app.admin', allowedRoles:['ADMIN'], ?

            ]

        });

        links.push({
            name: 'Trainingcenter',
            links: [
                {
                    name: 'Course',
                    icon: 'language',
                    path: 'courses',
                    requireRoles: ['SUPERADMIN', 'ADMIN', 'EDITOR']
                },
                {
                    name: 'VideoQuestions',
                    icon: 'questions',
                    path: 'videoquestions',
                    requireRoles: ['SUPERADMIN', 'ADMIN', 'EDITOR']
                },
                {
                    name: 'Lecturer',
                    icon: 'language',
                    path: 'lecturer',
                    requireRoles: ['SUPERADMIN', 'ADMIN', 'EDITOR']
                },
            ]

        });
        links.push({
                name: 'Admin',
                links:
                    [
                        {
                            name: 'Translation',
                            icon: 'language',
                            path: 'translations',
                            requireRoles: ['SUPERADMIN', 'ADMIN', 'EDITOR']
                        },
                        {
                            name: 'Supportanswers',
                            icon: 'language',
                            path: 'supportanswers',
                            requireRoles: ['SUPERADMIN', 'ADMIN', 'EDITOR']
                        },
                        {
                            name: 'Documents',
                            icon: 'book',
                            path: 'brandeddocuments',
                            requireRoles: ['SUPERADMIN', 'ADMIN', 'EDITOR']
                        },
                        {
                            name: 'NotificationLog',
                            icon: 'fa-envelope-open',
                            path: 'notifications',
                            requireRoles: ['SUPERADMIN', 'ADMIN', 'COORDINATOR']
                        },
                        { name: 'Files', icon: 'download', path: 'files', requireRoles: ['SUPERADMIN', 'ADMIN', 'EDITOR'] },
                        { name: 'Plans', icon: 'link', path: 'plans', requireRoles: ['SUPERADMIN', 'ADMIN'] },
                        { name: 'Styleguide', icon: 'cogs', path: 'technical', requireRoles: ['SUPERADMIN', 'ADMIN'] },
                        { name: 'Programs', icon: 'link', path: 'programs', requireRoles: ['SUPERADMIN', 'ADMIN'] },
                        { name: 'Brands', icon: 'link', path: 'brands', requireRoles: ['SUPERADMIN', 'ADMIN'] },

                    ]
            }
        );
        this.allLinks = links
    }

    refresh() {
        this.init();
        _.each(this.allLinks, (block) => {
            block.links = _.filter(block.links, (link) => this.CurrentUserService.hasOneRole(link.requireRoles));
        });

        this.links = this.allLinks;

        if (this.CurrentUserService.hasOneRole(['SUPERADMIN', 'ADMIN', 'COORDINATOR'])) {
            this.admin3Links = [
                {
                    name: 'Groups',
                    url: `${this.settings.admin3Url}/groups`
                },
                {
                    name: 'Matching',
                    url: `${this.settings.admin3Url}/matching`
                }
            ]
        }
    }
}

const component = {
    template: require('./sidebar.component.html'),
    controller: Controller
}
export default  component;
