'use strict';

import '../lib/locale/angular-locale_de-de';


const LOCALES = [{
    iso: 'de',
    title: 'Deutsch',
    wildcard: 'de_*',
    code: 'de_DE',
    codeJs: 'de-de'
}, {
    iso: 'en',
    code: 'en_US',
    codeJs: 'en-us',
    wildcard: 'en_*',
    title: 'English'
}];


class LocaleService {
    constructor($log, $translate) {
        'ngInject';
        var currentLocale = $translate.proposedLanguage();// because of async loading

    }


}


export default  LocaleService;

//
// angular.module('mmApp')
//     .constant('LOCALES', { //@todo: move to config file.
//         'locales': [],
//         'preferredLocale': 'de_De'//en_US
//     })
//     // .config(function (tmhDynamicLocaleProvider) {
//     //     tmhDynamicLocaleProvider.localeLocationPattern('/bower_components/angular-i18n/angular-locale_{{locale}}.js');
//     // })
//     .service('LocaleService', function ($translate, $localStorage, $log, $locale, ISO3166, tmhDynamicLocale, LOCALES, $rootScope) {
//
//         var $logger = $log.getInstance('LocaleService');
//         //tmhDynamicLocale
//         // PREPARING LOCALES INFO
//         var locales = LOCALES.locales;
//
//         // locales and locales display names
//         /* @var _LOCALES: array ['de','en'] */
//         var _LOCALES = _.map(locales, function (l) {
//             return l.iso
//         });
//         var _localesObject = {};
//         _.each(locales, function (l) {
//             _localesObject[l.iso] = l
//         });
//
//         if (!_LOCALES || _LOCALES.length === 0) {
//             $logger.error('There are no _LOCALES provided');
//         }
//         // display names  = ['Deutsch', English]...
//         var _LOCALES_DISPLAY_NAMES = _.map(locales, function (l) {
//             return l.title
//         });
//
//         var currentLocale = $translate.proposedLanguage();// because of async loading
//
//
//         var checkLocaleIsValid = function (locale) {
//             return _LOCALES.indexOf(locale) !== -1;
//         };
//
//         var setLocale = function (locale) {
//             if (!checkLocaleIsValid(locale)) {
//                 $logger.error('Locale name "' + locale + '" is invalid');
//                 return;
//             }
//
//             //amMoment.changeLocale('de');
//
//
//             $logger.info('setting locale to ', locale);
//             $localStorage.language = locale;
//             currentLocale = locale;// updating current locale
//             ISO3166.setLocale(locale);
//             $translate.use(locale);
//             tmhDynamicLocale.set(locale);
//
//
//         };
//
//         // EVENTS
//         // on successful applying translations by angular-translate
//         $rootScope.$on('$translateChangeSuccess', function (event, data) {
//             document.documentElement.setAttribute('lang', data.language);// sets "lang" attribute to html
//             // asking angular-dynamic-locale to load and apply proper AngularJS $locale setting
//             tmhDynamicLocale.set(data.language.toLowerCase().replace(/_/g, '-'));
//         });
//
//         $rootScope.$on('user.sessionupdate', function (e, user) {
//             $logger.info('user.sessionupdate using language of user:', user, user.language);
//             if (user.language) {
//                 $translate.use(user.language);
//             }
//
//         });
//
//         setLocale(currentLocale);
//
//
//         return {
//             getLocaleDisplayName: function () {
//                 return _localesObject[currentLocale];
//             },
//             // e.g. de-de
//             getCurrentLocaleObject: function () {
//                 return _localesObject[currentLocale];
//
//             },
//             getTimezone: function () {
//                 // only works in new browsers;
//                 return Intl.DateTimeFormat().resolvedOptions().timeZone;
//             },
//             //e.g. de
//             getCurrentLocale: function () {
//                 return $translate.use();
//             },
//             getLocales: function () {
//                 return locales;
//             },
//             setLocale: setLocale,
//             getLocalesDisplayNames: function () {
//                 return _LOCALES_DISPLAY_NAMES;
//             }
//         };
//     })
// //
//
//     .run(function ($rootScope, $translate, $localStorage, tmhDynamicLocale) {
//
//
//
//         // Use the locally stored language
//         if ($localStorage.language) {
//             var locale = $localStorage.language;
//             tmhDynamicLocale.set(locale);
//             $translate.use(locale);
//         }
//     })
//     .directive('ngTranslateLanguageSelect', function (LocaleService) {
//
//         return {
//             restrict: 'A',
//             replace: true,
//             template: '<div style="width:30px" uib-dropdown><a uib-dropdown-toggle ng-click="toggleLangSwitcher($event)"><div class="famfamfam-flags {{currentLanguage}}" style="display:inline-block;"></div></a>' +
//             '' +
//             '<ul class="dropdown-menu language-dropdown profile-dropdown"  uib-dropdown-menu >' +
//             '<li ng-repeat="choice in locales">' +
//             '<a ng-click="setLanguage(choice)"><div class="famfamfam-flags {{choice.iso}} " style="display:inline-block;"></div> {{choice.title}}</a>' +
//             '</li>' +
//             '</ul>' +
//             '</div>',
//
//             controller: function ($scope, $rootScope) {
//
//
//                 $scope.locales = LocaleService.getLocales();
//                 $scope.currentLanguage = LocaleService.getCurrentLocale();
//
//                 $rootScope.$on('$translateChangeSuccess', function (event, data) {
//                     $scope.currentLanguage = LocaleService.getCurrentLocale();
//                 });
//
//                 $scope.setLanguage = function (locale) {
//                     //e.g. de
//                     LocaleService.setLocale(locale.iso);
//                 };
//             }
//         };
//     })
//     .factory('VVTranslate', function ($q, $translate) {
//         return {
//             form: function (form) {
//                 var promises = [];
//                 // var result = angular.copy(schema);
//
//                 angular.forEach(form, function (value, key) {
//                     // value is excepted to be a form obj;
//                     if (typeof value == 'string') {
//                         return;
//                     }
//                     var promise = $translate(['form.' + value.key, (value.description ? 'form.' + value.description : false)])
//                         .then(function (translations) {
//                             value.title = translations['form.' + value.key];
//                             if (translations['form.' + value.description]) {
//                                 value.description = translations['form.' + value.description];
//                             }
//                         });
//                     promises.push(promise);
//                 });
//                 return $q.all(promises).then(function () {
//                     return form;
//                 });
//             }
//         }
//     })
//     .constant('uiDatetimePickerConfig',
//         // https://rawgit.com/Gillardo/bootstrap-ui-datetime-picker/master/example/index.html
//         {
//             dateFormat: 'short',
//             defaultTime: '12:00:00',
//             html5Types: {
//                 date: 'yyyy-MM-dd',
//                 'datetime-local': 'yyyy-MM-ddTHH:mm:ss.sss',
//                 'month': 'yyyy-MM'
//             },
//             initialPicker: 'date',
//             reOpenDefault: false,
//             enableDate: true,
//             enableTime: true,
//             buttonBar: {
//                 show: false,
//                 now: {
//                     show: true,
//                     text: 'Now'
//                 },
//                 today: {
//                     show: true,
//                     text: 'Today'
//                 },
//                 clear: {
//                     show: true,
//                     text: 'Clear'
//                 },
//                 date: {
//                     show: true,
//                     text: 'Date'
//                 },
//                 time: {
//                     show: true,
//                     text: 'Time'
//                 },
//                 close: {
//                     show: true,
//                     text: 'Close'
//                 }
//             },
//             closeOnDateSelection: true,
//             closeOnTimeNow: true,
//             appendToBody: false,
//             altInputFormats: [],
//             ngModelOptions: {},
//             saveAs: false,
//             readAs: false,
//         }
//     )
//
//
// ;
