import Constants from '../../lib/constants'
import _ from 'lodash'
import UAParser from 'ua-parser-js';

import RestHelperDetail from "../../lib/api/resthelperdetail.class";

class Controller {
    constructor($controller, $http, $scope, UserService, RestangularFull, $stateParams, settings) {
        'ngInject';

        this.$http = $http;
        this.$scope = $scope;
        this.currentAnswers = [1]
        // this.selectedUser = false // for testing; false = i do not need one;
        this.$controller = $controller;
        this.RestangularFull = RestangularFull;
        this.UserService = UserService;

        this.userList = [];
    }


    async userSelected(user) {
        let options = {
            name: 'User',
            defaultValues: {},
            params: {'includes[]': ['launches']}
        };

        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/users', options);
        em.setDetailId(user.id);
        em.run()

        this.selectedUser = user;

        const incidentResponse = await this.RestangularFull.all('admin/supportincidents').post({
            customer_id: user.id
        });


        this.supportIncident = incidentResponse.data

    }

    async searchUser(search) {
        this.selectedUser = null;
        this.userList = await this.UserService.findUser(search, ['organization']);
        this.$scope.$apply();
    }

    saveIncident() {
        const saved = this.supportIncident.save();
        this.lastTimeSaved = new Date();
        return saved;
    }


    undoCall() {
        const response = this.supportIncident.remove()
        this.reset();
        return response;
    }

    reset() {
        this.currentAnswers = [1]
        this.selectedUser = null;
        this.supportIncident = null;
    }

    formatBrowser(uastring) {
        // var uastring = "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/535.2 (KHTML, like Gecko) Ubuntu/11.10 Chromium/15.0.874.106 Chrome/15.0.874.106 Safari/535.2";
        const parser = new UAParser();
        parser.setUA(uastring);
        const result = parser.getResult();
        const device = (result.device.vendor ? result.device.vendor : '') + ' ' + (result.device.model ? result.device.model : '') + ' ' + (result.device.model ? result.device.type : '');

        return `${result.browser.name} (${result.browser.version}) | ${device} | ${result.os.name}  (${result.os.version})  |`;
    }


}

const component = {
    template: require('./inboundcall.component.html'),
    controller: Controller
};
export default component;
