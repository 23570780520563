import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'
import showdown from "showdown/dist/showdown.min";

class Controller {
    constructor($controller, $scope, $http,$confirm, $stateParams) {
        'ngInject';

        let options = {
            name: 'Supportanswervie',
            defaultValues: {},
            params: { 'includes[]': ['relations'] }
        };

        this.newLink = {}
        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/supportanswers', options);
        this.types = ['question', 'solution', 'template'];
        this.linkClasses = [];
        this.$confirm = $confirm;
        this.$http = $http;
        this.$scope = $scope;
        this.em = em;

        em.setDetailId($stateParams.id);
        em.run()
    }

    compileHtml(md) {
        if (!this.showdownConverter) {
            this.initShowdown();
        }
        let html = this.showdownConverter.makeHtml(md);
        if (!html) {
            this.$log.warn('[showdown] could not generate html from ', md)
            return
        }
        this.$scope.model.description = html;
    }

    initShowdown() {
        if (typeof(showdown) == "undefined") {
            this.$log.warn('shodown not availavble')
            return
        }
        let converter = new showdown.Converter();
        converter.setOption('simplifiedAutoLink', true);
        converter.setOption('simpleLineBreaks', true);
        this.showdownConverter = converter
    }

    getSupportAnswers(search) {


        return this.$http.get('/admin/supportanswers?search=' + search)
            .then((res) => {
                return _.map(res.data.data, (u) => {
                    // u.displayName = u.displayName +  ' ( ' + _.get(u,'organization.name') + ' )';
                    u.displayName = `${u.title} (${u.type})`;
                    return u
                })

            })

    }

    /**
     *
     * @param a
     * @returns {*}
     */
    unlinkAnswer(a) {

        let id = this.$scope.model.id;
        this.isLinking = true;
        const textSureDelete = 'Do you really want to delete the relation (the content wont be removed)';
        const self = this;

        return this.$confirm({ text: textSureDelete })
            .then(() => this.$http.delete('/admin/supportanswers/' + id + '/relations/' + a.id))
            .then(() => this.em.refresh())
            .then(() => this.isLinking = false)

    }

    linkAnswer() {
        if (!this.newLink.linksToSelect || !this.newLink.linksToSelect.id) {
            alert('please select an answer to link to');
            return;
        }

        this.newLink.linksTo = this.newLink.linksToSelect.id;
        this.newLink.links_to = this.newLink.linksToSelect.id;

        let id = this.$scope.model.id;

        this.isLinking = true;
        this.newLink.class = 'link';

        this.$http.post('/admin/supportanswers/' + id + '/relations', this.newLink)
            .then(l => {
                this.isLinking = false;
                this.newLink = {}
                this.em.refresh();

            });

    }


}

const component = {
    template: require('./supportanswer.component.html'),
    controller: Controller
};
export default  component;
