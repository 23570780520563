import angular from 'angular';

const MODULE_NAME = 'admin-brandeddocument';

import brandeddocuments from './brandeddocuments.component'
import brandeddocument from './brandeddocument.component'
import multidocument from './multidocument.component'
import brandeddocumentbrowser from './documentbrowser.component'
import documentsByKey from './documentsByKey.component'

angular.module(MODULE_NAME, [])
    .component('adminbrandeddocuments', brandeddocuments )
    .component('adminbrandeddocument', brandeddocument)
    .component('adminbrandeddocumentbrowser', brandeddocumentbrowser)
    .component('documentsByKey', documentsByKey)
    .component('multidocument', multidocument)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
        .state('admin.brandeddocuments', {
            abstract: true,
            url: '/brandeddocuments',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations','programs'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.brandeddocuments.list', {
            url: '/list',
            component: 'adminbrandeddocuments'
        })
        .state('admin.brandeddocuments.index', {
            url: '/browser',
            component: 'adminbrandeddocumentbrowser'
        })
        .state('admin.brandeddocuments.byKey', {
            url: '/browser/:key',
            component: 'documentsByKey'
        })
        .state('admin.brandeddocuments.byKey.edit', {
            url: '/browser/:key/document/:id',
            component: 'adminbrandeddocument'
        })
        .state('admin.brandeddocuments.multi', {
            url: '/multi',
            component: 'multidocument'
        })
        .state('admin.brandeddocuments.detail', {
            url: '/:id',
            component: 'adminbrandeddocument'
        })
}


export default MODULE_NAME;
