// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

import Constants from '../lib/constants'


class Controller {
    constructor($scope, $state, RestangularFull) {
        'ngInject';
        this.$scope = $scope

        this.Restangular = RestangularFull
        this.api = null //client
        this.commentTypes = Constants('commentTypes')
        this.reset();



    }

    reset() {
        this.$scope.newComment = { type: 'organisational', text:'' };
    }

    addComment(comment) {

        this.isSubmitting = true
        this.api.post({
            'body': comment.text,
            'type':  comment.type,
            'visible_to': this.scope
        })
            .then(() => this.isSubmitting = false)
            .then(() => this.reset())
            .then(() => this.refresh())



    }

    $onInit() {

        const self = this
        const url = this.entity + '/' + this.id + '/comments/admin'

        self.api = this.Restangular.all(url)
        this.scope = this.scope || 'admin'

        this.refresh()
    }

    pinComment(comment, newValue) {

        comment.pinToTop = newValue;
        comment.put()
            .then(() => this.refresh());

    }

    refresh() {
        const self = this
        self.isLoading = true
        self.api.getList({ 'includes[]': 'author' })  // GET: /users
            .then(function (comments) {
                self.isLoading = false
                self.comments = comments.data;
            })
    }

}

const component = {
    template: require('./comments.component.html'),

    bindings: {
        entity: '<', //One-way (“<”) data bindings; Expressions (“&”) bindings // Evaluated DOM attribute (“@”) bindings
        id: '<',
        scope: '<',
        hideinput: '<'
    },
    controller: Controller
};


export default  component;
