
import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope) {
        'ngInject';

    }
    reduceType(link){
        return link
            .replace('App\\Notifications\\','')
            .replace('Notification','')


    }
}

const component = {
    template: require('./notificationlist.component.html'),
    controller: Controller,
    bindings: {
        notifications: '<'
    }
};


export default  component;
