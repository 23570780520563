
import BaseBreadService from '../../services/BaseBreadService'



class BookingService  extends BaseBreadService {
    constructor($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService, $http) {
        'ngInject';
        super($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService, $http);
        this.$log = $log
        this.endpoint = 'admin/bookings';
        this.$http = $http;

    }
    async addBooking(model){
        return this.$http.post(this.endpoint, model);
    }

    /**
     * Currently there is no deletion endpoint implemented, but you can delete booking by reducing down to 0 amount of
     * licences.
     */
    async removeBooking(model){
        return this.$http.put(this.endpoint + '/' + model.id, {...model, amount: 0});
    }

    async getSummaryOfOrganization(id) {
        // /organizations/{id}/booking-summary
        return this.$http.get(`/admin/organizations/${id}/booking-summary`);
    }
    async getAllCompaniesOverview() {
        return this.$http.get(`/admin/reporting/bookings`);

    }
};

export default  BookingService;
