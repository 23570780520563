import Constants from '../lib/constants'


class Controller {


    constructor($http) {
        'ngInject';

    }


    $onInit() {

    }

    getVideoUrl() {

        const type = 'vimeo'// move to paramter
        switch (type) {
            case 'youtube':
                return 'https://www.youtube-nocookie.com/embed/' + this.videoid;
            case 'vimeo':
                return 'https://player.vimeo.com/video/' + this.videoid;
        }
        return '';

    }


}

const component = {
    template: `
    <div>
        <iframe width="100%" height="300" ng-src="{{$ctrl.getVideoUrl() | trustedUrl}}" ></iframe>

    <span class="muted">{{$ctrl.getVideoUrl()}}</span>

    </div>
`,

    bindings: {
        videoid: '<',
        type: '<'
    },
    controller: Controller
};


export default component;
