import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import AlertService from '../../lib/uihelper/alerts.service'


class Controller {
    constructor($controller, $scope, $stateParams, CourseService) {
        'ngInject';
        this.$scope = $scope;
        let options = {
            name: 'Quiz',
            defaultValues: {},
            params: { 'includes[]': [] }
        };

        this.$scope.newQuestion = {
            questionId: undefined,
            text:'',
            type: 'single_choice'
        };
        this.$scope.newAnswer = {
            answerId: undefined,
            questionId: undefined,
            text:'',
            isCorrect: false
        };
        this.$scope.questionTypes = [
            { label: 'Single Choice', field: 'single_choice' },
            { label: 'Multiple Choice', field: 'multiple_choice' }
        ];
        this.$scope.sectionId = $stateParams.sectionId;
        this.$scope.quizId = $stateParams.quizId;
        if ($stateParams.quizId){
            let em = $controller(RestHelperDetail);
            em.setup($scope, 'admin/quizzes', options);
            em.setDetailId($stateParams.quizId);
            em.run()
            this.em = em;
        }
        this.CourseService = CourseService;
        this.$scope.showNewAnswerForQuestionId = undefined;
        this.AlertService = $controller(AlertService);

    }
    saveQuiz() {
        const passGradePercentage = this.$scope.model.pass_grade_percentage;
        const sectionId = this.$scope.sectionId;

        if (this.$scope.quizId){
            const quizId = this.$scope.quizId;
            this.CourseService.updateQuiz({sectionId: sectionId,quizId: quizId, passGradePercentage: passGradePercentage })
                .then((result) => {
                    this.$scope.model = result;
                });
        } else{
            this.CourseService.createQuiz({sectionId: sectionId, passGradePercentage: passGradePercentage })
                .then((result) => {
                    this.$scope.model = result;
                    this.$scope.quizId = result.id;
                    if (result.section){

                        // this.$state.go('admin.quizzes.detail', { sectionId: result.section.id, 'quizId': this.$scope.quizId });
                    }
                });
        }

    }
    saveQuestion() {
        const type = this.$scope.newQuestion.type;
        const text = this.$scope.newQuestion.text;
        const quizId = this.$scope.quizId;
        this.CourseService.createQuestion({quizId: quizId, type: type, text: text })
            .then((result) => {
                this.$scope.model.questions.push(result);
                this.resetNewQuestion()
            });
    }
    updateQuestion() {
        const questionId = this.$scope.newQuestion.questionId;
        const type = this.$scope.newQuestion.type;
        const text = this.$scope.newQuestion.text;
        const quizId = this.$scope.quizId;
        this.CourseService.updateQuestion({questionId: questionId, quizId: quizId, type: type, text: text })
            .then((result) => {
                this.$scope.model.questions = this.$scope.model.questions.map((q) =>{
                    if (q.id === result.id){
                       return result;
                    }
                    return q;
                });
                this.resetNewQuestion();
            });
    }
    openEditQuestionForm(question) {
        this.$scope.newQuestion =  {
            questionId: question.id,
            text: question.text,
            type: question.type
        };
    }
    openEditAnswerForm(question, answer) {
        this.$scope.newAnswer =  {
            answerId: answer.id,
            questionId: question.id,
            text: answer.text,
            isCorrect: answer.is_correct
        };
    }
    resetNewQuestion() {
        this.$scope.newQuestion =  {
            questionId: undefined,
            text:'',
            type: 'single_choice'
        };
    }
    resetNewAnswer() {
        this.$scope.newAnswer = {
            answerId: undefined,
            questionId: undefined,
            text:'',
            isCorrect: false
        };
    }

    saveAnswer(question) {
        if (question.type === 'single_choice'){
            const questionCorrectAnswers = question.answers.filter(answer => answer.is_correct === "1");

            if (questionCorrectAnswers.length > 0 && this.$scope.newAnswer.isCorrect){
                this.AlertService.showConstant('error', 'TOO_MUCH_CORRECT_ANSWER');
                return;
            }
        }
        const text = this.$scope.newAnswer.text;
        const isCorrect = this.$scope.newAnswer.isCorrect;
        this.CourseService.createAnswer({questionId: question.id, isCorrect: isCorrect, text: text })
            .then((result) => {
                question.answers.push(result);
                this.resetNewAnswer();
            });
    }
    updateAnswer(question) {
        const answerId = this.$scope.newAnswer.answerId;
        const text = this.$scope.newAnswer.text;
        const isCorrect = this.$scope.newAnswer.isCorrect;
        this.CourseService.updateAnswer({answerId: answerId, questionId: question.id, isCorrect: isCorrect, text: text })
            .then((result) => {
                question.answers = question.answers.map((a) =>{
                    if (a.id === result.id){
                        return result;
                    }
                    return a;
                });
                this.resetNewAnswer();
            });
    }

    removeAnswer(question, answer) {
        this.CourseService.removeAnswer({answerId: answer.id})
            .then((result) => {
                question.answers = question.answers.filter(
                 (a) => {
                     return a.id !== answer.id
                 }
             )
            });
    }
    removeQuestion(question) {
        this.CourseService.removeQuestion({questionId: question.id})
            .then((result) => {
                this.$scope.model.questions = this.$scope.model.questions.filter(
                    (q) => {
                        return q.id !== question.id
                    }
                )
            }).catch((error) =>{
            this.AlertService.showConstant('error', 'FAILED_REMOVING_QUESTION');
        });
    }
}

const component = {
    template: require('./quiz.component.html'),
    controller: Controller
};
export default  component;
