import angular from 'angular';

const MODULE_NAME = 'admin-lead';

import leads from './leads.component'
import lead from './lead.component'

angular.module(MODULE_NAME, [])
    .component('adminleads', leads )
    .component('adminlead', lead)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
    //@todo there has to be a smarter way
        .state('admin.leads', {
            abstract: true,
            url: '/leads',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['registrationcodes'])
            }

        })
        .state('admin.leads.index', {
            url: '/list',
            component: 'adminleads'
        })
        .state('admin.leads.detail', {
            url: '/:id',
            component: 'adminlead'
        })
}


export default MODULE_NAME;