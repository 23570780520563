import angular from 'angular';

// import uitree from 'angular-ui-tree'

// import 'angular-ui-tree/dist/angular-ui-tree.min.css'

// import contextmenu from 'angular-bootstrap-contextmenu'

import contextmenu from 'angular-tree-control/context-menu'
import angulartree from 'angular-tree-control'
import angulartreecss from 'angular-tree-control/css/tree-control.css'



// import 'jsoneditor/dist/jsoneditor.css'
// import 'jsoneditor/dist/jsoneditor.min'




const MODULE_NAME = 'admintranslation';

import translations from './translations.component'
import translation from './translation.component'

angular.module(MODULE_NAME, [angulartree, 'angular-jsoneditor'])
    .component('translations', translations)
    .component('translation', translation)
    .config(config)


function config($stateProvider) {
    'ngInject';
    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.translations', {
            abstract: true,
            url: '/translations',
            template: '<ui-view></ui-view>'

        })
        .state('admin.translations.index', {
            url: '/list',
            component: 'translations'
        })
        .state('admin.translations.detail', {
            url: '/:id',
            component: 'translation'
        })
}


export default MODULE_NAME;