class RoomService {
    constructor(AuthenticationService, $log, $http, settings) {
        'ngInject';
        this.AuthenticationService = AuthenticationService
        this.settings = settings
        this.$http = $http;
        this.$log = $log
    }

    openRoom(roomId, demoMode) {


        const self = this
        return this.getPreviewRoomUrl(roomId, demoMode)
            .then((url)=> {
                window.open(url);
            })

    }
    getPreviewRoomUrl(roomId, demoMode){
        const self = this
        return this.$http.get('/admin/conference/rooms/' + roomId + '/previewroomlink')
            .then((res)=> {
                self.$log.info('[conference] url' , res.data)
                return res.data.data.url   + (demoMode? '&mode=demo':'');
            });

    }
    // openRoomOfProgram(roomId) {
    //     this.$http.get('/admin/programs/' + )
    //     let url = this.settings.conferenceFrontendUrl + 'room/' + roomId + '?token=' + this.AuthenticationService.getAccessToken();
    //     window.open(url);
    // }

    openContentAdmin() {
        let jwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyIsInR5cGUiOiJhY2Nlc3MifQ.eyJ1c2VySWQiOjEsImlhdCI6MTQ5ODU1NzYwNCwiZXhwIjoxODE0MTMzNjA0LCJhdWQiOiJodHRwczovL3lvdXJkb21haW4uY29tIiwiaXNzIjoiZmVhdGhlcnMiLCJzdWIiOiJhbm9ueW1vdXMifQ.NPOXiaLe-hvy8fJIFWmabUy6-sZdtbIqcIphwnPwPt0';
        // this.AuthenticationService.getAccessToken();
        let url = this.settings.conferenceAdminUrl + 'auth?jwt=' + jwt;
        window.open(url);
// /        /auth?jwt=
    }


};

export default  RoomService;
