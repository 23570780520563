// In app.js or main.js or whatever:
// var myApp = angular.module('askchisne', ['ngSanitize', 'ngAnimate', 'ui.bootstrap', 'ui.bootstrap.tpls']);

// This filter makes the assumption that the input will be in decimal form (i.e. 17% is 0.17).
// Usage:
// <tr ng-repeat="i in items">
//     <td>{{i.statistic | percentage:2}}</td>
// </tr>

export default function ($filter) {
    'ngInject';
    return function (input, decimals) {
        return $filter('number')(input * 100, decimals) + '%';
    };
}
