import angular from 'angular';

const MODULE_NAME = 'admin-brand';

import brands from './brands.component'
import brand from './brand.component'

angular.module(MODULE_NAME, [])
    .component('adminbrands', brands )
    .component('adminbrand', brand)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
        .state('admin.brands', {
            abstract: true,
            url: '/brands',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.brands.index', {
            url: '/list',
            component: 'adminbrands'
        })
        .state('admin.brands.detail', {
            url: '/:id',
            component: 'adminbrand'
        })
}


export default MODULE_NAME;