import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($http, UserService) {
        'ngInject';
        this.UserService = UserService;

    }

    findPerson(search) {
        return this.UserService.findUser(search);
    }

    selected($item, $model, $label, $event) {
        if ($item && this.onChoose) {
            this.onChoose({user: $item})
        }
    }


}

const component = {
    template: require('./userautocomplete.component.html'),
    controller: Controller,
    bindings: {
        onChoose: '&'
    }
};
export default  component;
