import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'

class Controller {
    constructor(AlertsService, Restangular) {
        'ngInject';
        this.AlertService = AlertsService;
        this.Restangular = Restangular;

    }

    $onInit() {
        this.reset();
    }

    sendWhatsapp(message) {

        const self = this;

        return this.Restangular.all('admin/users/' + this.user.id + '/sendwhatsapp')
            .post({
                message: self.whatsappmessage.text
            })
            .then(() => {
                self.reset();
                self.AlertService.showConstant('success', 'WHATSAPP_SEND');
            })

    }

    reset() {
        this.whatsappmessage = {
            text: `Hello ${this.user.firstName}`
        }
    }


}

// <b>{{$ctrl.user.displayName}}<i class="fa fa-star" class="text-success" ng-if="$ctrl.user.servicelevel > 3.indexOf('PLUS') !== -1"></i></b>
let template = `
<div>
             <div class="alert alert-danger" ng-if="!$ctrl.user.qualifiedWhatsappNumber">
                <span>This user does not have a quaified whatsapp number. Please check the whatsapp settings on page two.</span>
            </div>

             <div class="alert alert-success" ng-if="$ctrl.user.whatsappStatus ==='confirmed'">
                <span>This user has confirmed his whatsapp number.</span>
            </div>


            <textarea class="form-control" width="100%" style="height:150px;"
                      ng-model="$ctrl.whatsappmessage.text"></textarea>



            <button class="btn btn-primary btn-xs btn-block"
                    promise-btn
                    ng-click="$ctrl.sendWhatsapp()">Send Whatsapp
            </button>
</div>
`;

const component = {
    template: template,
    controller: Controller,
    replace: true,
    bindings: {
        user: '<'
    },

};


export default  component;
