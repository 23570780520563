import angular from 'angular';

const MODULE_NAME = 'admin-stories';

import stories from './stories.component'
import story from './story.component'

angular.module(MODULE_NAME, [])
    .component('adminstories', stories )
    .component('adminstory', story)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
        .state('admin.stories', {
            abstract: true,
            url: '/stories',
            template: '<ui-view></ui-view>',
            resolve: {
                // datastore: (DataStore) => DataStore.ensureEntities(['organizations','programs'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.stories.index', {
            url: '/list',
            component: 'adminstories'
        })
        .state('admin.stories.detail', {
            url: '/:id',
            component: 'adminstory'
        })
}


export default MODULE_NAME;
