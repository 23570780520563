
import BaseBreadService from '../../services/BaseBreadService'
var FileSaver = require('file-saver');




class OrganizationsService  extends BaseBreadService {
    constructor($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService, $http) {
        'ngInject';
        super($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService, $http);
        this.$log = $log
        this.endpoint = 'admin/organizations';
        this.$http = $http;

    }
    downloadXlsxReport(id, type){


        function getFileNameFromHttpResponse(httpResponse) {
            var contentDispositionHeader = httpResponse.headers('Content-Disposition');
            var result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
            return result.replace(/"/g, '');
        }

        const url = 'admin/organizations/' + id + '/reports/' + type;


        this.$http.get(url, { responseType: 'arraybuffer', timeout: 180000 })
            .then((result) => {

                const contentType = result.headers('content-type');
                const fileName = getFileNameFromHttpResponse(result);

                var blob = new Blob([result.data], { type: contentType + ';charset=utf-8' });
                FileSaver.saveAs(blob, fileName);
                // var objectUrl = URL.createObjectURL(blob);
                // objectUrl.download = 'download.xlsx';
                // window.open(objectUrl);

            })


    }
    async findOrganization(search, includes) {
        const res = await this.$http({
            url: '/admin/organizations',
            method: "GET",
            params: {search, 'includes[]': includes}
        });
        return res.data.data;
    }
};

export default  OrganizationsService;
