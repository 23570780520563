import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

let localEm;

class Controller {
    constructor($scope, $rootScope, $controller, $state, $stateParams, $httpParamSerializer) {
        'ngInject';

        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;

        let options = {
                name: 'Todo',
                defaultValues: {},
                params: {
                    state: 'any',
                    search: '',
                    'includes[]': ['assignee', 'customer']
                }
            }
        ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/coordinatortodos', options);

        this.$scope.sort = { duedate: 'ASC' };

        this.$scope.filter = { state: 'OPEN' };


        em.run();

        this.em = em;
        localEm = em;
        this.isDetailView = false;



    }

    $onInit() {
        this.listenToStateChange();
    }


    isPast(date) {
        let now = new Date();

        return (new Date(date).getTime() < now.getTime());
    }


    resetFilter() {
        this.$scope.filter = {};
        this.em.refresh();

    }
    openDetailView(){
        this.isDetailView = true
        this.$scope.$apply();
    }

    reload() {

        setTimeout(() => localEm.refresh(), 50);
        ; //hack, but works;

    }


    listenToStateChange() {
        this.isDetailView = (this.$state.params.id);
        // https://ui-router.github.io/guide/ng1/migrate-to-1_0#state-change-events
        var listener = this.$rootScope.$on('$stateChangeSuccess',
            function (event, toState, toParams, fromState, fromParams) {
                this.$scope.selectedId = toParams.id;
                this.isDetailView = true;

            });

        this.$scope.$on('$destroy', () => listener());

    }
}

const component = {
    template: require('./todos.component.html'),
    controller: Controller,
}

export default  component;
