import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $scope, $stateParams, OrganizationsService, DataStore, settings) {
        'ngInject';
        const validUntil = new Date();
        validUntil.setDate(validUntil.getDate() + 365); // default value in 1 year

        let options = {
            name: 'Registrationcode',
            defaultValues: {
                primaryRole: 'mentor',
                validUntil
            },
            params: { 'includes[]': ['users'] }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/registrationcodes', options);
        this.settings = settings;
        this.OrganizationsService = OrganizationsService;
        this.$scope = $scope
        this.DataStore = DataStore

        em.setDetailId($stateParams.id);
        em.addHook('AFTER_SINGLE_GET', (e) => {
            this.updatePrograms(e);

        });

        em.run()
    }

    updatePrograms(model) {
        if (!model.groupId){
            return;
        }
        const groupId = model.groupId;
        const group = this.DataStore.getOne('groups', groupId);


        this.OrganizationsService.get(group.organization.id, { params: { 'includes[]': ['programs'] } })
            .then( res => this.organization = res)
        ;



    }

    enforceCode(model) {
        model.code = model.code || ''
        model.code = model.code.replace(/[^\-\w\s!?]/g, '').toUpperCase();
    }

    getRegistrationLink(code) {
        return this.settings.frontendUrl + '/register/code/' + code;
    }

}

const component = {
    template: require('./registrationcode.component.html'),
    controller: Controller
};
export default  component;
