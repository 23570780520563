import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'


import countries from 'country-list/country-list';


import moment from 'moment-timezone'


class Controller {
    constructor($controller, $http, $confirm, $scope, $stateParams, UserService, settings, $uibModal, EnrollmentsService, registrationCodeService, AlertsService) {
        'ngInject';
        let options = {
            name: 'User',
            defaultValues: {},
            params: {'includes[]': ['enrollments', 'registrationCode', 'roles', 'organization', 'groups']}
        };
        this.registrationCodeService = registrationCodeService;


        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/users', options);

        this.genders = Constants('genders')
        this.locales = Constants('locales')
        this.timezones = moment.tz.names();
        this.feedbackLimit = 10;
        this.$uibModal = $uibModal;
        this.$confirm = $confirm;
        this.$scope = $scope;
        this.EnrollmentsService = EnrollmentsService;
        this.currentTab = 1;
        this.selectedTab = null;
        this.registrationCodes = null;
        this.registrationCodeChanged = false;
        this.AlertsService = AlertsService;
        this.UserService = UserService;


        $scope.datepickerOptions = {minDate: new Date(), open: false};


        em.addHook('AFTER_SINGLE_GET', (e) => {
            if (e.remindAgainAt) {
                e.remindAgainAt = new Date(e.remindAgainAt);
            }
            if (e.validUntil) {
                e.validUntil = new Date(e.validUntil);
            }

        })


        em.setDetailId($stateParams.id);
        em.run()

        this.settings = settings;
        this.newRole = ''
        this.em = em;
        this.$http = $http
        this.countries = this.getCountriesWithUppercasedCodes();

        this.whatsAppStati = {
            'confirmed': 'confirmed',
            'exists': 'exists',
        };
        this.whatsAppSetups = {
            'mobile': 'mobile',
            'notused': 'notused',
            'othernumber': 'othernumber'
        };


    }

    applyRegistrationCode(codeId) {
        let url = 'admin/users/' + this.$scope.model.id + '/applyRegistrationCode/' + codeId;
        this.$http.post(url)
            .then(() => this.em.refresh())
            .then((res) => {
                this.registrationCodeChanged = true;
            })
        ;
    }

    selectTab(name) {
        this.selectedTab = name;

        if (name === 'groups') {
            this.updateRegistrationCodes();
        }

    }

    logManualInteraction() {
        this.$scope.model.lastManualInteractionAt = new Date();
        this.em.save();
    }

    updateRegistrationCodes() {

        if (this.registrationCodes && this.registrationCodes.length > 0) {
            return;
        }
        this.registrationCodeService.getForOrganization(this.$scope.model.organization_id)
            .then((res) => this.registrationCodes = res);


    }

    openDatepicker() {
        this.$scope.datepickerOptions.open = true;
    }

    findMentorship(enrollment) {
        this.$uibModal.open({
            component: 'findMentorshipComponent'
        })

    }

    whatsappStatusToBadge(status) {
        if (status === 'exists') {
            return 'badge-primary';
        }
        if (status === 'confirmed') {
            return 'badge-success';
        }
        return 'badge-warning';

    }

    async logoutUser(model) {
        const textSureDelete = 'Are you sure? This will log out user from all sessions on all devices.';
        const id = model.id;

        await this.$confirm({text: textSureDelete});
        try {
            await this.revokeTokens(id)
            const response = await this.closeSocketConnections(id);
            const closedConnections = (response && response.closedConnections || 0);

            alert('The user was signed out from all sessions, were closed ' + closedConnections + ' socket connections');
        } catch (e) {
            alert('Can not logout user. Check browser console for details');
            console.error(e);
        }
    }

    deleteUser(model) {
        const textSureDelete = 'Are you sure? This will set user to DELETED and anonymize all data';
        this.$confirm({text: textSureDelete});

        this.requestDeleteTransition(model.id).then(response => {
            this.AlertsService.showConstant('success', 'Successfully Deleted!')
        });
    }

    async requestDeleteTransition(id){
        await this.$http.post('/admin/statemachine/user/' + id + '/delete')
    }

    async revokeTokens(id) {
        const url = 'admin/users/' + id + '/logout-all-sessions/';
        await this.$http.get(url);
    }

    closeSocketConnections(id) {
        const url = this.settings.onlineUserServiceUrl + '/platform/admin/users/' + id + '/logout';
        return this.$http.get(url);
    }

    impersonate(model) {

        this.UserService.impersonateUser(model.id);
    }

    resetpassword(model) {

        const textSureDelete = 'Are you sure, you want to reset this password? Any old password will be invalid!';
        const url = 'admin/users/' + model.id + '/resetpassword/';
        const self = this;

        return this.$confirm({text: textSureDelete})
            .then(() => this.$http.post(url))
            .then(() => alert('The user will receive an email containing his login credentials'))
            ;
    }

    hasRole(model, searchRole) {
        const roles = model.roles;
        return _.find(roles, (role) => role.name === searchRole) !== undefined;
    }

    promoteToAmbassador(model) {
        const textSureDelete = 'Are you sure, this user should become an Ambassador? This will send an email and inform him automatically!';
        const url = 'admin/users/' + model.id + '/promoteToAmbassador';

        const self = this;

        return this.$confirm({text: textSureDelete})
            .then(() => this.$http.post(url))
            .then(() => this.refresh())
            .then(() => alert('done!'))
            ;
    }


    promoteToSuperuser(model) {
        const textSureDelete = 'Are you sure, this user should become a superuser? This will send an email and inform him automatically!';
        const url = 'admin/users/' + model.id + '/promoteToSuperuser';

        return this.$confirm({text: textSureDelete})
            .then(() => this.$http.post(url))
            .then(() => this.refresh())
            .then(() => alert('done!'))
            ;
    }

    sendSSOInvite(model) {

        const textSureDelete = 'Are you sure, you want to reset this password and send SSO inviete. do this only oncce!';
        const url = 'admin/users/' + model.id + '/sendSSOInvite';
        //users/{id}/sendSSOInvite

        return this.$confirm({text: textSureDelete})
            .then(() => this.$http.post(url))
            .then(() => alert('The user will receive an email containing his login credentials'))
            ;
    }

    setPasswordDemo(model) {
        const url = 'admin/users/' + model.id + '/setdemopassword/';
        this.$http.post(url)
            .then(() => alert('Password was set to volunteer!'))
        ;

    }

    quitUser(model) {

        const textSureDelete = 'Are you sure you want to disable this user? This will disable enrollments, remove him from matchingpool,...';
        const url = 'admin/users/' + model.id + '/quit';
        const self = this;

        return this.$confirm({text: textSureDelete})
            .then(() => this.$http.post(url))
            .then(() => alert('Sucessfully set to quit'))
            ;

    }

    setNoReplyAllEnrollments(model) {

        const textSureDelete = 'Are you sure you want to set all enrollments to no reply? This will inform the user.';
        const url = 'admin/users/' + model.id + '/noreply';
        const self = this;

        return this.$confirm({text: textSureDelete})
            .then(() => this.$http.post(url))
            .then(() => alert('Sucessfully set to noreply'))
            ;

    }

    migrateUser(model) {

        const textSureDelete = 'Please do this only once per person, because we will send an email!';
        const url = 'external/users/' + model.id + '/sendMigrationMail';
        const self = this;

        return Promise.resolve() // this.$confirm({ text: textSureDelete })
            .then(() => this.$http.post(url))
            .then(() => alert('The user will receive an email containing his login credentials'))
            ;

    }

    quickEnroll(model, pgm) {
        // selectedPgm

        let l = _.find(model.enrollments, (e) => e.program.id === pgm && e.role === model.primaryRole)
        if (l) {
            alert('This combination does already exists');
            return;
        }

        this.EnrollmentsService.create({
            userId: model.id,
            programId: pgm,
            state: 'ACTIVE',
            training_completed_at: new Date(),
            role: model.primaryRole
        })
            .then(() => this.em.refresh())
            .then(() => {
                alert('successfully enrolled into  this program with status active')

            })


    }

    addRole(model, role) {
        if (!role || role === '') {
            return;
        }

        let url = 'admin/users/' + model.id + '/roles/' + role.id
        this.$http.post(url)
            .then(() => this.em.refresh())

    }

    removeRole(model, role) {
        let url = 'admin/users/' + model.id + '/roles/' + role.id
        if (role.displayName === 'User' || role.displayName === 'Admin') {
            alert('You cannot remove Admin or User Role')
        }
        this.$http.delete(url)
            .then(() => this.em.refresh())

    }

    resetReminder() {
        this.$scope.model.remindComment = '';
        this.$scope.model.remindAgainAt = null;

    }

    openDatepicker() {
        this.$scope.datepickerOptions.open = !this.$scope.datepickerOptions.open;

    }

    getCountriesWithUppercasedCodes() {
        // todo: replace later by request to countries resource;
        const countriesWithLoverCasedCodes = countries().getCodeList();
        return Object.keys(countriesWithLoverCasedCodes)
            .reduce((result, key) => ({...result, [key.toUpperCase()]: countriesWithLoverCasedCodes[key]}), {})
    }
}

const component = {
    template: require('./user.component.html'),
    controller: Controller
};


export default  component;
