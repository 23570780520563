import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'

class Controller {
    constructor() {


    }
}

// <b>{{$ctrl.user.displayName}}<i class="fa fa-star" class="text-success" ng-if="$ctrl.user.servicelevel > 3.indexOf('PLUS') !== -1"></i></b>
let template = `
<div class="useravatar">
    <img ng-show="$ctrl.user.avatar.small" ng-src="{{$ctrl.user.avatar.small}}" height="30" width="30" class="profile-pic">
    <username user="$ctrl.user"></username>
</div>
`;

const component = {
    template: template,
    // controller: Controller,
    replace: true,
    bindings: {
        user: '<'
    },

};


export default  component;
