import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, DataStore) {
        'ngInject';
        this.documentKeys = DataStore.get('options').BrandedDocumentKeys
        this.categories = this.calculateCategoriesFromKeys(this.documentKeys);
        $scope.DataStore = DataStore;
    }

    /**
     *
     * @param keys array ['enrolllment_a','enrollment_b', 'match_a',... ]
     * @return array categories: ['enrollment' => [..], ['match' => [], ... ]
     */

    calculateCategoriesFromKeys(keys) {
        const output = {'others': []};
        keys.forEach(documentKey => {
            if (documentKey.indexOf('_') === -1) {
                output.others.push(documentKey);
                return;
            }
            const category = documentKey.split('_')[0];
            if (!output[category]) {
                output[category] = [];
            }
            output[category].push(documentKey);
        });

        // if category has less then 3 entries, merge it to others.
        for (let key in output) {
            if (key === 'others') {
                continue;
            }
            const value = output[key];
            if (value.length <= 3) {
                output.others = output.others.concat(value);
                delete output[key];
            }
        }
        return output;
    }
}

const
    component = {
        template: require('./documentbrowser.component.html'),
        controller: Controller,
        bindings: {
            filter: '<',
            embededView: '<'
        }
    }

export default component;
