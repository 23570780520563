class Controller {
    constructor($controller, $scope, $http, settings, DataStore, $stateParams) {
        'ngInject';

        this.scope = $scope;
        this.scope.pagination = {};
        this.scope.headers = [
            'Name', ' Finished courses', 'Failed quizzes', 'Passed quizzes'
        ];
        this.$http = $http;
        this.fetchData();

    }

    paginationUpdated(updatedPagination) {
        this.scope.pagination = updatedPagination;
        this.fetchData()
    }
    async fetchData() {
        const url = 'reports/trainings';
        const response = await this.$http({url, method: 'GET', params: this.scope.pagination})
        this.scope.pagination = response.data.pagination;
        this.scope.users = response.data.data.map(user => {
            return {
                name: user.full_name,
                finished_courses: user.courseReport.finished_courses + "/" + user.courseReport.total_courses,
                failed_quizzes: user.quizReport.failed_quizzes + "/" + user.quizReport.total_quizzes,
                passed_quizzes: user.quizReport.passed_quizzes + "/" + user.quizReport.total_quizzes,

            };
        })
        this.scope.$apply();

    }
}

const component = {
    template: require('./userTrainingReport.component.html'),
    controller: Controller
};
export default component;
