
import BaseBreadService from '../../services/BaseBreadService'


class EnrollmentService  extends BaseBreadService {
    constructor($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService) {
        'ngInject';
        super($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService);
        this.$log = $log
        this.endpoint = 'admin/enrollments';
    }


};

export default  EnrollmentService;
