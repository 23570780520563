import angular from 'angular';

import module from '../app'

const MODULE_NAME = 'auth';

import login from './login.component'
import CurrentUserService from './currentuser.service'
import AuthenticationService from './authentication.service'
import HttpErrorHandler from './httperrorhandler.factory'




angular.module(MODULE_NAME, ['ui.router'])
    .component('login', login)
    .service('AuthenticationService', AuthenticationService)
    .service('CurrentUserService', CurrentUserService)
    .factory('httpErrorHandler', HttpErrorHandler)
    .config(config)
    .config(configIntercepter)
;

function configIntercepter($httpProvider){
    $httpProvider.interceptors.push('httpErrorHandler');
}


function config($stateProvider) {
    $stateProvider
        .state('auth', {
            url: '/auth',
            template: '<ui-view></ui-view>',
            abstract:true
        }).state('auth.login', {
            url: '/login',
            template: '<login></login>',
            // {
            //     url: '/inbox',
            //         template: '<inbox mails="$resolve.mails"></inbox>',
            //     resolve: {mails: function(Mails) {return Mails.fetch(); }}
            // }
            // resolve: {mails: function(Mails) {return Mails.fetch(); }}
        })
}


export default MODULE_NAME;
