
import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $stateParams, $httpParamSerializer) {
        'ngInject';


        let options = {
                name: 'Supportanswer',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '', 'includes[]': [],
                }
            }
            ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/supportanswers', options);
        em.run();




    }
    getClassForRow(row){
        switch (row.type){
            case 'solution':return 'success';
            case 'question':return 'primary';
            default: return 'default';
        }
    }
    isSolution(row){
        return row.title.indexOf('Solution') !== -1;
    }
}

const component = {
    template: require('./supportanswers.component.html'),
    controller: Controller,
}

export default  component;
