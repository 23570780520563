
import 'angularjs-toaster/toaster.min.css'

/**
 * AlertsService
 * -------------
 *  Usage:
 *   AlertsService.confirm(options)
 *
 *   AlertsService.alert(options)
 *   AlertsService.showConstant('success','HELLO_WORLD'); // see translation file
 *
 *angular.module('mmApp')
 .service('AlertsService',
 */


function AlertsService($translate, $uibModal, $confirm, toaster) {
    'ngInject';

    this.showConstant = showConstant;
    this.show = show;

    this.confirm = confirm;
    this.simpleModal = simpleModal;
    this.alert = alert;
    this.articleModal = articleModal;
    if (!toaster.options){
        toaster.options = {};
    }
    toaster.options.positionClass = 'toast-bottom-right';


    function articleModal(slug) {
        $uibModal.open({
            template: '<article-directive is-modal="true" close="$close" slug="\'' + slug + '\'"></article-directive>'
        });
    }

    function confirm(options, settings) {
        options = options || {};
        options.title = options.title || 'Confirm';
        options.yes = options.yes || 'proceed';
        options.cancel = options.cancel || 'cancel';

        options.title = $translate.instant(options.title, options.titleVariables);
        options.text = $translate.instant(options.text, options.textVariables);
        options.yes = $translate.instant(options.yes);
        options.ok = $translate.instant(options.yes);
        options.cancel = $translate.instant(options.cancel);
        options.backdrop = 'static';
        return $confirm(options, settings);
    }

    function alert(text, options) {

        options = options || {};
        options.text = text;
        options.closeCaption = 'OK';
        return simpleModal('', options);
    }

    function simpleModal(title, elements) {
        elements = elements || {};
        elements.templateUrl = elements.templateUrl || 'app/common/simpleModal.html';


        return $uibModal.open({
            templateUrl: elements.templateUrl,
            controller: function ($scope, $uibModalInstance) {
                $scope.title = title;
                $scope.closeCaption = 'close';
                $scope.responseText = elements.responseText || '';

                if (elements.text) {
                    elements.text = $translate.instant(elements.text, elements.textVariables);
                }


                _.each(elements, function (value, key) {
                    $scope[key] = value;
                });
                $scope.close = function (responseText) {
                    $uibModalInstance.close({textarea: $scope.responseText});
                };

            }
        });

    }


    function showConstant(type, constant, variables) {

        type = checkType(type);
        let title = $translate.instant('toastr.' + constant + '.hl', variables);
        let message = $translate.instant('toastr.' + constant + '.text', variables);

        if (title == 'toastr.' + constant + '.hl') {
            console.error('did not find translation for constat', title);
            title = (type === 'success'?'Success':'Error');
        }
        if (message == 'toastr.' + constant + '.text') {
            message = $translate.instant(constant, variables);
        }

        toaster.pop(type, title, message);
        // toastr[type](message, title);
    };

    /**
     * see docu of toaster: https://github.com/jirikavi/AngularJS-Toaster
     *
     * @param type (error | info etc.)
     * @param origMessage
     * @param title
     * @param variables additional variables in tranlsation (@todo)
     */
    function show(type, origMessage, title, variables) {
        type = checkType(type);
        title = title ? $translate.instant(title) : 'Information';
        var message = origMessage ? $translate.instant(origMessage) : '';
// title = title ? $translate.instant('toastr.' + title) : 'Information';
//         var message = origMessage ? $translate.instant('toastr.' + origMessage) : '';

        toaster.pop(type, title, message);

        // toastr[type](title, message);
    };


    function checkType(type) {
        // if (!toastr[type]) {
        //     console.error('[MessagingService] Wrong message type', type);
        //     type = 'success';
        // }
        return type;

    }


}

export default  AlertsService;
