'use strict';


//
// @todo
//


class TenantService {
    constructor($rootScope, $q, $log, $state, $http) {
        'ngInject';

        const self = this;
        this.$logger = $log.getInstance('TenantService');
        this.$http = $http;
        this.$rootScope = $rootScope;
        this.currentTenant = null;

        self.$rootScope.$on('auth.userupdate', (user) =>{
            //
        });
        return this;

    }

    updateTenant(tenantId){


        if (tenantId == null){
            delete this.$http.defaults.headers.common;
            delete this.currentTenant;
        }

        this.$http.defaults.headers.common['X-Tenant-Id'] = tenantId;
        this.currentTenant = tenantId;
        this.$rootScope.$emit('refresh:all');
        // this.$logger('updated tenant to ', tenantId);


    }

}


export default  TenantService;
