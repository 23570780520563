import angular from 'angular';

const MODULE_NAME = 'admin-supportanswer';

import supportanswers from './supportanswers.component'
import supportanswer from './supportanswer.component'

angular.module(MODULE_NAME, [])
    .component('adminsupportanswers', supportanswers )
    .component('adminsupportanswer', supportanswer)
    .config(config)


function config($stateProvider) {
    'ngInject';

    //@todo there has to be a smarter way
    $stateProvider
        .state('admin.supportanswers', {
            abstract: true,
            url: '/supportanswers',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.supportanswers.index', {
            url: '/list',
            component: 'adminsupportanswers'
        })
        .state('admin.supportanswers.detail', {
            url: '/:id',
            component: 'adminsupportanswer'
        })
}


export default MODULE_NAME;