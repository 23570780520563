
export default function (CurrentUserService) {
    'ngInject';
    return function(scope, element, attrs) {
        if (CurrentUserService.hasRole('admin') || CurrentUserService.hasRole('superadmin')){
            // element.css('display', 'none');
        } else {
            element.css('display', 'none');

        }
    }
}

/**
 * <span class="label label-default">Default Label</span>
 <span class="label label-primary">Primary Label</span>
 <span class="label label-success">Success Label</span>
 <span class="label label-info">Info Label</span>
 <span class="label label-warning">Warning Label</span>
 <span class="label label-danger">Danger Label</span>

 */



