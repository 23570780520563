
import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller) {
        'ngInject';
        let options = {
                name: 'Userfeedback',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '', 'includes[]': ['user','user.organization'],
                },
                sort: { 'user_feedbacks.createdAt':'desc'}
            }
            ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/userfeedbacks', options);
        em.run();
        $scope.getNumber = function(num) {
            return new Array(num);
        }
        this.$scope = $scope;
        this.em = em;


    }
    updateStarFilter(stars) {
        this.$scope.filter =  [{ key: 'responseScalar', value: stars, operator: 'lte' }];
        this.em.refresh();



    }
}

const component = {
    template: require('./userfeedbacks.component.html'),
    controller: Controller,
    bindings: {
        isModule: '='
    }
}

export default  component;
