import RestHelperList from "../../lib/api/resthelperlist.class";

class Controller {
    constructor($controller, $scope, UserService) {
        'ngInject';

        this.$scope = $scope;
        this.em = $controller(RestHelperList);
        this.UserService = UserService;
    }

    $onInit() {

        const roles = this.roles ? this.roles : ['manager'];
        this.forceFilter = [
            { key: 'primaryRole', value: roles, operator: 'in' },
            { key: 'organization_id', value: this.organizationid, operator: 'eq' },
        ];

        let options = {
                name: 'Manager',
                defaultValues: {},
                forceFilter: this.forceFilter,
                params: {
                    limit: 30,
                },

            }
        ;

        this.em.setup(this.$scope, 'admin/users', options);
        this.em.run();
    }

    impersonate(model) {
        this.UserService.impersonateUser(model.id);
    }


    refresh() {
        this.em.refresh();
    }
}

// <b>{{$ctrl.user.displayName}}<i class="fa fa-star" class="text-success" ng-if="$ctrl.user.servicelevel > 3.indexOf('PLUS') !== -1"></i></b>
let template = `
            <div>
                                       
                <table  ng-if="rows" class="table-clickable table table-slim" >
                        <tr>
                            <th>Name</th>    
                            <th>Role</th>
                            <th>Action</th>
                        </tr>
                        <tr ng-repeat="row in rows" >
                            <td><useravatar user="row"></useravatar></td>                                       
                            <td><userrole role="row.primaryRole"></userrole></td>
                            <td><button class="btn btn-default btn-xs" ng-click="$ctrl.impersonate(row)">Login with this user</button></td>
                        </tr>
                        <tr ng-if="!rows || rows.length === 0">
                            <td> Keine Einträge gefunden </td>
                            
                        </tr>
                   </table>
               </div>

`;

const component = {
    template: template,
    controller: Controller,
    bindings: {
        organizationid: '<',
        roles: '<'
    },


};


export default  component;
