class Controller {
    constructor($scope, CurrentUserService, $state, settings, AuthenticationService) {
        'ngInject';
        this.userService = CurrentUserService;

        this.$state = $state
        this.AuthenticationService = AuthenticationService
        this.settings = settings;
        // this.currentUser2 = CurrentUserService.getUser();
        // this.currentUser3 = CurrentUserService;

    }
    isDevelopment() {
        return this.settings.env === 'dev';
    }
    isStaging() {
        return this.settings.env === 'staging';

    }

    search(value){
        this.$state.go('admin.dashboard.search', {search: value});
    }

    logout() {

        this.$state.go('auth.login')
        this.AuthenticationService.logout()

    }
}

const component = {
    template: require('./navbar.component.html'),
    controller: Controller
};


export default  component;


