class Settings {
    constructor($sceDelegateProvider) {
        'ngInject';
        const self = this;
        this.$sceDelegateProvider = $sceDelegateProvider
        this.settings = {
            env: process.env.NODE_ENV,
            languages: ['de', 'en'],
        };


        this.$get = () => self.settings;
    }

    useVariableIfSet(source, target) {
        if (!process.env[source] || process.env[source] === 'false') {
            return;
        }
        this.settings[target] = process.env[source];
    }

    defaults() {

        this.useVariableIfSet('TENANT_ID', 'tenant_id');
        this.useVariableIfSet('API_BASE_URL', 'apiBaseUrl');
        this.useVariableIfSet('FRONTEND_URL', 'frontendUrl');
        this.useVariableIfSet('ADMIN3_URL', 'admin3Url');
        this.useVariableIfSet('ONLINE_USER_SERVICE', 'onlineUserServiceUrl');
        this.useVariableIfSet('CONFERENCE_FRONTEND', 'conferenceFrontendUrl');
        this.useVariableIfSet('CONFERENCE3_FRONTEND', 'conferenceFrontend3Url');
        this.useVariableIfSet('CLIENT_ID', 'client_id');
        this.useVariableIfSet('CLIENT_SECRET', 'client_secret');
        this.useVariableIfSet('WHATSAPP_ENABLED', 'whatsapp_enabled');
        // Confernece Backend is not linked; 'https://admin.conference.staging.volunteer-vision.com/'

        this.settings.apiFullUrl = this.settings.apiBaseUrl + '/api/v2/';
        this.$sceDelegateProvider.resourceUrlWhitelist([
            'self',
            'http://localhost:8000/**',
            'http://localhost:8100/**',
            'https://www.youtube-nocookie.com',
            this.settings.apiBaseUrl + '/**',
        ]);

    }

}

export default  Settings;
