class Controller {
    constructor($scope, CurrentUserService) {
        'ngInject';

        if (CurrentUserService.hasRole('ADMIN')) {
            this.showTechnicalDetails = false
        }

    }

    toggleDetails() {
        this.showTechnicalDetails = !this.showTechnicalDetails
    }
}

const template = `
            <div show-admin style='padding:20px 0;'>
                <button ng-click='$ctrl.toggleDetails();' class='btn btn-default' > 
                    Show technical details <i class='fa fa-carrot'></i>
                </button><hr/>
                <div ng-if='$ctrl.showTechnicalDetails'>
                    Daten:<br/>
                    <pre>{{$ctrl.data | json}}</div>
                </div>
            </div>
            `

const component = {
    bindings: {
        'data': '='
    },
    controller: Controller,
    template: template
}


export default  component;



