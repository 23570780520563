import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'
import RestHelperList from "../../lib/api/resthelperlist.class";

class Controller {
    constructor($scope) {
        'ngInject';

        $scope.getNumber = function (num) {
            return new Array(num);
        }
    }

}

let template = `
    <div class="row">
    <div class="">
        <p> {{$ctrl.feedback.questionCode}}<br/>
        <div ng-if="$ctrl.feedback.responseScalar">
                 <span class="text-primary" ng-repeat="i in getNumber($ctrl.feedback.responseScalar)  track by $index" >{{i}}<i class="fa fa-star"></i></span>
        </div>
        <span ng-if="$ctrl.feedback.responseText" ng-bind-html="$ctrl.feedback.responseText">
        </span>
        </p> 
        </div>
    </div>
`;

const component = {
    template: template,
    controller: Controller,
    bindings: {
        feedback: '<'
    },

};


export default  component;

