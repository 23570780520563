import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'
import moment from 'moment-timezone'



class Controller {
    constructor($controller, $scope, $stateParams) {
        'ngInject';
        let options = {
            name: 'Appointment',
            defaultValues: {},
            params: { 'includes[]': ['mentorship'] }
        };
        // this.currentTimezone = moment.tz.guess();

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/appointments', options);


        this.timezones =
            ['Africa/Abidjan',
                'Europe/Budapest',
                'America/Lima',
                'America/New_York',
                'Asia/Dubai',
                'Europe/Zagreb',
                'America/Los_Angeles',
                'America/Chicago',
                'Europe/Berlin',
                'Africa/Lagos',
                'America/Guatemala',
                'Europe/London',
                'Europe/Madrid',
                'Europe/Amsterdam',
                'Europe/Paris',
                'America/Panama',
                'Asia/Calcutta',
                'America/Mexico_City'].sort();



        em.setDetailId($stateParams.id);
        em.run()
    }
    inTimezone(date, tz){
        /*
              LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'

         */
        var format = 'LLLL';

        return moment(date).tz(tz).format(format);
    }
}

const component = {
    template: require('./appointment.component.html'),
    controller: Controller
};
export default  component;
