import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $http, $scope, Upload, $stateParams, settings) {
        'ngInject';

        this.$http = $http;
        this.$scope = $scope;

    }


}

const component = {
    template: require('./supportwidget.component.html'),
    controller: Controller,
    bindings: {
    }
};
export default  component;
