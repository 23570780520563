import angular from 'angular';

const MODULE_NAME = 'admin-todo';

import todos from './todos.component'
import todo from './todo.component'

angular.module(MODULE_NAME, [])
    .component('admintodos', todos )
    .component('admintodo', todo)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
    //@todo there has to be a smarter way
        .state('admin.todos', {
            abstract: true,
            url: '/todos',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.todos.index', {
            url: '/list',
            component: 'admintodos'
        })
        .state('admin.todos.detail', {
            url: '/:id',
            component: 'admintodo'
        })
        .state('admin.todos.index.detail', {
            url: '/:id',
            component: 'admintodo'
        })
}


export default MODULE_NAME;