const _constants = {

    // states
    'userStates': ['NEW', 'REGISTERED', 'QUIT', 'BANNED', 'INMIGRATION'],
    'userProcessStates': ['NEW', 'REGISTERED'],

    'webinarStates': ['NEW', 'PLANNED', 'CANCELED', 'SUCCESS'],
    'webinarProcessStates': ['NEW', 'PLANNED', 'SUCCESS'],

    'mentorshipStates': ['UNCONFIRMED', 'REQUESTED', 'CONFIRMED', 'REJECTED', 'ACTIVE', 'PAUSED', 'DONE', 'CANCELED'],
    'mentorshipProcessStates': ['NEW', 'UNCONFIRMED', 'CONFIRMED', 'ACTIVE', 'DONE'],


    'todoStates': ['OPEN', 'WAITING', 'DONE'],
    'todoProcessStates': ['OPEN', 'WAITING', 'DONE'],

    'appointmentStates': ['NEW', 'PLANNED', 'CANCELED', 'NOSHOW', 'ONLINE', 'NOATTENDANCE', 'SUCCESS', 'HANGUP'],
    'appointmentProcessStates': ['PLANNED', 'CONFIRMED', 'ONLINE', 'SUCCESS'],

    'organizationStates': ['ONBOARDING', 'ROLLOUT', 'ACTIVE', 'EXIT'],
    'organizationProcessStates': ['ONBOARDING', 'ROLLOUT', 'ACTIVE'],

    'enrollmentStates': ['NEW', 'QUIT', 'ACTIVE', 'NOREPLY', 'EXCLUDED','WAITINGLIST'],
    'enrollmentProcessStates': ['NEW', 'ACTIVE'],

    'roles': ['mentor', 'mentee'],

    'todoTypes': [
        'APPOINTMENT_TECHNICAL_ISSUES',
        'APPOINTMENT_TOO_SHORT',
        'ENROLLMENT_INCOMPLETE',
        'INPERSON_CHECKIN',
        'MANUAL',
        'MATCH_CANCELED',
        'MENTORSHIP_REJECTED',
        'NO_FIRST_APPOINTMENT',
        'NO_FUTURE_APPOINTMENT',
        'NO_SHOW',
        'POOR_CALL_CHECK',
        'USER_BAD_FEEDBACK',
        'WEBINAR_REMINDER'
        
        
    ],
    'enrollmentRoles': ['mentor', 'mentee'],
    'commentTypes': ['testimonial', 'organisational', 'complain', 'SUPERVISOR_COMMENT', 'other', 'outboundcall','itcheck'],
    'genders': ['male', 'female', 'nonbinary', 'nochoice'],
    'documentTypes': ['sms', 'email', 'video', 'article', 'file', 'whatsapp'],
    'documentScopes': ['supervisor', 'preparation', 'lection', 'csr'],
    'organizationTypes': ['socialservice', 'company'],
    'questionsCodes': ['CONNECTION_QUALITY', 'LIKE_MENTORSHIP'],
    'groupParticipationTypes': ['member', 'supervisor', 'manager', 'super_manager'],
    'serviceLevels': ['noservice', 'normal', 'premium', 'priority'],
    'langs': ['de', 'en', 'es'],
    'locales': [{
        iso: 'de',
        title: 'Deutsch',
        wildcard: 'de_*',
        code: 'de_DE',
        codeJs: 'de-de'
    }
        , {
            iso: 'en',
            code: 'en_US',
            codeJs: 'en-us',
            wildcard: 'en_*',
            title: 'English'
        },
        {
            iso: 'es',
            code: 'es_ES',
            codeJs: 'es-ES',
            wildcard: 'es_*',
            title: 'Spanish'
        }]
};


function get(name) {
    if (!_constants[name]) {
        console.error('[ConstantsService] Requested unknown constant', name);
        return null;
    }
    return _constants[name];
}


export default get;

