

class Controller {
    constructor($scope,$http) {
        'ngInject';
        this.$http = $http;
        this.$scope = $scope;
        this.getPlans();

    }
    getPlans()
    {
        this.$scope.isLoading = true;
        this.$scope.plans = [];

        this.$http.get('admin/plan-features')
            .then((result) =>
            {
                this.$scope.plans = result.data.data;
                this.$scope.isLoading = false
            }


            );


    }
}




const component = {
    template: require('./plans.component.html'),
    controller: Controller,
}

export default component;
