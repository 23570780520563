// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };
import _ from 'lodash'
import RestHelperList from "../../lib/api/resthelperlist.class";


const template = `

<div style="height:100%">
<style>    ng-map {display:block; width:100%; height: calc(100vh - 100px); }
</style>
<p> showing {{$ctrl.users.length}} users</p>

<div class="row">
<div class="col-md-2">
            <select class="form-control"
                                        ng-init="orgs = DataStore.get('organizations')"
                                        ng-change="EntityHelper.refresh()"
                                        ng-model="filter.organization_id"
                                        ng-options="c.id as c.displayName for c in orgs | orderBy:'displayName'">
                                    <option value="">any organization</option>
                                </select>

</div>
<div class="col-md-2">                                
                                
                                 <select class="form-control" ng-change="EntityHelper.refresh()"
                                        ng-model="filter.primaryRole"
                                        ng-options="c.name as c.displayName for c in DataStore.get('roles')">
                                    <option value="">any role</option>
                                </select>
                                
</div>
</div>

    <div map-lazy-load="https://maps.google.com/maps/api/js?key=AIzaSyB9Izab3Py6Bngi6B9UHL6SQlV8xxNxBJE" ng-if="$ctrl.users">

  
    <ng-map center="41,-87" zoom="3">
          <marker ng-repeat="user in $ctrl.users"   position="{{user.lat}}, {{user.lng}}" title="{{user.displayName}}"></marker>  
    </ng-map>
   </div>
</div>
`;

// @docs: https://ngmap.github.io/

class Controller {
    constructor($rootScope, $scope, $controller, NgMap, UserStore) {
        'ngInject';

        this.NgMap = NgMap;

        let options = {
                name: 'User',
                defaultValues: {},
                params: {
                    limit: 10000,
                    view: 'map'
                    // 'includes[]': ['organization','roles'],
                },
                filter: [{ key: 'lat', value: '0', operator: 'gt' }, { key: 'lng', value: '0', operator: 'gt' }]
            }
        ;
        let self = this
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/users', options);
        em.run();

        em.addHook('AFTER_LIST_GET', (results) => {

            self.users = results;
            //
            self.NgMap.getMap().then(function (map) {

            });

        })


        // after fresh do something;


    }

    userSelected(user) {
        this.$state.go('admin.users.detail', { id: user.id });
    }

    refresh() {
        const self = this;
        self.users = [];

        //
        // this.UserStore.getAllUsers('map')
        //     .then(users => _.filter(users, u => u.lat && u.lng && parseFloat(u.lat) != 0.0)) //
        //     .then((users) => {
        //         this.users = users;
        //
        //         this.NgMap.getMap().then(function (map) {
        //             console.log(map.getCenter());
        //             console.log('markers', map.markers);
        //             console.log('shapes', map.shapes);
        //         });
        //
        //     })
        // ;

    }
}

const component = {
    template: template,

    controller: Controller

};


export default  component;
