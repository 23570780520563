
class Controller {
    constructor($rootScope, $scope, $state, $http, CurrentUserService) {
        'ngInject';
        this.CurrentUserService = CurrentUserService;
        this.$state = $state;
        this.$http = $http;
        this.model = { password: '', passwordConfirmation:''}

    }

    sendResetPassword() {
        this.CurrentUserService.resetPassword(this.model);
    }


}

const component = {
    template: require('./passreset.component.html'),
    controller: Controller

};


export default  component;
