class Controller {

    constructor($scope, $http, $uibModalStack, AlertsService) {
        'ngInject';

        this.$http = $http
        this.$uibModalStack = $uibModalStack
        this.password = null
        this.AlertsService = AlertsService

    }

    $onInit() {
        this.organization = this.resolve.organization
    }

    checkPassword(password){
       return this.$http.post('/admin/check-password/',  {password: password})
            .then((res) =>{
                if (res.data.data === 'Password correct'){
                     this.anonymizeUsers().then(e => e)
                } else{
                    this.AlertsService.showConstant('error', 'Password incorrect');
                }
            })
    }
    confirm() {
        const self = this
        this.checkPassword(this.password).then(function () {
            return self.close();
        })
    };
   async anonymizeUsers() {
        return this.$http.post('admin/organizations/' + this.organization.id + '/anonymize')
            .then((res) => {
                    this.AlertsService.showConstant('success', 'Successfully removed');
                }
            )
            .catch((res) => {

            })


    }

    cancel() {
        this.dismiss()
    };

}

const component = {
    template: require('./anonymizeUsers.component.html'),
    controller: Controller,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
}

export default  component;
