import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'
import showdown from "showdown/dist/showdown.min";

class Controller {
    constructor($controller, $scope, $http, $confirm, $stateParams) {
        'ngInject';
        this.$http = $http;


        this.qrcode = null;
    }
    $onInit() {
        this.refresh();
    }
    refresh() {
        const url = (this.userid ? '/admin/users/' + this.userid + '/2faqr' : '/admin/me/2faqr')

        this.$http.get(url)
            .then(res => {
                this.qrcode = res.data.qrcode
            });
    }


}

const html = `
<div>
    <p> To enable two factor authentcation, please download Google Authenticator App to your phone and scan this code.</p>
    <img  ng-if="$ctrl.qrcode" src="{{$ctrl.qrcode}}" />
</div>
`;

const component = {
    template: html,
    controller: Controller,
    bindings: {
        userid: '<'
    }

};
export default  component;
