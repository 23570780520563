//
// Usage:
// <tr ng-repeat="i in items | reverse">
//    ...
// </tr>

export default function () {
    return function (items) {
        return items ? items.slice().reverse() : [];
    };

}
