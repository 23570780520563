import angular from 'angular';

const MODULE_NAME = 'admin-mentorship';

import mentorships from './mentorships.component'
import shortMentorship from './short-mentorship.component'
import mentorship from './mentorship.component'
import cancelMentorshipWizard from './cancel-mentorships-wizard.component'
import MentorshipService from './mentorship.service'
import confirmMentorshipComponent from "./confirm-mentorship.component";


angular.module(MODULE_NAME, [])
    .component('mentorships', mentorships )
    .component('mentorship', mentorship)
    .component('shortMentorship', shortMentorship)
    .component('cancelMentorshipWizard', cancelMentorshipWizard)
    .component('confirmMentorshipComponent', confirmMentorshipComponent)
    .service('MentorshipService', MentorshipService)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.mentorships', {
            abstract: true,
            url: '/mentorships',
            template: '<ui-view></ui-view>',
        })
        .state('admin.mentorships.index', {
            url: '/list',
            component: 'mentorships'
        })
        .state('admin.mentorships.detail', {
            url: '/:id',
            component: 'mentorship'
        })
}


export default MODULE_NAME;
