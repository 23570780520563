import angular from 'angular';

const MODULE_NAME = 'admin-program';

import programs from './programs.component'
import program from './program.component'
import programcard from './programcard.component'
import programname from './programname.component'
import shortprogram from './shortprogram.component'

angular.module(MODULE_NAME, [])
    .component('adminprograms', programs )
    .component('adminprogram', program)
    .component('programcard', programcard)
    .component('programname', programname)
    .component('shortprogram', shortprogram)
    .service('ProgramsService', require('./programs.service').default)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.programs', {
            abstract: true,
            url: '/programs',
            template: '<ui-view></ui-view>',
            resolve: {
                // datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.programs.index', {
            url: '/list',
            component: 'adminprograms'
        })
        .state('admin.programs.detail', {
            url: '/:id',
            component: 'adminprogram'
        })
}


export default MODULE_NAME;
