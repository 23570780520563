import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'

// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };
class Controller {
    constructor($controller, $scope) {
        'ngInject';
        this.$controller = $controller;
        this.$scope = $scope;
    }

    $onInit() {
        let options = {
            name: 'Mentorship',
            defaultValues: {},
            params: { 'includes[]': ['appointments'] }
        };


        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/mentorships', options);
        em.setDetailId(this.id);
        em.run()
        this.em = em;


    }

}

const component = {
    template: require('./short-mentorship.component.html'),
    controller: Controller,
    bindings: {
        id: '<'
    },

};


export default  component;
