class CourseService {
    constructor($log, $http, settings) {
        'ngInject';
        this.settings = settings
        this.$http = $http;
        this.$log = $log
    }

    createNewSection({ title, description }) {
        return this.$http.post('admin/sections', { title, description })
            .then((res) => res.data.data);
    }
    createQuiz({ sectionId, passGradePercentage }) {
        return this.$http.post('admin/quizzes', {tcSectionId: sectionId, passGradePercentage:passGradePercentage })
            .then((res) => res.data.data);
    }
    updateQuiz({sectionId, quizId,  passGradePercentage }) {
        return this.$http.put('admin/quizzes/'+quizId, {tcSectionId: sectionId, passGradePercentage:passGradePercentage })
            .then((res) => res.data.data);
    }
    removeQuiz({ quizId }) {
        return this.$http.delete('admin/quizzes/'+quizId)
            .then((res) => res.data.data).catch((error) => error);
    }
    createQuestion({ quizId, text, type }) {
        return this.$http.post('admin/quizQuestions', {tcQuizId: quizId, type: type, text: text })
            .then((res) => res.data.data);
    }
    updateQuestion({questionId, quizId, text, type }) {
        return this.$http.put('admin/quizQuestions/'+questionId, {tcQuizId: quizId, type: type, text: text })
            .then((res) => res.data.data);
    }
    removeQuestion({ questionId }) {
        return this.$http.delete('admin/quizQuestions/'+questionId)
            .then((res) => res.data.data);
    }
    createAnswer({ questionId, text, isCorrect }) {
        return this.$http.post('admin/quizAnswers', {tcQuizQuestionId: questionId, isCorrect: isCorrect, text: text })
            .then((res) => res.data.data);
    }
    updateAnswer({answerId, questionId, text, isCorrect }) {
        return this.$http.put('admin/quizAnswers/'+answerId, {tcQuizQuestionId: questionId, isCorrect: isCorrect, text: text })
            .then((res) => res.data.data);
    }
    removeAnswer({ answerId }) {
        return this.$http.delete('admin/quizAnswers/'+answerId)
            .then((res) => res.data.data).catch((error) => error);
    }
    attachSectionToCourse(section, courseId) {
        return this.$http.post(`admin/sections/${section.id}/courses/${courseId}`);

    }

    createVideo({ sectionId }) {
        return this.$http.post(`admin/videos`, { tcSectionId: sectionId, description:'-',title:'-',url:'http://', duration:0 });


    }

};

export default  CourseService;
