// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

import Constants from '../../lib/constants'
import RestHelperDetail from "../../lib/api/resthelperdetail.class";


class Controller {
    constructor($scope, $state, $controller, RestangularFull) {
        'ngInject';
        this.$scope = $scope
        this.Restangular = RestangularFull
        this.api = null //client
        this.$controller = $controller;

        const self = this;
        // this.reset();
    }


    $onInit() {

        //
        //
        let options = {
            name: 'User',
            defaultValues: {},
            params: { 'includes[]': ['launches', 'callcheckResults'] }
        };


        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/users', options);

        // em.addHook('AFTER_SINGLE_GET', (e) => {
        //     self.user = e;
        //     self.merge();
        // });
        this.em = em;
        this.em.setDetailId(this.id);
        this.em.inject();
        this.em.refresh();


    }

}

const component = {
    template: require('./usertechnicalview.component.html'),

    bindings: {
        id: '<'
    },
    controller: Controller
};


export default  component;
