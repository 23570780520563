// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

import Constants from '../../lib/constants'
import RestHelperDetail from "../../lib/api/resthelperdetail.class";


class Controller {
    constructor($scope, $state, $controller, AlertsService, settings, RestangularFull) {
        'ngInject';
        this.$scope = $scope
        this.Restangular = RestangularFull
        this.api = null //client
        this.commentTypes = Constants('commentTypes')
        this.history = [];
        this.user = null;
        this.$controller = $controller;
        this.AlertsService = AlertsService;
        $scope.settings = settings;
        const self = this;
        this.reset();
    }


    $onInit() {

        const self = this
        const url = 'users/' + this.id + '/comments/admin'

        self.api = this.Restangular.all(url)


        this.scope = this.scope || 'admin'


        let options = {
            name: 'User',
            defaultValues: {},
            params: {'includes[]': ['feedbacks', 'notifications', 'relatedTodos', 'smslogs']}
        };


        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/users', options);

        em.addHook('AFTER_SINGLE_GET', (e) => {
            self.user = e;
            self.merge();
        });
        this.em = em;
        this.em.setDetailId(this.id);
        this.em.inject();
        this.refresh()

    }

    reset() {
        this.$scope.newComment = {type: 'organisational', text: ''};
    }


    getAdditionalClass(row) {
        let now = new Date();
        if (row.type === 'comment' && row.data.pinToTop) {
            return 'pinned';
        }
        if (row.type === 'todo' &&
            row.date.getTime() < now.getTime() &&
            (row.data.state === 'OPEN' || row.data.state === 'WAITING')) {
            return 'warning';
        }
        return '';

    }

    addTodo(todo) {
        const self = this

        // api/v2/admin/coordinatortodos
        this.isLoading = true;
        this.Restangular.all('admin/coordinatortodos')
            .post({
                customerId: this.id,
                assigneeId: todo.assigneeId,
                duedate: todo.duedate,
                type: 'MANUAL',
                state: 'OPEN',
                description: todo.text
            })
            .then(() => {

                self.isLoading = false
                self.refresh();
                todo.duedate = null;
                todo.text = '';
                todo.assigneeId = null;
            })
        ;


    }

    addComment(comment) {
        this.isSubmitting = true
        this.api.post({
            'body': comment.text,
            'type': comment.type,
            'visible_to': this.scope
        })
            .then(() => this.isSubmitting = false)
            .then(() => this.reset())
            .then(() => {
                    this.refresh()
                    this.AlertsService.showConstant('success', 'Comment saved')
                }
            )


    }


    pinComment(comment, newValue) {

        comment.pinToTop = newValue;
        comment.put()
            .then(() => this.refresh());

    }

    typeToIcon(type) {

        switch (type) {
            case 'comment':
                return 'fas fa-comment';
            case 'todo':
                return 'fas fa-tasks';
            case 'feedback':
                return 'fas fa-star';
            case 'notification':
                return 'fas fa-envelope';

        }//

        return 'fas fa-question-circle'

    }

    refresh() {

        /*
         history contains:
            1) activties
            2) -- comments
            3) state changes
            4) -- notificatons
            5) -- todos
            6)  -- feedbacks
         */
        this.em.refresh();

        const self = this
        self.isLoading = true
        self.api.getList({'includes[]': 'author'})  // GET: /users
            .then(function (comments) {
                self.isLoading = false
                self.comments = comments.data;
                self.merge();
            })

    }


    merge() {
        let history = [];


        if (!this.user) {
            return;
        }


        _.each(this.comments, comment => history.push({
            date: comment.createdAt,
            type: 'comment',
            data: comment
        }));


        _.each(this.user.feedbacks, feedback => history.push({
            date: feedback.createdAt,
            type: 'feedback',
            data: feedback
        }));
        _.each(this.user.relatedTodos, todo => history.push({
            date: todo.duedate,
            type: 'todo',
            data: todo
        }));


        _.each(this.user.notifications, n => {
            let splitPath = n.type.split('\\');
            n.visibleType = splitPath[splitPath.length - 1];
        })
        _.each(this.user.notifications, n => history.push(
            {
                date: n.createdAt, type: 'notification', data: n
            }));

        _.each(history, h => h.date = new Date(h.date));

        function sorting(a, b) {
            a.data = a.data || {};
            b.data = b.data || {};
            a.data.pinToTop = !!a.data.pinToTop; // make sure false === undefined;
            b.data.pinToTop = !!b.data.pinToTop; // make sure false === undefined;
            if (a.data.pinToTop !== b.data.pinToTop) {
                return (a.data.pinToTop ? -1 : 1);
            }

            return b.date.getTime() - a.date.getTime();
        }

        history.sort(sorting);
        // _.each(history, (f) => console.log('..', f.data.pinToTop, f.date));
        // history = _.sortBy(history, 'date').reverse();

        this.history = history;


    }

}

const component = {
    template: require('./userhistory.component.html'),

    bindings: {
        entity: '<', //One-way (“<”) data bindings; Expressions (“&”) bindings // Evaluated DOM attribute (“@”) bindings
        id: '<',
        scope: '<'
    },
    controller: Controller
};


export default component;
