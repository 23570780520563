import _ from 'lodash'


// @todo: on logout or error flush cache

class DataStore {

    constructor($rootScope, $q, $log, RestangularFull) {
        'ngInject';
        this.$rootScope = $rootScope
        $rootScope.DataStore = this

        this.$q = $q
        this.$logger = $log.getInstance('Datahelper')
        this.Restangular = RestangularFull
        this.reset()
        $rootScope.$on('auth.authenticated', () => this.reset());
        $rootScope.$on('refresh:all', () => this.reset());

    }

    reset() {
        this.$logger.info('clear cache');
        this._cache = {}
        this._promises = {}
        this._indexedCached = {}
    }

    refreshEntity(entityName) {
        this.$logger.info('reloading cache for ' + entityName);
        delete this._cache[entityName];
        this.ensureEntity(entityName);
    }

    getCachedEntity() {
        alert('not implemneted')
    }

    async ensureEntity(entityName) {
        this.$logger.info('ensuring', entityName)
        if (this._cache[entityName]) {
            return this._cache[entityName]
        }
        if (!this._promises[entityName]) {
            this._promises[entityName] = this._fetch(entityName);
        }
        this.$logger.info('using promise', entityName,this._promises[entityName])
        return await this._promises[entityName];
    }
    // ensureEntity(entityName) {
    //     this.$logger.info('ensuring', entityName)
    //     if (this._cache[entityName]) {
    //         return this.$q.resolve(this._cache[entityName]);
    //     }
    //     if (!this._promises[entityName]) {
    //         this._promises[entityName] = this._fetch(entityName);
    //     }
    //     this.$logger.info('using promise', entityName)
    //     return this._promises[entityName];
    // }

    ensureEntities(names) {
        return this.$q.all(_.map(names, (n) => this.ensureEntity(n)));
    }

    get(entityName) {

        if (!this._cache[entityName]) {
            this.$logger.error(entityName + ' not loaded before requested, please put Dataheler.ensureEntity("' + entityName + '") in resolve of your route');
        }

        return this._cache[entityName];

    }

    getOne(entityName, id) {
        const all = this.get(entityName);
        const search = { id: id }; // if id === number;
        return _.find(all, search);

    }

    _fetch(entityName) {

        //@todo:
        this.$logger.info('Prefetching values for ' + entityName);
        const self = this
        let promise = this._restangularForEntity(entityName)


        return promise
            .then(function (results) {

                if (results.data) {
                    results = results.data;
                }
                if (results.length) {
                    results = _.map(results, _addDisplayName);
                    results = _.orderBy(results,'displayName');
                }
                self._cache[entityName] = results;
                self._promises[entityName] = null;
                return results;
            });

        function _addDisplayName(entity) {

            if (entity.displayName) return entity;
            entity.displayName = entity.name || entity.displayName || entity.title || entity.email;

            return entity;
        }

    }

    _restangularForEntity(entitiyName) {
        let endpoint = null
        let options = { limit: 1000 }


        switch (entitiyName) {
            case 'options':
                // let fn = 'get'
                endpoint = 'admin/' + entitiyName
                return this.Restangular.one(endpoint).get()

            case 'ownusers':

                //filter_groups: [{"filters":[{"key":"primaryRole","value":"mentor","operator":"eq"}]}]
                endpoint = 'admin/users'
                let currentUserOrganization = 1 //@todo get from current User service;
                options.filter_groups = JSON.stringify([{filters:[{
                    key: 'organization_id',
                    value: currentUserOrganization,
                    operator: 'eq'
                }]}]);
                options.sort =  JSON.stringify([{"key":"first_name","direction":"asc"}]);

                break;
            case 'groups':
                endpoint = 'admin/' + entitiyName + '?includes[]=organization'
                break;
            default:
                endpoint = 'admin/' + entitiyName
        }
        return this.Restangular
            .all(endpoint)
            .getList(options)

    }

}


export default  DataStore;
