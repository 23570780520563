// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };
import BaseBreadService from '../../services/BaseBreadService'


//https://docs.angularjs.org/guide/component life cycle hooks doc

class Controller {
    showDirectStateChanges = false;

    constructor($state, $confirm, $rootScope, $http, $controller) {
        'ngInject';
        this.$http = $http;
        this.$controller = $controller;
        this.$rootScope = $rootScope;
        this.$confirm = $confirm;

    }

    $onInit() {
        this.getStateMachine()
            .then(()=> this.getHistory())
        ;
    }

    refresh() {
        // emity
        this.$rootScope.$emit('refresh:model');

    }

    // $onDestroy = function () {
    //     $element.off('click', clickHandler);
    // };

    getHistory() {
        this.entityService = this.$controller(BaseBreadService);
        this.entityService.setEntity(this.pluralentity);
        this.entityService.get(this.model.id, { params: { 'includes[]': ['history', 'history.actor'] } })
            .then(res => this.historyModel = res);


    }

    getStateMachine() {

        const self = this;

        return this.$http.get('admin/statemachine/' + this.entity.toLowerCase())
            .then((res) => this.config = res.data)
            .then(() => this.postProcess())
            .then(() => this.renderGraph())

    }

    applyTransition(transition) {
        const textSureDelete = 'Are you sure? This could send out emails etc.?';
        return this.$confirm({ text: textSureDelete })
            .then(() => this.$http.post('admin/statemachine/' + this.entity.toLowerCase() + '/' + this.model.id + '/' + transition))
            .then(() => alert('applied'))
            .then(() => this.refresh())
    }

    setState(state) {
        const textSureDelete = 'This leads to not understandable history of our user dat. Please create an asana task in the bugs report describing why you had to do this an with this example linked!';
        return this.$confirm({ text: textSureDelete,  title: 'You are manually changing a state!' })
            .then(() => this.$http.post('admin/statemachine/manual/' + this.entity.toLowerCase() + '/' + this.model.id + '/' + state))
            .then(() => alert('applied'))
            .then(() => this.refresh())

    }

    postProcess() {
        const self = this
        this.config.allowedTransitions =
            _.pickBy(
                this.config.transitions,
                (value, key) => value.from.indexOf(this.model.state) !== -1
            )

    }

    renderGraph() {
        // @todo

    }
}

const component = {
    template: require('./statemachine.component.html'),
    bindings: {
        model: '=',
        entity: '<',
        pluralentity: '<'
    },
    controller: Controller
};


export default  component;
