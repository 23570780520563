import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $scope, $stateParams, $http,CourseService,DataStore, ProgramsService) {
        'ngInject';

        let options = {
            name: 'Course',
            defaultValues: {},
            params: { 'includes[]': ['sections','lecturers','programs'] }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/courses', options);


        em.setDetailId($stateParams.id);
        em.run()
        this.$http = $http;
        this.$scope = $scope;
        this.em = em;
        this.getLecturers();
        this.ProgramsService = ProgramsService;
        this.DataStore = DataStore;

        this.newCourseMeta = {required: false};

    }

    async attachProgram(courseId, programId) {
        if (!programId){
            alert('Please select a program Id first');
            return;
        }
        await this.ProgramsService.attachCourse(programId,  courseId, this.newCourseMeta);
        await this.em.refresh();
        this.newCourseMeta = {required: false, courseId: null};
    }
    async dettachProgram(courseId, programId){
        await this.ProgramsService.dettachCourse(programId, courseId);
        await this.em.refresh();
    }

    getLecturers() {

        this.lecturers = [];

        this.$http.get('admin/lecturers')
            .then((result) => this.lecturers = result.data.data);

    }

    removeLecturer(lec) {

        this.$http.delete('admin/courses/' + this.$scope.model.id + '/lecturers/' + lec.id)
            .then(() => this.em.refresh())
    }

    addLecturer(newlecturer) {
        this.$http.post('admin/courses/' + this.$scope.model.id + '/lecturers/' + newlecturer.id)
            .then(() => this.em.refresh())
    }

}

const component = {
    template: require('./course.component.html'),
    controller: Controller
};
export default  component;
