

export default function ($parse) {
    'ngInject';
    return {
        replace: true,
        controller: function ($scope) {
            'ngInject';

            $scope.reload = function () {
                $scope.refreshClicked = true;
                setTimeout(() => {
                    $scope.refreshClicked = false;
                }, 1000);

            }
        },
        template: `<div>
                      <span class="muted" am-time-ago="lastReload"></span>
                     <button class="btn btn-defaut btn-sm" ng-class="{'btn-info': refreshClicked}" ng-click="reload(); $ctrl.em.refresh()"><i class="fas fa-sync-alt"></i></button>
                   </div>
            `,
        restrict: 'E'
    }
}

