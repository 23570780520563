import angular from 'angular';

const MODULE_NAME = 'shared';


import comments from './comments.component'
import spinner from './spinner.component'
import stateMachineVisualized from './statemachine/statemachine.component'
import stateMachineService from './statemachine/statemachine.service'

import errorBox from './errorBox.component'
import crudtoolbar from './crudtoolbar.directive'
import entityfunnel from './entityfunnel.component'
import participationfunnel from './participationfunnel.component'
import searchfield from './searchfield.directive'
import paginate from './paginate.directive'
import dataTable from './table/dataTable.directive'
import daysSince from './dayssince.component'
import videoPreview from './videoPreview.component'
import reloadbutton from './reloadbutton.directive'
import showTechnicalDetails from './technical.details.directive'
import showArticle from './showArticle.component'
import languageselector from './languageselector.component'

import helptooltip from './helptooltip.component'
import multilanguagejson from './multilanguagejson.component'


// todo: integrated it from CDN...
// import '@fortawesome/fontawesome'
// import '@fortawesome/fontawesome/styles.css'
// import '@fontawesome/styles.css'


angular.module(MODULE_NAME, [])
    .component('comments', comments)
    .component('spinner', spinner)
    .component('daysSince', daysSince)
    .component('errorbox', errorBox)
    .component('entityfunnel', entityfunnel)
    .component('showArticle', showArticle)
    .component('videoPreview', videoPreview)
    .component('multilanguagejson', multilanguagejson)
    .component('participationfunnel', participationfunnel)
    .component('helptooltip', helptooltip)
    .directive('reloadbutton', reloadbutton)
    .component('languageselector', languageselector)
    .component('dateselector', require('./dateselector.component').default)
    .component('statemachineGraph', require('./statemachine/statemaching.graph.component').default)
    .component('stateMachineVisualized', stateMachineVisualized)
    .directive('crudtoolbar', crudtoolbar)
    .service('StateMachineService', stateMachineService)

    .directive('searchfield', searchfield)
    .directive('pagination', paginate)
    .directive('datatable', dataTable)
    .component('showTechnicalDetails', showTechnicalDetails)


export default MODULE_NAME;
