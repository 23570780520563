import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'

// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };
class Controller {
    constructor($rootScope, UserOnlineService) {
        'ngInject';
        this.isOnline = false
        this.$rootScope = $rootScope;
        this.UserOnlineService = UserOnlineService
    }

    $onInit() {
        const self = this

        function refresh() {
            if (self.user) {
                self.isOnline = self.UserOnlineService.onlineUsersId.indexOf(self.user.id) !== -1
                self.isInConference = self.UserOnlineService.conferenceOnlineIds.indexOf(self.user.id) !== -1
            }
        }

        this.listener = this.$rootScope.$on('onlineusers.update', () => refresh())
        refresh()

    }
    $onDestroy() {
        this.listener()
    }
}

// <b>{{$ctrl.user.displayName}}<i class="fa fa-star" class="text-success" ng-if="$ctrl.user.servicelevel > 3.indexOf('PLUS') !== -1"></i></b>
let template = `
    <span ui-sref="admin.users.detail({id:$ctrl.user.id})" class="username" ng-if="$ctrl.user"
        ng-class="{'muted-name': $ctrl.user.countForReporting === false}"
    >
        <span ng-if="$ctrl.colorBadge"  style="color:{{$ctrl.user.colorCode}}" > &#9679;  </span>
        {{$ctrl.user.firstName || $ctrl.user.first_name || $ctrl.user.email}} {{$ctrl.user.lastName || $ctrl.user.last_name}}
          
        <span ng-show="$ctrl.isOnline" class="label label-green">ON</span>
        <span ng-show="$ctrl.isInConference" uib-tooltip="Online in the virtual class room" class="label"><i  class="fa fa-microphone" ></i></span>
        
        <i class="fa fa-star" 
            ng-class="{'text-danger':$ctrl.user.servicelevel =='priority'}" 
            ng-if="$ctrl.user.servicelevel == 'priority' || $ctrl.user.servicelevel == 'premium'"
            ></i>
            
           
    </span>
    
    
`;

const component = {
    template: template,
    controller: Controller,
    replace: true,
    bindings: {
        user: '<',
        colorBadge: '<'
    },

};


export default  component;
