import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'
import moment from "moment-timezone";

class Controller {
    constructor($scope, $timeout, $http, RestangularFull) {
        'ngInject';
        this.timezones = moment.tz.names();
        this.currentTimezone = moment.tz.guess();
        this.restangular = RestangularFull;
        this.daysInWeek = ['Sunday,', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday', 'Sunday'];

        this.hours = [8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5];
        this.days = [1, 2, 3, 4, 5, 6, 0]; //mo - fr 0 == sonntag
        this.entries = false;
        this.$http = $http;


        // @todo how to acces availbilty? via user

        $timeout(() => {
            this.refresh();
        })


    }

    formatTime(minuteOfDay) {
        let hours = parseInt(minuteOfDay / 60);
        let minutes = minuteOfDay % 60;
        minutes = minutes === 0 ? '00' : minutes;

        return `${hours}:${minutes}`;
    }


    refresh() {
        const self = this
        self.isLoading = true
        this.restangular.all(this.endpoint)
            .get('?timezone=' + this.currentTimezone)
            .then((res) => {
                self.isLoading = false
                self.entries = res.data;
            })
    }

    save() {
        var obj = { data: this.entries, timezone: this.currentTimezone };
        this.$http.put(this.endpoint, obj)
    }

    timeFormat(time) {
        let slotTime = 60; // minutes
        let starthour = parseInt(time);
        let startMinutes = (time % 1) * 60;
        let endMinutes = (startMinutes + slotTime) % 60;
        let endHour = (startMinutes >= endMinutes ? starthour + 1 : starthour);

        return this.stringPad(starthour, 2) + ':' + this.stringPad(startMinutes, 2) + ' - ' + this.stringPad(endHour, 2) + ':' + this.stringPad(endMinutes, 2);
    }

    getSelector(day, hour) {
        return {
            dayOfWeek: day,
            startMinuteOfDay: hour * 60
        }
    }

    stringPad(n, width, z) {

        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;

    }

    select(day, hour) {

        let selector = this.getSelector(day, hour)
        let entry = this.findEntry(day, hour)

        if (entry === undefined) {
            selector.durationMinutes = 60;
            this.entries.push(selector);
        } else {
            this.entries = _.reject(this.entries, selector);
        }
        this.save();


    };

    isSelected(day, hour) {

        return (this.findEntry(day, hour) !== undefined);
    }

    findEntry(day, hour) {
        return _.find(this.entries, this.getSelector(day, hour));

    }


    /*[
                    'dayOfWeek' => 1,
                    'startMinuteOfDay' => $minuteOfDay,
                    'durationMinutes' => 60,
                    'timezone' => $tz
                ]
                */

}

const component = {
    template: require('./availbilityEditor.component.html'),
    controller: Controller,
    bindings: {
        endpoint: '='
    }
}

export default  component;


/**
 .directive('betterScheduleIntl', ['$parse', function ($parse) {
    return {
      templateUrl: 'app/conference/schedule/betterScheduleIntl.html',
      scope: {
        entries: '=',
        onChoose: '=',
        mode: '=',
        update: '='
      },
      restrict: 'E',
      controller: function ($scope, $log, $translate, $confirm, AlertsService) {




        let updateCallback = $scope.update || function () {
            $log.error('[schedule] please overwrite update method')
          };

        function timeToFloat(time) {
          let hhmm  = this.time.split(':');
          return parseInt(hhmm[0]) + parseInt(hhmm[1])/60;
        }
        function floatToTime(time){
            return parseInt(time) + ':' + (time % 1 * 60);
        }




        $scope.timeFormat = function(time){
          let slotTime = 60; // minutes
          let starthour = parseInt(time);
          let startMinutes = (time % 1) * 60;
          let endMinutes = (startMinutes + slotTime ) % 60;
          let endHour = (startMinutes >= endMinutes ? starthour + 1 : starthour);
          return this.stringPad(starthour,2) + ':' + this.stringPad(startMinutes,2) + ' - ' + this.stringPad(endHour,2) + ':' + this.stringPad(endMinutes,2) ;
        };




        let onChoose = $scope.onChoose || function () {
            $log.error('[better schedule] overwrite onChoose attribute')
          };

        let mode = $scope.mode || 'registration';


        $scope.isSelected = isSelected;
        $scope.select = select;

        function

        function };

        function

      }
    }
 }]);
 **/
