import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'
import CourseService from "../courses/course.service";

class Controller {
    constructor($controller, $scope, $stateParams, CourseService) {
        'ngInject';
        this.$scope = $scope;
        let options = {
            name: 'Section',
            defaultValues: {},
            params: { 'includes[]': ['videos', 'exercises', 'quiz', 'courses'] }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/sections', options);
        em.setDetailId($stateParams.id);
        em.run()

        this.CourseService = CourseService;
        this.em = em;

    }

    addVideo() {
        const sectionId = this.$scope.model.id;
        this.CourseService.createVideo({ sectionId })
            .then(() => this.em.refresh());
    }
    removeQuiz(quiz) {
        const quizId = quiz.id
        this.CourseService.removeQuiz({quizId})
            .then(() => this.em.refresh());
    }
}

const component = {
    template: require('./section.component.html'),
    controller: Controller
};
export default  component;
