// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

class Controller {
    constructor($rootScope, $scope, $state, $http, CurrentUserService) {
        'ngInject';
        const self = this;
        this.showDashboard = CurrentUserService.hasOneRole(['ADMIN', 'COORDINATOR']);

        // @todo: remove on destry;
        setTimeout(() => {
            self.showDashboard = CurrentUserService.hasOneRole(['ADMIN', 'COORDINATOR'])
            $scope.$apply();
        }, 2500);
        setTimeout(() => {
            self.showDashboard = CurrentUserService.hasOneRole(['ADMIN', 'COORDINATOR'])
            $scope.$apply();
        }, 1000);
        this.currentUser = CurrentUserService;
        this.$state = $state;
        this.$http = $http;

    }

    userSelected(user) {
        this.$state.go('admin.users.detail', { id: user.id });
    }


}

const component = {
    template: require('./dashboard.component.html'),
    controller: Controller

};


export default  component;
