import RestHelperList from "../../lib/api/resthelperlist.class";

class Controller {
    constructor($controller, $scope, $http, UserService) {
        'ngInject';
        this.$scope = $scope;
        this.$http = $http;
        this.kpis = null;

    }

    $onInit() {

        this.refresh();
    }


    refresh() {
        this.$http.get('admin/organizations/' + this.organizationid + '/kpis')
            .then((res) => this.kpis = res.data)


    }
}

// <b>{{$ctrl.user.displayName}}<i class="fa fa-star" class="text-success" ng-if="$ctrl.user.servicelevel > 3.indexOf('PLUS') !== -1"></i></b>
let template = `
            <div>

                     <div  ng-if="$ctrl.kpis">
                    <div class="row">
                        <div class="col-md-4 kpi text-center">
                            <h3>{{$ctrl.kpis.userCount || 0}}</h3>
                            <b>Registered Users</b>
                        </div>

                        <div class="col-md-4  kpi text-center">
                            <h3>{{$ctrl.kpis.licencesUsed || 0}}</h3>
                            <b>Licences Used</b>
                        </div>
                        <div class="col-md-4 kpi text-center">
                            <h3>{{$ctrl.kpis.matchablePaticipations || 0}}</h3>
                            <b>Matchable Participations</b>
                        </div>
                        <div class="col-md-4 kpi text-center">
                            <h3>{{$ctrl.kpis.activeMentorships || 0}}</h3>
                            <b>Active Mentorships</b>
                        </div>


                        <div class="col-md-4  kpi text-center">
                            <h3>{{$ctrl.kpis.doneMentorships || 0}}</h3>
                            <b>Done Mentorships</b>
                        </div>

                    </div>
                    <div class="row">
                    <div class="col-md-12">
                    <b> Happiness last 3 month average</b>
                    <table class="table table-condensed table-slim table--layout-auto">
                    <tr ng-repeat="row in $ctrl.kpis.happiness.last3Month">
                        <td>{{row.question_code}}</td>
                        <td class="text-right muted">#{{row.count}}</td>
                        <td class="text-right">{{row.rating | number:2}}</td>
                    </tr>
                    </table>
                    </div>
                    
                    </div>
                </div>
                
                <div  ng-if="$ctrl.showlicences">
                <b> Licences </b>

                <table class="table table-slim">
                    <tr>
                        <th>Program</th>
                        <th class=" muted text-right">Activated</th>
                        <th class=" muted text-right">Rescindeded</th>
                        <th>Used</th>
                    </tr>
                    <tr ng-repeat="(key, value) in $ctrl.kpis.licencesPerProgram">
                        <td>{{key}}</td>
                        <td class="text-right muted">{{value.total || 0}}</td>
                        <td class="text-right muted">

                            {{value.total - value.used}}
                        </td>
                        <td class="text-right">{{value.used || 0}}</td>

                    </tr>

                </table>
                </div>
                

               </div>

`;

const component = {
    template: template,
    controller: Controller,
    bindings: {
        organizationid: '<',
        roles: '<',
        showlicences: '<'
    },


};


export default  component;
