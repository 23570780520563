import angular from 'angular';

const MODULE_NAME = 'admin';


import dashboard from './dashboard/dashboard.component'
import dashboardKpis from './dashboard/dashboardkpis.component'
import resetPassword from './dashboard/passreset.component'

import layout from './layout'
import users from './users'
import translations from './translations'
import webinars from './webinars'
import organizations from './organizations'
import programs from './programs'
import brandeddocuments from './brandeddocuments'
import registrationcodes from './registrationcodes'
import stories from './stories'
import feedbacks from './userfeedbacks'
import participations from './participations'
import enrollments from './enrollments'
import technical from './technical'
import files from './files'
import todos from './todos'
import brands from './brands'
import tenants from './tenants'
import support from './support'
import demo from './demo'
import bookings from './bookings'
import messaging from './messaging'
import myprofile from './profile'
import lecturer from './lecturer'
import courses from './courses'
import quizzes from './quizzes'
import videoquestions from './videoQuestions'
import searchComponent from './dashboard/search.component';

import mentorships from './mentorships'
import appointments from './appointments'
import operations from './operations'
import notifications from './notifications'
import reports from './reports'
import sections from './sections'


import supportanswers from './supportanswers'
import leads from './leads'
import plans from './plans'

angular.module(MODULE_NAME, [myprofile, stories, sections, courses, quizzes, videoquestions, lecturer, supportanswers, messaging, todos, support, reports, tenants, brands, notifications, layout, files, technical,
    appointments, participations, demo, mentorships, enrollments, feedbacks, users, programs, registrationcodes,
    translations, organizations, brandeddocuments, webinars, leads, operations, plans,bookings])
    .component('dashboard', dashboard)
    .component('dashboardKpis', dashboardKpis)
    .component('resetPassword', resetPassword)
    .component('searchComponent', searchComponent)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
        .state('admin', {
            abstract: true,
            url: '/admin',
            template: '<adminlayout></adminlayout>',
            resolve: {
                auth: ($state, AuthenticationService) => AuthenticationService.requireAuth(),
                datastore: (DataStore) => DataStore.ensureEntities(['options'])
            }
        })
        .state('admin.dashboard', {
            url: '/dashboard',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('admin.dashboard.search', {
            url: '/search/:search',

            component: 'searchComponent',
        })
        .state('admin.dashboard.index', {
            url: '/home',
            component: 'dashboard',
        })
        .state('admin.dashboard.reset', {
            url: '/reset-password',
            component: 'resetPassword',
        })
}


export default MODULE_NAME;
