// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

class Controller {
    constructor() {
        this.langs = ['de', 'en', 'es'];

    }
}


const component = {
    template: `
    <div  >
             <label class="btn btn-info btn-xs"
               ng-repeat="c in $ctrl.langs"
               ng-model="$ctrl.lang"
               uib-btn-radio="c"
               >{{c}}</label>
                                           
    </div>
`,
    bindings: {
        lang: '='
    },
    controller: Controller

};


export default  component;
