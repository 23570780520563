import angular from 'angular';

const MODULE_NAME  = 'layout';

import sidebar from './sidebar.component'
import navbar from './navbar.component'
import adminlayout from './adminlayout.component'

angular.module(MODULE_NAME,[])
    .component('sidebar', sidebar)
    .component('navbar', navbar)
    .component('adminlayout', adminlayout)

export default MODULE_NAME;
