import angular from 'angular';

const MODULE_NAME = 'admin-registrationcode';

import registrationcodes from './registrationcodes.component'
import registrationcode from './registrationcode.component'
import registrationCodeService from './registrationcode.service'


angular.module(MODULE_NAME, [])
    .component('adminregistrationcodes', registrationcodes )
    .component('adminregistrationcode', registrationcode)
    .service('registrationCodeService', registrationCodeService)
    .config(config)


function config($stateProvider) {
    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.registrationcodes', {
            abstract: true,
            url: '/registrationcodes',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations','brands','programs','options','groups'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.registrationcodes.index', {
            url: '/list',
            component: 'adminregistrationcodes'
        })
        .state('admin.registrationcodes.detail', {
            url: '/:id',
            component: 'adminregistrationcode'
        })
}


export default MODULE_NAME;
