import angular from 'angular';

const MODULE_NAME = 'admin-notification';

import notifications from './notifications.component'
import notification from './notification.component'
import notificationlist from './notificationlist.component'

angular.module(MODULE_NAME, [])
    .component('adminnotifications', notifications )
    .component('adminnotification', notification)
    .component('notificationlist', notificationlist)
    .config(config)


function config($stateProvider) {
    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.notifications', {
            abstract: true,
            url: '/notifications',
            template: '<ui-view></ui-view>',
            resolve: {
                // datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
            }

        })
        .state('admin.notifications.index', {
            url: '/list',
            component: 'adminnotifications'
        })
        .state('admin.notifications.detail', {
            url: '/:id',
            component: 'adminnotification'
        })
}


export default MODULE_NAME;