import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'

class Controller {
    constructor() {

    }
}
const template = `
    <span style="color: {{$ctrl.program.colorCode}}"> {{$ctrl.program.title}} </span>
`;
const component = {
    template: template,
    controller: Controller,
    bindings: {
        program: '<'
    },

};


export default  component;
