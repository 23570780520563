// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

class Controller {
    constructor($rootScope, $scope, $state, $http, CurrentUserService) {
        'ngInject';

        const self = this;

        this.searchValue = $state.params.search;

        this.$state = $state;
        this.$http = $http;
        this.search(this.searchValue);
        this.selected = 0;
        this.isLoading = false;
    }

    open(result, type) {
        // future cehck if it's user or company
        switch (type){
            case 'user':
                this.$state.go('admin.users.detail', { id: result.id });
                break;
            case 'organization':
                this.$state.go('admin.organizations.detail', { id: result.id });
        }

    }

    search(value) {

        this.userResults = [];
        this.orgResults = [];

        this.isLoading = true;
        let promises = [];
        let promise;

        promise = this.$http({
            url: 'admin/users',
            method: "GET",
            params: { search: value }
        })
            .then((res) => this.userResults = res.data.data)
        ;

        ;
        promises.push(promise);

        promise = this.$http({
            url: 'admin/organizations',
            method: "GET",
            params: { search: value }
        })
            .then((res) => this.orgResults = res.data.data)
        ;

        promises.push(promise);

        // Promise.all(promises)
        //     .then(() => this.isLoading = false)
        ;
        this.isLoading = false;


    }


}

var regInteger = /^-?\d+$/;

function isInteger(str) {
    return regInteger.test(str);
}


const component = {
    template: require('./search.component.html'),
    controller: Controller

};


export default  component;
