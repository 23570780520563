import Constants from '../../lib/constants'

class Controller {
    constructor($scope, $rootScope, $http, DataStore, StateMachineService) {
        'ngInject';
        //@todo: i think its better torequest the gorup memberships only if needed;
        this.$http = $http;
        $scope.now = new Date();

        $scope.DataStore = DataStore
        $scope.Constants = Constants
        this.daysWaitingCount = 0;
        this.StateMachineService = StateMachineService

    }
    unixDate (date){
        return new Date(date).getTime();
    }
    dayDifference(dateRecent, dateOlder){
        let differenceInMillisecond = this.unixDate(dateRecent) - this.unixDate(dateOlder)
        this.daysWaitingCount = Math.round((differenceInMillisecond)/(24*3600*1000))
        return  this.daysWaitingCount
    }
    participate(enrollment) {
        this.StateMachineService.transition('enrollment',enrollment.id,'participate')
            .then(() => alert('I added a new participation, the user is now available as matchable (under participations)!'))
            .then(() => this.updateCallback())
    }
    updateCallback() {
        if (this.callback && typeof(this.callback) === 'function') {
            this.callback();
        }
    }
    isFuture(date){
        if (!date ){
            return false;
        }
        const now = new Date();
        const then = new Date(date);
        return now.getTime() < then.getTime();

    }
    getOpponent(mentorship){
        // shownUserId
        const self = this

        return mentorship.mentorParticipation.user.id === self.shownUserId
            ? mentorship.menteeParticipation
            : mentorship.mentorParticipation;

    }

    $onInit() {
        if (this.enrollments && this.enrollments[0]) {
            this.enrollments[0].isOpen = true
        }

    }


}

const component = {
    template: require('./user.enrollments.component.html'),
    controller: Controller,
    bindings: {
        enrollments: '<',
        shownUserId: '<',
        callback: '&'
    },
}

export default  component;

