import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'
import RestHelperList from "../../lib/api/resthelperlist.class";


class Controller {
    constructor($controller, $scope) {
        'ngInject';
        let options = {
                name: 'User',
                defaultValues: {},
                params: {
                    limit: 15,
                    usr_state: 'any', 'includes[]': ['organization'],
                    // @todo: sort
                },
                sort: { 'createdAt': 'desc' },
            }
        ;


        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/users', options);
        this.em = em;
        em.resetStoredParameter();

        em.run();

    }

    refresh() {
        this.em.refresh();

    }
}

// <b>{{$ctrl.user.displayName}}<i class="fa fa-star" class="text-success" ng-if="$ctrl.user.servicelevel > 3.indexOf('PLUS') !== -1"></i></b>
let template = `
<div class="card card-default">
                    <div class="card-header">
                    <div class="pull-right">
                        
                        <reloadbutton refresh="$ctrl.refresh"></reloadbutton>
                    </div>
                    
                        <div class="card-title">
                            Latest Registrations
                        </div>
                    </div>
                    <div class="panel-body">
                 
        <div id="latestRegistrations">
    
    
        <div ng-show="isLoading" class="text-center loadingIndicator">
            <spinner></spinner>
        </div>
        
        <table class="table-clickable table table-slim">
            <tr>
                
                <th>Name</th> 
                <th>Organization</th>
                <th>Date</th>
            </tr>
            <tr ng-if="rows" ng-repeat="user in rows">
                <td><useravatar user="user"></useravatar></td>
                <td>{{user.organization.name}}</td>
                <td>{{user.createdAt | date}}</td>
           </tr>
       </table>
    
       </div> 
      </div>
  </div>


`;

const component = {
    template: template,
    controller: Controller

};


export default  component;
