import Constants from '../lib/constants'


class Controller {


    constructor($http) {
        'ngInject';
        this.$http = $http
        this.article = {};
    }


    $onInit() {


        this.$http.get('articles/' + this.code)
            .then((result) => this.article = result.data.data);

    }


}

const component = {
    template: `
    <div>
        <div ng-bind-html="$ctrl.article.content | html"></div>
    </div>
`,

    bindings: {
        code: '<'
    },
    controller: Controller
};


export default  component;
