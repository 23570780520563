import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'
let localEm;

class Controller {
    constructor($controller, $scope, $stateParams) {
        'ngInject';
        let options = {
            name: 'Participation',
            defaultValues: {},
            params: { 'includes[]': ['enrollment', 'mentorMentorship', 'menteeMentorship'] },
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/participations', options);
        this.$scope = $scope;
        this.$scope.matchDateAfter = new Date()

        localEm = em;

        em.setDetailId($stateParams.id);
        em.run()
    }
    setMatchingDate() {

        this.$scope.model.startMatchingAfter = (new Date());
    }

    autoDateSave() {
        setTimeout(() => {
            this.$scope.model.startMatchingAfter = this.$scope.model.startMatchingAfter.toISOString().slice(0,-5).replace('T', ' ')
            localEm.save()
        }, 50);
    }
    isPast(date){
        if (!date){
            return false;
        }
        const then = new Date(date);
        const now = new Date();
        return now.getTime() > then.getTime();

    }
}

const component = {
    template: require('./participation.component.html'),
    controller: Controller
};
export default  component;
