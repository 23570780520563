import Constants from '../../lib/constants'
import RestHelperDetail from "../../lib/api/resthelperdetail.class";
import moment from "moment-timezone";

class Controller {
    constructor($scope, $http,$controller, DataStore) {
        'ngInject';
        //@todo: i think its better torequest the gorup memberships only if needed;
        this.$http = $http;

        $scope.DataStore = DataStore
        $scope.Constants = Constants
        this.$scope = $scope
        this.em  = null
        this.$controller = $controller


    }

    $onInit() {

        let options = {
            name: 'User',
            defaultValues: {},
            params: {'includes[]': ['profileFields']}
        };

        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/users', options);
        em.setDetailId(this.userid);
        em.run()
        this.em = em

        if (this.defaultType){
            this.$scope.groupType = this.defaultType;

        }
    }

}

const component = {
    template: require('./user.profilefilelds.component.html'),
    controller: Controller,
    bindings: {
        userid: '<',
        defaultType: '<'
    }
}

export default  component;
