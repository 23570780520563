import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'
import RestHelperList from "../../lib/api/resthelperlist.class";


class Controller {
    constructor($controller, $scope, $timeout, $rootScope, UserOnlineService) {
        'ngInject';
        const self = this

        function refresh() {
            self.onlineUsers = UserOnlineService.onlineUsers
            $timeout(function () {
                $scope.$apply();
            })
        }
        this.listener = $rootScope.$on('onlineusers.update', () => {
            refresh()
        });
        refresh()

    }

    $onDestroy() {
        this.listener()
    }

}

// <b>{{$ctrl.user.displayName}}<i class="fa fa-star" class="text-success" ng-if="$ctrl.user.servicelevel > 3.indexOf('PLUS') !== -1"></i></b>
let template = `
    <div id="onlineUsers">
  
    <table class="table-clickable table table-slim">
            <tr>
                <th>Role</th>
                <th>Name</th> 
                <th>Organization</th>
            </tr>
            <tr ng-if="$ctrl.onlineUsers && $ctrl.onlineUsers.length ==0 "><td>No users online</td></tr>
            <tr ng-if="$ctrl.onlineUsers" ng-repeat="user in $ctrl.onlineUsers track by $index">
                <td><userrole role="user.primaryRole"></userrole></td>
                <td><username user="user"></username></td>
                <td>{{user.organization.name}}</td>
           </tr>
       </table>
    </div>
    
    
`;

const component = {
    template: template,
    controller: Controller

};


export default  component;
