import angular from 'angular';

const MODULE_NAME = 'admin-appointment';

import appointments from './appointments.component'
import upcomingAppointments from './appointmentsupcoming.component'
import appointmentFunnel from './appointments.funnel.component'
import appointment from './appointment.component'

angular.module(MODULE_NAME, [])
    .component('adminappointments', appointments )
    .component('adminappointment', appointment)
    .component('appointmentsFunnel', appointmentFunnel)
    .component('upcomingAppointments', upcomingAppointments)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
    //@todo there has to be a smarter way
        .state('admin.appointments', {
            abstract: true,
            url: '/appointments',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.appointments.index', {
            url: '/list?filter',
            component: 'adminappointments'
        })
        .state('admin.appointments.detail', {
            url: '/:id',
            component: 'adminappointment'
        })
}


export default MODULE_NAME;