import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'

class Controller {

}


let template = `
<div>

{{status}}
    <span ng-if="status === 3" class="badge badge-success"><i class="fa fa-check"></i><i
                                class="fa fa-check" uib-tooltip="user received and read message."></i> read</span>
    <span ng-if="status === 2" class="badge badge-default"
          uib-tooltip="delivered, but not know if it was read already."
    ><i class="fa fa-check"></i><i
            class="fa fa-check"></i> delivered</span>
    <span ng-if="status === 1" class="badge badge-warning">waiting for device to come online</span>
    <span ng-if="status === 0" class="badge badge-danger"
          uib-tooltip="our phone is not connected or number does not exist.">waiting for delivery</span>
</div>
`;

const component = {
    template: template,
    controller: Controller,
    bindings: {
        status: '<'
    },

};


export default  component;
