import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $http, $q, $uibModal, $controller) {
        'ngInject';

        this.$http = $http;
        this.$q = $q
        this.$uibModal = $uibModal
        this.$scope = $scope


        let options = {
                name: 'Participation',
                defaultValues: {},
                params: {
                    usr_state: 'any',
                    search: '',
                    'includes[]': ['enrollment', 'enrollment.user', 'enrollment.program', 'mentorMentorship', 'menteeMentorship'],
                }
            }
        ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/participations', options);
        em.run();
        this.em = em;


    }


    showMatchableUser() {
        this.$scope.filter.matchable = 'true';
        this.em.refresh();
    }

    isAvailableForMatch(participation) {
        if (participation.mentorship_id)
            return false;

        if (participation.enrollment.state !== 'ACTIVE')
            return false;

        // @todo: check here if participation.startMatchingAfter > now

        return true;
    }

    findMentorship(participation) {
        const self = this

        this.$uibModal.open({
            component: 'findMentorshipComponent',
            size: "xlg",
            resolve: {
                participation: () => participation
            }
        }).result.then(function (result) {

            self.refresh()

        }, function (reason) {
            // dissmissed
        });
    }


}

const component = {
    template: require('./participations.component.html'),
    controller: Controller,
}

export default component;
