import './fileupload.css'


class Controller {
    constructor($log, $rootScope, settings, Upload, AlertsService) {
        'ngInject';
        this.Upload = Upload;
        this.AlertsService = AlertsService;
        this.$log = $log
        this.apiurl = settings.apiFullUrl;
        this.state = 'WAITING';
        this.progress = 0;
        this.$rootScope = $rootScope;

    }

    upload(file) {
        const self = this;

        self.progress = 0;
        self.state = 'UPLOADING';

        // url e.g. 'programs/' + program.id + '/cover'

        let uploadUrl = this.apiurl + this.url
        // this.$log('uploading to ', uploadUrl)

        return this.Upload.http({
            url: uploadUrl,
            headers: {
                'Content-Type': file.type,
                'Filename': file.name
            },
            data: file
        }).then(function (resp) {
            self.state = 'SUCCESS';
            //@todo emit parent event;


            if (self.onSuccess) {
                self.onSuccess(resp);
            }
            self.$rootScope.$on('refresh:model');


        }, function (resp) {
            self.state = 'ERROR';
            console.error('Error status: ' + resp.status);
            if (self.onSuccess) {
                self.onSuccess();
            }
        }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        });


    }

};


const component = {
    template: require('./fileupload.component.html'),

    bindings: {
        url: '<',
        type: '<',
        onSuccess: '=',
        isDisabled : '<'
    },
    controller: Controller
};


export default  component;
