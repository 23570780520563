import angular from 'angular';

const MODULE_NAME = 'admin-plans';

import plans from './plans.component'

angular.module(MODULE_NAME, [])
    .component('adminPlans', plans )
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
        .state('admin.plans', {
            abstract: true,
            url: '/plans',
            template: '<ui-view></ui-view>',

        })
        .state('admin.plans.index', {
            url: '/list',
            component: 'adminPlans'
        })

}


export default MODULE_NAME;
