import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $http) {
        'ngInject';


        this.issues = [];
        this.$http = $http;


        let options = {
                name: 'Webinar',
                defaultValues: {},
                params: {
                    'includes[]': ['program', 'referent'],
                    'withCount[]': ['enrollments'],

                },
                sort: { 'starts_at': 'desc' },

                // @todo sort by


                // params: {
                //     usr_state: 'any', search: '',
                //     'includes[]': ['program','enrollments'], //speaker
                // }
            }
        ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/webinars', options);
        em.run();
        this.findIssues();

    }

    findIssues() {
        this.$http.get('/admin/webinars/issues')
            .then((result)=>{
                this.issues = result.data.warnings;

            })


    }

    isPast(webinar) {
        if (!webinar.startsAt) {
            return false
        }
        if (new Date(webinar.startsAt).getTime() >= new Date().getTime()) {
            return false
        }
        return true
    }
}

const component = {
    template: require('./webinars.component.html'),
    controller: Controller,
}

export default  component;
