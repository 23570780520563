import Constants from '../../lib/constants'
import RestHelperDetail from "../../lib/api/resthelperdetail.class";
import moment from "moment-timezone";

class Controller {
    constructor($scope, $http,$controller, DataStore, settings) {
        'ngInject';
        //@todo: i think its better torequest the gorup memberships only if needed;
        this.$http = $http;

        $scope.DataStore = DataStore
        $scope.Constants = Constants
        this.settings = settings
        this.$scope = $scope
        this.em  = null
        this.$controller = $controller
        this.organizationGroups = null;
    }

    async $onInit() {
        let options = {
            name: 'User',
            defaultValues: {},
            params: {'includes[]': ['groups']}
        };

        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/users', options);
        em.setDetailId(this.userid);
        em.run()
        this.em = em

        if (this.defaultType){
            this.$scope.groupType = this.defaultType;

        }

        this.organizationGroups = await this.fetchGroupsForOrganization(this.organizationid);
    }

    async fetchGroupsForOrganization(organizationId) {
        let url = `admin/organizations/${organizationId}/groups`
        const response = await this.$http.get(url);
        return response.data.data;
    }

    addGroup(groupId, role) {

        //@todo
        let url = 'admin/users/' + this.userid + '/groups/' + groupId

        this.$http.post(url, {role: role})
            .then(() => this.updateCallback())
        // @todo: refresh parent
    }

    removeGroup(groupId, groupRole) {
        let url = 'admin/users/' + this.userid + '/groups/' + groupId
        this.$http.delete(url, {
            params: {
                role: groupRole
            }
        })
            .then(() => this.updateCallback())
        // @todo: refresh parent

    }

    updateCallback() {
        this.em.refresh()
    }
}

const component = {
    template: require('./user.groupeditor.component.html'),
    controller: Controller,
    bindings: {
        organizationid: '<',
        userid: '<',
        defaultType: '<'
    }
}

export default  component;
