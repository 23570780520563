export default function (RestangularProvider) {
    'ngInject';

    RestangularProvider.addResponseInterceptor(function (data, operation, what, url, response, deferred) {


        let extractedData = data.data ? data.data : data;

        if (operation === "getList" && data.meta) {
            extractedData.meta = data.meta;
        }

        return extractedData;
    });

}