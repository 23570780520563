import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}

function vimeo_parser(url) {
    // https://vimeo.com/279525653

    // Look for a string with 'vimeo', then whatever, then a
    // forward slash and a group of digits.

    const matchPrivate = /vimeo.*\/(\d+)\/.*/i.exec(url);
    if (matchPrivate) {
        return matchPrivate[1];
    }

    // If the match isn't null (i.e. it matched)
    const match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
        // The grouped/matched digits from the regex
        return match[1];
    }
    return false;


}
let _em = null;

class Controller {
    constructor($controller, $scope, $stateParams) {
        'ngInject';

        let options = {
            name: 'File',
            defaultValues: {}
            // params: { 'includes[]': ['organization'] }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/stories', options);
        em.setDetailId($stateParams.id);
        em.run()

        this.em = em;
        this.$scope = $scope;

    }

    refreshCallback() {
        _em.refresh();
    }



}


const component = {
    template: require('./story.component.html'),
    controller: Controller
};
export default  component;
