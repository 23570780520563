import angular from 'angular';

const MODULE_NAME = 'admin-file';

import files from './files.component'
import file from './file.component'

angular.module(MODULE_NAME, [])
    .component('adminfiles', files )
    .component('adminfile', file)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.files', {
            abstract: true,
            url: '/files',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations','programs'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.files.index', {
            url: '/list',
            component: 'adminfiles'
        })
        .state('admin.files.detail', {
            url: '/:id',
            component: 'adminfile'
        })
}


export default MODULE_NAME;