class BaseBreadService {
    constructor($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService) {
        'ngInject';
        this.$log = $log
        this.Restangular = RestangularFull;
    }

    setEntity(name) {
        this.endpoint = 'admin/' + name;
    }

    /**
     *
     * @param filters  [{ key: key, value: value, operator: 'eq' }]
     * @param includes array ['users']
     */
    find({ filters, includes }) {
        this.setupService();

        let query = {};
        query.includes = includes;
        query.filter_groups = filters


        // let sort = this.getSortParameter();
        // if (sort) {
        //     query.sort = sort;
        // }
        return this.restclient
            .getList(query)
            .then((res) => res.data);
    }

    get(id, { params }) {

        this.restclient = this.Restangular.one(this.endpoint, id);

        params = params || {};

        return this.restclient.get(params)
            .then(res => res.data)
            ;
    }

    getList({ query }) {
        this.restclient = this.Restangular.all(this.endpoint);

        return this.restclient.getList(query)
            .then(res => res.data)
            ;
    }

    create(params) {
        this.restclient = this.Restangular.all(this.endpoint);

        params = params || {};

        return this.restclient.post(params)
            .then(res => res.data)
            ;
    }

    setupService() {
        this.restclient = this.Restangular.all(this.endpoint);
    }


};

export default  BaseBreadService;
