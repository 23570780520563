import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $scope, $stateParams) {
        'ngInject';

        let options = {
            name: 'Brand',
            defaultValues: {}
            // params: { 'includes[]': ['organization'] }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/brands', options);


        em.setDetailId($stateParams.id);
        em.run()
    }
}

const component = {
    template: require('./brand.component.html'),
    controller: Controller
};
export default  component;
