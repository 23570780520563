import angular from 'angular';

const MODULE_NAME = 'admin-videoquestion';

import videoquestions from './videoquestions.component'
import videoquestion from './videoquestion.component'
// import videoquestionService from './videoquestion.service'
// import videoquestionSections from './videoquestion.sections.component'

angular.module(MODULE_NAME, [])
    .component('adminvideoquestions', videoquestions)
    .component('adminvideoquestion', videoquestion)
    // .service('videoquestionservice', videoquestionService)
    // .component('videoquestionsections', videoquestionSections)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
    //@todo there has to be a smarter way
        .state('admin.videoquestions', {
            abstract: true,
            url: '/videoquestions',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.videoquestions.index', {
            url: '/list',
            component: 'adminvideoquestions'
        })
        .state('admin.videoquestions.detail', {
            url: '/:id',
            component: 'adminvideoquestion'
        })
}


export default MODULE_NAME;
