import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

import moment from "moment";


class Controller {
    constructor($controller, $scope, $http, settings, DataStore, $stateParams) {
        'ngInject';

        this.DataStore = DataStore;
        $scope.DataStore = DataStore;
        this.result = {};
        this.$http = $http;
        this.refresh();

        this.settings = settings;
        this.organizationId = null;


    }

    refresh() {

        const url = 'admin/reporting/todos';
        this.$http({ url, method: 'GET', params: {} })
            .then((response) => this.processResponse(response.data));

    }

    fetchProgramFunnel(program) {

    }

    sum(month) {
        let sum = 0;
        _.each(month, user => user.count ? sum += user.count : 0)
        return sum
    }

    processResponse(data) {
        let rows = data.todos;
        let tableStructure = {};

        // getArrayOfAll Questioncodes:
        this.headlines = _.uniq(_.map(rows, 'username')).sort();

        _.each(rows, (row) => {
            tableStructure[row.month] = tableStructure[row.month] || { month: new Date(row.month) };
            tableStructure[row.month][row.username] = { count: row.count, rating: row.rating };
        });


        tableStructure = _.map(tableStructure, obj => obj); // convert object to array

        // sort
        this.report = _.sortBy(tableStructure, 'month');

        return this.report;
    }
}

const component = {
    template: require('./todoReport.component.html'),
    controller: Controller
};
export default  component;
