import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}

function vimeo_parser(url) {
    // https://vimeo.com/279525653

    // Look for a string with 'vimeo', then whatever, then a
    // forward slash and a group of digits.

    const matchPrivate = /vimeo.*\/(\d+)\/.*/i.exec(url);
    if (matchPrivate) {
        return matchPrivate[1];
    }

    // If the match isn't null (i.e. it matched)
    const match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
        // The grouped/matched digits from the regex
        return match[1];
    }
    return false;


}
let _em = null;

class Controller {
    constructor($controller, $scope, $stateParams, $http) {
        'ngInject';

        let options = {
            name: 'File',
            defaultValues: {}
            // params: { 'includes[]': ['organization'] }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/files', options);


        em.setDetailId($stateParams.id);
        em.run()
        _em = em;
        this.em = em;
        this.$http = $http;
        this.filetypes = ['video/vimeo', 'video/youtube', 'application/pdf']; // @tood ?
        this.categories = ['general', 'intranet', 'video', 'personal_upload'];
        this.fetchAllMCBs()
        this.$scope = $scope;

    }

    refreshCallback() {
        _em.refresh();
    }
    fetchAllMCBs(){
        return this.$http.get('admin/mcbs/', {params: {limit: -1}}).then((res) => {
            this.mcbs = _.map(res.data.data, (row) => row)
        })
    }

    updateVideoUrl(model) {

        const url = model.source.toLowerCase();
        if (url.indexOf('vimeo') !== -1) {
            model.mime = 'vimeo';
            var code = vimeo_parser(model.url);
            if (code) {
                model.source = code;
                this.$scope.videoVisible = true;
            }


            return;
        }


        if (url.indexOf('youtube') !== -1) {
            model.mime = 'youtube';
            var code = youtube_parser(model.source);
            if (code) {
                model.source = code;
                this.$scope.videoVisible = true;
            }


        }

        return;

    }

    getVideoUrl(model) {
        switch (model.mime) {
            case 'youtube':
                return 'https://www.youtube-nocookie.com/embed/' + model.source;
            case 'vimeo':
                return 'https://player.vimeo.com/video/' + model.source;
        }
        return '';

    }

}


const component = {
    template: require('./file.component.html'),
    controller: Controller
};
export default  component;
