'use strict';


//
// @todo
//


class WebinarService {
    constructor($log, $http) {
        'ngInject';

        this.$logger = $log.getInstance('TenantService');
        this.$http = $http;
        return this;

    }

    getUpcomingWebinars() {

        let filters = [{ key: 'startsAt', value: 'now()', operator: 'gt' }];
        filters = JSON.stringify([{
            'filters': filters
        }])

        //_.sortBy(res.data, 'startsAt'
        return this.$http.get('/admin/webinars/', { params: { filter_groups: filters } })
            .then((res) => res.data)


    }

}


export default  WebinarService;
