let Settings = null
console.log('process.env.NODE_ENV', process.env);

if (process.env.NODE_ENV === 'production') {
    Settings = require('./settings/settings.prod').default
} else if (process.env.NODE_ENV === 'staging') {
    Settings = require('./settings/settings.staging').default
} else {
    Settings = require('./settings/settings.dev').default
}
console.log("settings found", Settings);
export default Settings;

