import BaseBreadService from '../../services/BaseBreadService'


class ProgramsService extends BaseBreadService {
    constructor($log, $state, $confirm, $http, $rootScope, RestangularFull, DataStore, AlertsService) {
        'ngInject';
        super($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService);
        this.$http = $http;
        this.$log = $log
        this.endpoint = 'programs';

    }

    getForOrganization(id) {

    }

    async attachCourse(programId, courseId, meta) {
        return this.$http.post(`/admin/courses/${courseId}/programs/${programId}`, meta);
    }

    async dettachCourse(programId, courseId, meta) {
        return this.$http.delete(`/admin/courses/${courseId}/programs/${programId}`);
    }
};

export default ProgramsService;
