import angular from 'angular';

const MODULE_NAME = 'services';

import useronlineservice   from './useronline.service'
import ConferenceService   from './conference.service'
import FileUploadService   from './fileupload.component'
import BitlyService   from './bitly.service'
import RoomService   from './room.service'
import LocaleServie   from './locale.service'

const config  = ($translateProvider) => {
    'ngInject';
    $translateProvider
    // .useSanitizeValueStrategy('escapeParameters')
        .registerAvailableLanguageKeys(['en', 'de'], {
            'en_*': 'en',
            'de_*': 'de'
        });
    // .determinePreferredLanguage();

    // var locale = WhiteLabelService.getPreferedLocale();

};






angular.module(MODULE_NAME, [])
    .service('useronlineservice', useronlineservice)
    .service('ConferenceService', ConferenceService)
    .service('BitlyService', BitlyService)
    .service('RoomService', RoomService)
    .service('LocaleService', LocaleServie )
    .config(  config )
    .component('fileUploadComponent', FileUploadService)


export default MODULE_NAME;
