
import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $stateParams, $httpParamSerializer) {
        'ngInject';
        let options = {
                name: 'Registrationcode',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '',
                    'includes[]': ['group','group.organization','autoEnrollIn'],
                    'withCount[]': ['users']
                },
                sort: { 'createdAt': 'desc' }
            }
            ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/registrationcodes', options);
        em.run();

    }
    isPast(date){
        if (!date){
            return false;
        }
        const now = new Date();
        const then = new Date(date);
        return then.getTime() < now.getTime();
    }

    getUserLimitClass(row){

        if (!row.usersCountLimit && row.usersCountLimit === 0){
            return '';
        }
        if (row.usersCountLimit >= row.usersCount){
            return 'bg-danger';
        }
        if (row.usersCountLimit >= row.usersCount*0.9){
            return 'bg-.twarning';
        }

        return '';



    }
}

const component = {
    template: require('./registrationcodes.component.html'),
    controller: Controller,
}

export default  component;
