import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'
import showdown from 'showdown/dist/showdown.min'


class Controller {
    constructor($controller, $log, $scope, $stateParams,$http, settings, OrganizationsService) {
        'ngInject';

        this.$controller = $controller;
        this.$scope = $scope;
        this.$log = $log;
        this.$stateParams = $stateParams;
        this.settings = settings
        this.$http = $http;
        this.OrganizationsService = OrganizationsService;
        this.selectedOrganization = null;

        this.organizationsList = [];
    }

    $onInit() {
        let options = {
            name: 'Brandeddocument',
            defaultValues: { type: 'email', language: 'en' },
            params: {
                'includes[]': ['reviewer'],
            }

            // params: {}
        };

        let id = this.id || this.$stateParams.id;

        this.previewUrl = null;

        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/brandeddocuments', options);
        em.setDetailId(id);
        em.run()
        this.availableVariables();
        this.em = em;

    }
    async getPlaceholderData(){
        const placeholder =  await this.$http.get('admin/brandeddocuments/' + this.$scope.model.id + '/placeholder')

        this.placeholderData = placeholder.data;
        this.$scope.$apply();

    }
    showHistory() {
        let url = this.settings.apiBaseUrl + '/api/v2/admin/brandeddocuments/' + this.$scope.model.id + '/log';
        this.$http.get(url)
            .then((result) => this.historyData = result.data.data);

    }
    onlyPreview() {
        let previewUrl = this.settings.apiBaseUrl + '/api/v2/admin/brandeddocuments/' + this.$scope.model.id + '/preview?time=' + (new Date()).getTime();
        this.previewUrl = previewUrl

    }

    saveAndPreview() {
        let previewUrl = this.settings.apiBaseUrl + '/api/v2/admin/brandeddocuments/' + this.$scope.model.id + '/preview?time=' + (new Date()).getTime();

        this.em.save()
            .then(() => this.previewUrl = previewUrl)

    }
    reviewArticle() {
        let previewUrl = this.settings.apiBaseUrl + '/api/v2/admin/brandeddocuments/' + this.$scope.model.id + '/review';
        this.em.save()
            .then(() => this.$http.post(previewUrl))
            .then(() => this.em.refresh())
        ;


    }

    initShowdown() {
        if (typeof(showdown) == "undefined") {
            this.$log.warn('shodown not availavble')
            return
        }
        let converter = new showdown.Converter();
        converter.setOption('simplifiedAutoLink', true);
        converter.setOption('simpleLineBreaks', true);
        this.showdownConverter = converter

    }

    selectFirstTab() {
        //@todo
    }

    compileHtml(md) {
        if (!this.showdownConverter) {
            this.initShowdown();
        }
        let html = this.showdownConverter.makeHtml(md);
        if (!html) {
            this.$log.warn('[showdown] could not generate html from ', md)
            return
        }
        String.prototype.replaceAll = function (search, replacement) {
            var target = this;
            return target.replace(new RegExp(search, 'g'), replacement);
        };

        html = html.replaceAll('::', '->');
        this.$scope.model.content = html;
    }

    availableVariables() {
        this.variables = [
            {
                entity: 'user',
                variables: [
                    'gender',
                    'first_name',
                    'last_name',
                    'email',
                    'password',
                    'remember_token',
                    'primary_role',
                    'phone_number',
                    'whatsapp_number',
                    'address',
                    'city',
                    'postcode',
                    'country',
                    'country_of_origin',
                    'birthday',
                    'organization_id',
                    'brand_id',
                    'accept_sms',
                    'accept_email',
                    'accept_push',
                    'is_anonymized',
                    'last_reminder',
                    'last_login',
                    'language',
                    'timezone',
                    'state',
                    'last_state_change_at',
                    'registration_code_id',
                    'preferred_channel',
                    'color_code',
                    'servicelevel',
                    'about_me',
                    'position',
                    'phone_number_prefix',
                    'whatsapp_number_prefix',
                    'community_enabled'
                ]
            },
            {
                entity: 'webinar',
                variables: [
                    'state',
                    'title',
                    'description',
                    'duration_minutes',
                    'program_id',
                    'referent_id',
                    'login_link',
                    'organization_id',
                    'invitation_note',
                    'seats',
                    'target_audience',
                    'starts_at'
                ]
            }
            , {
                entity: 'enrollment',
                variables: ['user_id', 'program_id', 'role', 'state', 'training_completed_at', 'last_state_change_at', 'postponed_until']
            },
            {
                entity: 'appointment',
                variables:
                    ['mentorship_id', 'planned_start', 'planned_duration_minutes', 'state',
                        'noshow_reportee_id', 'last_state_change_at']
            }
        ]
    }

    async organizationSelected(organization) {
        this.selectedOrganization = organization;
        this.$scope.model.organization_id = organization.id;

    }
    async searchOrganization(search) {
        if (search===''){
            this.organizationsList = null;
            return;
        }
        this.selectedOrganization = null;
        this.organizationsList = await this.OrganizationsService.findOrganization(search,[]);
        this.$scope.$apply();
    }
}

const component = {
    template: require('./brandeddocument.component.html'),
    controller: Controller,
    bindings: {
        view: '<',
        id: '<'
    }
};
export default  component;
