
export default function () {
    'ngInject';

    return {
        replace: true,
        scope:{
            role:'='
        },
        template:
            `<span class="label label-{{getClass(role)}}">{{role}}</span>`,
        controller: function($scope){
            'ngInject';
            $scope.getClass = function(role){
                if (!role) return 'default';

                role = role.toUpperCase();

                switch (role){
                    case 'MENTOR': return 'info';
                    case 'MENTEE': return 'alternative';
                    case 'COORDINATOR': return 'primary';
                    case 'MANAGER': return 'warning';
                    case 'SUPERVISOR': return 'inverse';
                    case 'ADMIN': return 'danger';
                    case 'SUPERADMIN': return 'danger';
                    case 'AMBASSADOR': return 'primary';
                    default: return 'default';
                }
            }
        },
        restrict: 'E'
    }
}

/**
 * <span class="label label-default">Default Label</span>
 <span class="label label-primary">Primary Label</span>
 <span class="label label-success">Success Label</span>
 <span class="label label-info">Info Label</span>
 <span class="label label-warning">Warning Label</span>
 <span class="label label-danger">Danger Label</span>

 */



