// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

const component = {
    template:
`<div class="spinner">
  <div class="double-bounce1"></div>
  <div class="double-bounce2"></div>
</div>`,


};


export default  component;
