import RestHelperList from '../../lib/api/resthelperlist.class'

import constants from '../../lib/constants'


// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

const component = {
    template: require('./users.component.html'),
    controller($scope, $controller, settings, $stateParams, $httpParamSerializer) {
        'ngInject';
        let options = {
                name: 'User',
                defaultValues: {},
                params: {
                    // limit:50,
                    usr_state: 'any',
                    search: '',
                    'includes[]': ['organization', 'roles'],
                },
                sort: { last_name: 'asc' }
            }
        ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/users', options);
        em.run();

        $scope.availableRoles = constants('roles');
        this.sortOptions = [
            { label: 'Registration date', field: 'createdAt' },
            { label: 'Organisation', field: 'organization_id' },
            { label: 'First Name', field: 'firstName' },
            { label: 'Last Name', field: 'lastName' },
            { label: 'Role', field: 'primaryRole' }
        ];

        $scope.settings = settings;

    }
};


export default  component;
