export default function () {

    return {
        replace: true,
        template: `
  <div class="pagination pull-right text-center " style="margin:0;">
    
       <div style="margin:0 auto;" >
          <small class="text-center">   {{meta.current_page}} of {{meta.last_page}} &middot; ({{meta.total}} entries)<br/></small> 
          <ul class=" pagination" style="margin:0;">
            <li class="prev" ng-class="{disabled: !params.page || params.page==1 }"><a
              ng-click="EntityHelper.prevPage(params.offset)"><i class="fa fa-chevron-left"></i></a></li>
            <li>
              <a ng-click="EntityHelper.refresh()">
                <div class="fa fa-sync"></div>
              </a>
            </li>            <li>
              <a ng-click="EntityHelper.reset(true)" uib-tooltip="Reset all filters">
                <div class="fa fa-ban"></div>
              </a>
            </li>
            <li class="next" ng-class="{disabled: params.page >= meta.last_page }"><a
              ng-click="EntityHelper.nextPage(params.offset)"><i class="fa fa-chevron-right"></i></a></li>
              <li>
              
              <select ng-model="params.limit" uib-tooltip="Show number of results" ng-change="EntityHelper.refresh()" class="form-control">
              <option value=""># Results</option>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>200</option>
               </select>
                </li>
          </ul>
    </div>
  </div>

`,
        restrict: 'E'
    }
}


