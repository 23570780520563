class ConferenceService {
    constructor(AuthenticationService, CurrentUserService, $log, $http, settings) {
        'ngInject';
        this.AuthenticationService = AuthenticationService
        this.settings = settings
        this.$http = $http;
        this.$log = $log
        this.CurrentUserService = CurrentUserService;
    }

    openRoom(roomId, demoMode) {


        const self = this
        return this.getPreviewRoomUrl(roomId, demoMode)
            .then((url) => {
                window.open(url);
            })

    }

    getPreviewRoomUrl(mentorshipId, demoMode) {
        const self = this
        return this.$http.get('/admin/mentorships/' + mentorshipId + '/previewroomlink')
            .then((res) => {
                self.$log.info('[conference] url', res.data)
                return res.data.data.url + (demoMode ? '&mode=demo' : '');
            });
    }

    /**
     * Returns a random integer between min (inclusive) and max (inclusive).
     * The value is no lower than min (or the next integer greater than min
     * if min isn't an integer) and no greater than max (or the next integer
     * lower than max if max isn't an integer).
     * Using Math.round() will give you a non-uniform distribution!
     */
    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     * works only for old room;
     * @param roomId
     * @param demoMode
     * @returns {string}
     */
    getPreviewRoomUrlForRoomId(programId) {
        const self = this

        const user = this.CurrentUserService.getUser();
        const token = user.firebaseToken;
        const randomNumber = this.getRandomInt(0, 5000);
        return this.$http.get('/admin/conference/program/' + programId + '/previewroomlink')
            .then(response => response.data.data);
    }
    async getBetaUrlForProgram(programId){
        const url = await this.getPreviewRoomUrlForRoomId(programId);
        console.log({url});
        return url.url.replace('conference','conference-beta.services');
    }

    createPreviewRoom(program) {
        return this.$http.post('/admin/programs/' + program.id + '/createpreviewroom  ')
    }
    getCourseByKey(key) {
        return this.$http.get(this.settings.onlineUserServiceUrl+'/platform/admin/courses/'+key)
    }


};

export default  ConferenceService;
