
import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $stateParams, $httpParamSerializer) {
        'ngInject';
        let options = {
                name: 'File',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '', 'includes[]': ['program'],
                }
            }
            ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/files', options);
        em.run();

        this.sortOptions = [
            { label: 'Title', field: 'title' },
            { label: 'Id', field: 'id' }
        ];

    }
}

const component = {
    template: require('./files.component.html'),
    controller: Controller,
}

export default  component;
