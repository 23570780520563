import angular from 'angular';

const MODULE_NAME = 'admin-userfeedback';

import userfeedbacks from './userfeedbacks.component'
import userfeedback from './userfeedback.component'
import userfeedbackcard from './userfeedbackcard.component'



angular.module(MODULE_NAME, [])
    .component('adminuserfeedbacks', userfeedbacks )
    .component('adminuserfeedback', userfeedback)
    .component('userFeedbackCard', userfeedbackcard)

    .config(config)


function config($stateProvider) {
    'ngInject';
    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.userfeedbacks', {
            abstract: true,
            url: '/userfeedbacks',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations','options'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.userfeedbacks.index', {
            url: '/list',
            component: 'adminuserfeedbacks'
        })
        .state('admin.userfeedbacks.detail', {
            url: '/:id',
            component: 'adminuserfeedback'
        })
}


export default MODULE_NAME;