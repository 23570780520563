import angular from 'angular';

const MODULE_NAME = 'admin-webinar';

import webinars from './webinars.component'
import webinar from './webinar.component'
import WebinarService from './webinars.service'
import webinarIssues from './webinarIssues.component'

angular.module(MODULE_NAME, [])
    .component('adminwebinars', webinars )
    .component('adminwebinar', webinar)
    .component('webinarissues', webinarIssues)
    .service('WebinarService', WebinarService)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.webinars', {
            abstract: true,
            url: '/webinars',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['programs'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.webinars.index', {
            url: '/list',
            component: 'adminwebinars'
        })
        .state('admin.webinars.detail', {
            url: '/:id',
            component: 'adminwebinar'
        })
}


export default MODULE_NAME;
