class Controller {
    constructor(settings) {
        'ngInject';

        this.linkToA3 = null;
        this.settings = settings;
    }

    $onInit() {
        this.linkToA3 = `${this.settings.admin3Url}/organizations/${this.organizationid}`
    }
}

const component = {
    template: require('./orgnanization.bookings.component.html'),
    controller: Controller,
    bindings: {
        organizationid: '<'
    },


};


export default  component;
