import angular from 'angular';

const MODULE_NAME = 'admin-participation';

import participations from './participations.component'
import participation from './participation.component'

angular.module(MODULE_NAME, [])
    .component('adminparticipations', participations )
    .component('adminparticipation', participation)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.participations', {
            abstract: true,
            url: '/participations',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }

        })
        .state('admin.participations.index', {
            url: '/list',
            component: 'adminparticipations'
        })
        .state('admin.participations.detail', {
            url: '/:id',
            component: 'adminparticipation'
        })
}


export default MODULE_NAME;