import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $scope, $stateParams, $http, StateMachineService, DataStore, CurrentUserService, settings, $uibModal) {
        'ngInject';
        let options = {
            name: 'Organization',
            defaultValues: {
                "name": "",
                "keyaccountId": CurrentUserService.id,
                "servicelevel": "normal",
                "licence_booked": "0",
                "type": "company",
                privateWebinars: false
            },
            params: {'includes[]': ['programs', 'registrationCodes']}
        };
        this.$http = $http;
        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/organizations', options);
        em.setDetailId($stateParams.id);
        em.run()

        this.em = em
        this.settings = settings;
        this.organizationId = $stateParams.id;
        this.$scope = $scope;
        this.privacyModes = DataStore.get('options').PrivacyModes;
        this.StateMachineService = StateMachineService
        this.videoProvider = ['twilio', 'tokbox'];
        this.$uibModal = $uibModal;

        // @todo: move to constants
        this.classifications = {
            'A': '>50.000 employees',
            'B': '>10.000 employees',
            'C': '<10.000 employees'
        };

        this.jfInterval = [
            {value: null, label: 'none'},
            {value: 7, label: '7 days'},
            {value: 14, label: '14 days'},
            {value: 28, label: '28 days'},
            {value: 60, label: '60 days'},
            {value: 90, label: '90 days'},
        ];

        this.enrollmentTypes = [
            {value: null, label: 'Program default'},
            {value: 'inhouse', label: 'Inhouse'},
            {value: 'inhouseFujitsu', label: 'Inhouse (fujitsu)'},
            {value: 'csr-basf', label: 'CSR (basf)'},
            {value: 'drk', label: 'DRK'},
            {value: 'bloombergDEI', label: 'BloombergDEI'},
            {value: 'bloombergCSR', label: 'BloombergCSR'},
            {value: 'waitingList', label: 'Waiting List'},
        ];

        this.clientBranding = {
            programId: undefined,
            customizationType: undefined
        };

        this.getPlans();

        if (this.organizationId){
            this.getProgramOrganizationCustomizations();
            this.getAllowedPlans();
        }

    }

    openSalesforce(model){
        window.open(`https://eu32.lightning.force.com/lightning/r/Account/${model.salesforceId}/view`);
    }
    getPlans() {

        this.$scope.plans = [];
        this.$scope.featureSelectedCheckbox = {};
        this.$http.get('admin/plan-features')
            .then((result) => {
                    this.$scope.plans = result.data.data;
                }
            );

    }
    getProgramOrganizationCustomizations() {

        this.$scope.programOrganizationCustomizations = [];
        this.$http.get('organizations/'+this.organizationId+'/program-organization-customizations')
            .then((result) => {
                    this.$scope.programOrganizationCustomizations = result.data.data;
                }
            );

    }
    removeCustomization(customization) {
        this.$http.delete('admin/organizations/'+this.organizationId+'/program-organization-customization/'+customization.id)
            .then((result) => {
                this.$scope.programOrganizationCustomizations = this.$scope.programOrganizationCustomizations.filter(function (item) {
                    return item.id !== customization.id;
                })
                }
            );
    }
    anonymizeAllUsers(organization) {
        const self = this
        var modalInstance =  this.$uibModal.open({
            component: 'anonymizeUsers',
            resolve: {
                organization: function () {
                    return organization;
                },
            },
        })
        modalInstance.result.then(function (res) {
            self.em.refresh()
        }, function (res) {
            self.em.refresh()
        });
    }
    removeLogo() {
        this.$http.delete('admin/organizations/'+this.organizationId+'/logo')
            .then((result) => {
                   this.$scope.model.logo = undefined;
                }
            );
    }
    removeCertificateBackground() {
        this.$http.delete('admin/organizations/'+this.organizationId+'/certificate-background')
            .then((result) => {
                    this.$scope.model.certificateBackground = undefined;
                }
            );
    }
    customizationUploaded() {
        this.clientBranding = {
            programId: undefined,
            customizationType: undefined
        };
    }

    updateAdditionalFeatures(planType, featureId) {
        //@todo: shadi, should be done with async await
        if(this.$scope.featureSelectedCheckbox[planType][featureId]){
            this.$http.post('admin/organizations/'+this.organizationId+'/plan-features/'+featureId)
                .then((result) =>
                    {
                        console.log(result);
                    }

                );
        } else{
            this.$http.delete('admin/organizations/'+this.organizationId+'/plan-features/'+featureId)
                .then((result) =>
                    {
                        console.log(result);
                    }

                );
        }
    }

    getAllowedPlans() {

        this.$scope.allowedPlans = [];
        this.$http.get('admin/organizations/' + this.organizationId + '/plan-features')
            .then((result) => {
                this.$scope.allowedPlans = result.data.data;
                    for (var planName in this.$scope.plans) {
                        this.$scope.featureSelectedCheckbox[planName] = {};
                        var arr = this.$scope.plans[planName]
                        for (var feature of arr) {
                            this.$scope.featureSelectedCheckbox[planName][feature.id] =
                                this.$scope.allowedPlans.plan.includes(feature.name)
                                || this.$scope.allowedPlans.additional.includes(feature.name)
                        }
                    }
                }
            );

    }

    getTechnicalSolutionComment(model) {

        if (model.technicalDeviceCheck && model.technicalCompanyNetworkCheck) {
            return 'No restrictions: Platform can be used from companies device within the company.';
        }

        if (model.technicalDeviceCheck && model.technicalGuestWifiCheck) {
            return 'Guest Wifi: Please use private or work device with the available guest wifi. (Alternative: Private device at home)';
        }
        if (model.technicalGuestWifiCheck) {
            return 'Guest Wifi with private Device: Please use your private device in the Guest Wifi or at home.';
        }

        return "Please use your private device at home.";

    }

    technicalSetupDefined(model) {
        return model.technicalDeviceCheck !== null && model.technicalGuestWifiCheck !== null && model.technicalCompanyNetworkCheck !== null && model.technicalWebinarCheck !== null;
    }

    transition(organization, name) {
        const self = this
        this.StateMachineService.transition('organization', organization.id, name)
            .then(() => self.em.refresh())
        ;
    }

    updateInputCode(name) {
        name = name || '';
        let code = name.replace(/[^A-Za-z0-9!?]/g, '').toUpperCase().substr(0, 10);

        if (!this.$scope.model.code) {
            this.$scope.model.code = code;
        }
    }


    addProgramm(id) {
        // @todo: check if already exists?
        this.em.getModel().post('programs/' + id)
            .then(() => this.em.refresh())
    }

    removeProgramm(id) {
        this.em.getModel().one('programs', id).remove()
            .then(() => this.em.refresh())
    }

    updatePivotEnrollmentType(id, enrollmentType) {

        this.em.getModel().one('programs', id).customPUT({enrollmentType})
            .then(() => this.em.refresh())


    }

}

const component = {
    template: require('./organization.component.html'),
    controller: Controller
};
export default component;
