// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

import Constants from '../../lib/constants'
import RestHelperDetail from "../../lib/api/resthelperdetail.class";
import RestHelperList from "../../lib/api/resthelperlist.class";


class Controller {
    constructor($scope, $state, $controller, RestangularFull) {
        'ngInject';
        this.$scope = $scope
        this.Restangular = RestangularFull
        this.api = null //client
        this.types = ['EMAIL', 'PHONE', 'JF','OTHER'];
        this.history = [];
        this.user = null;
        this.$controller = $controller;

        const self = this;
        this.reset();
    }


    $onInit() {
        'ngInject';
        const self = this
        const commentUrl = 'organization/' + this.id + '/comments/admin'


        let options = {
            name: 'OrganizationInteractions',
            forceFilter: [{ key: 'organization_id', value: this.id, operator: 'eq' }],
            params: { 'includes[]': ['coordinator'] },
            sort: { 'created_at': 'desc' },
        };

        let em = this.$controller(RestHelperList);
        em.setup(this.$scope, 'admin/organizationinteractions', options);
        em.run();


        self.commentsApi = this.Restangular.all(commentUrl)

        this.em = em;


        em.addHook('AFTER_LIST_GET', (e) => {
            setTimeout(() => self.merge());
        });

        this.refresh()

    }

    isInFuture(date) {
        return date && (new Date(date)).getTime() > (new Date());
    }

    reset() {
        this.$scope.newComment = { type: 'organisational', text: '' };
    }


    addInteraction(data) {
        // todo add empty interaction;
        // const model =  this.em.getModel({test:"test"});/

        return this.em.createModel({ organization_id: this.id, type: data.type, interactionAt: data.interactionAt })
            .then(() => this.em.refresh());

    }

    refresh() {

        /*
         history contains:
            1) activties
            2) -- comments
            3) state changes
            4) -- notificatons
            5) -- todos
            6)  -- feedbacks
         */
        this.em.refresh();
        const self = this;

        // const self = this
        self.isLoading = true
        self.commentsApi.getList({ 'includes[]': 'author' })  // GET: /users
            .then(function (comments) {
                self.isLoading = false
                self.comments = comments.data;
                self.merge();
            })

    }

    savePreparation(entry) {
        setTimeout(() => entry.save(), 50);

        ; //hack, but works;


    }
    stateChange(entry, newState){
        entry.state = newState;
        entry.save();

    }
    merge() {

        const history = [];

        _.each(this.$scope.rows, row => history.push({
            date: new Date(row.interactionAt || row.createdAt),
            type: 'interaction',
            data: row
        }));
        _.each(this.comments, comment => history.push({
            date: new Date(comment.createdAt),
            type: 'comment',
            data: comment
        }));

        function sorting(a, b) {
            if (!a.date){
                return 1;
            }
            if (!b.date){
                return -1;
            }
            // a.data = a.data || {};
            // b.data = b.data || {};
            // a.data.pinToTop = !!a.data.pinToTop; // make sure false === undefined;
            // b.data.pinToTop = !!b.data.pinToTop; // make sure false === undefined;
            // if (a.data.pinToTop !== b.data.pinToTop) {
            //     return (a.data.pinToTop ? -1 : 1);
            // }

            return b.date.getTime() - a.date.getTime();
        }

        history.sort(sorting);



        this.history = history;


        this.$scope.$apply();



    }


}

const component = {
    template: require('./organizationInteractionLog.component.html'),

    bindings: {
        entity: '<', //One-way (“<”) data bindings; Expressions (“&”) bindings // Evaluated DOM attribute (“@”) bindings
        id: '<',
        scope: '<'
    },
    controller: Controller
};


export default  component;
