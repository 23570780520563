import RestHelperList from '../../lib/api/resthelperlist.class'

import constants from '../../lib/constants'


// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

const component = {
    template: require('./translations.component.html'),
    controller($scope, $controller, $stateParams, $httpParamSerializer) {
        'ngInject';
        let options = {
                name: 'Translation',
                defaultValues: {},
                params: {}
            }
            ;
        let em = $controller(RestHelperList);
        let self = this;
        em.setup($scope, 'admin/translations', options);
        em.run();

        // @todo: this controller could be replaced by something like /admin/translation/getScopes;


        em.addHook('AFTER_LIST_GET', (results) => {

            let scopes = _.uniq(_.map(results, 'scope'));

            self.options = _.map(scopes, (scope) => {
                return {id: scope, title: scope};
            });


        })

        // $scope.availableRoles = constants('roles');
        //


    }
};


export default  component;
