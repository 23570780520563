export default  httpErrorHandler;


function httpErrorHandler($q, $location, $injector, settings) {
    'ngInject';

    return {
        'request': function (config) {
            let apiPrefix = settings.apiFullUrl;

            if (config.url.charAt(0) === '/') {
                config.url = config.url.substr(1);
            }

            if (config.url.indexOf('.html') === -1) {

                if (config.url.indexOf('http') !== 0 && config.url.indexOf('API') !== 0) {
                    config.url = 'API' + config.url;
                }
                if (config.url.indexOf('APIBASE') !== -1) {
                    config.url = config.url.replace('APIBASE', settings.apiBaseUrl);
                }

                if (config.url.indexOf('API') !== -1) {
                    config.url = config.url.replace('API', apiPrefix);
                }
            }

            // is API request? then add authentication;
            // if (config.url.substring(0, apiPrefix.length) !== apiPrefix) {
            if (true) { //@todo
                let authService = $injector.get('AuthenticationService');
                let access_token = authService.getAccessToken();
                if (access_token) {
                    config.headers['Authorization'] = 'Bearer ' + access_token;
                }
            }


            // do something on success
            return config;
        },


        // optional method
        'requestError': function (rejection) {
            console.log('requestError', rejection);
            var AlertsService = $injector.get('AlertsService');
            AlertsService.showConstant('error', 'REQUEST_FAILED');
            return $q.reject(rejection);
        },


        // optional method
        'responseError': function (rejection) {

            let message = _.get(rejection, 'data.message') || _.get(rejection, 'data.error') || null

            console.log('response error', rejection)
            var AlertsService = $injector.get('AlertsService');

            switch (rejection.status) {
                case 499:
                    if ($location.url().indexOf('login') !== -1) return;
                    return;

                case 403:
                    AlertsService.showConstant('warning', message || 'ACCESS_DENIED');
                    return $q.reject(rejection);

                case 401:
                    AlertsService.showConstant('warning', message || 'NOT_AUTHENTICATED')
                    var $state = $injector.get('$state');
                    $state.go('auth.login');
                    return;
                case 404:
                    AlertsService.showConstant('warning', message || 'NOT_FOUND');
                    return $q.reject(rejection); //$q.reject('UNAUTHENTICATED', rejection );
                case 400:
                    console.log('[400] Bad Request ', rejection);
                    AlertsService.showConstant('warning', message || 'ERROR_400');
                    return $q.reject(rejection);
                case 422:
                    AlertsService.showConstant('warning', message || 'ERROR_400');
                    return $q.reject(rejection);

                case 500:
                    console.log('[500] Server error Request ', rejection);
                    AlertsService.show('warning', 'SERVER_ERROR', message || 'KNOWN_SERVER_ERROR');
                    return $q.reject(rejection);
                case 502:
                    console.log('[502] Server down ', rejection);
                    var html = '<center><h1> Wartungsarbeiten </h1> Wir führen gerade Wartungsarbeiten durch. Bitte versuche es in wenigen Minuten erneut.</center>';
                    html += '<br/><br/><center><h1> Maintenance </h1> We are currntly doing maintenance work. Please try it in several Minutes again.</center>';
                    html += '<br/><br/><center><b> if the problem stays, please call Simon: 0177 670 69 37</b>.</center>';


                    $('body').html(html);
                    return $q.reject(rejection);
                case -1:
                    console.log('[500] Server error Request ', rejection);
                    AlertsService.showConstant('error', message || 'SERVER_NOT_REACHABLE');
                    return $q.reject(rejection);
                default:
                    console.error('[HttpErrorHandler] Server error ', rejection);
                    message = message || 'UNKNOWN_SERVER_ERROR';
                    // AlertsService.showConstant('error', message, 'Oh Snap! Systemerror ' + rejection.status);
                    return $q.reject(rejection);


            }

        }
    };
}
