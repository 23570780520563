import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'
import showdown from "showdown/dist/showdown.min";

class Controller {
    constructor($controller, $scope, $http,$confirm, $stateParams) {
        'ngInject';

        // let options = {
        //     name: 'Supportanswervie',
        //     defaultValues: {},
        //     params: { 'includes[]': ['relations'] }
        // };
        //
        // this.newLink = {}
        // let em = $controller(RestHelperDetail);
        // em.setup($scope, 'admin/supportanswers', options);
        // this.types = ['question', 'solution', 'template'];
        // this.linkClasses = [];
        // this.$confirm = $confirm;
        // this.$http = $http;
        // this.$scope = $scope;
        // this.em = em;
        //
        // em.setDetailId($stateParams.id);
        // em.run()
    }

}

const component = {
    template: require('./myprofile.component.html'),
    controller: Controller
};
export default  component;
