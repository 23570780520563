import angular from 'angular';

const MODULE_NAME = 'admin-technical';

import technical from './technical.component'
import requesthelper from './requesthelper.component'

angular.module(MODULE_NAME, [])
    .component('admintechnical', technical )
    .component('requesthelper', requesthelper )
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.technical', {
            abstract: true,
            url: '/technical',
            template: '<ui-view></ui-view>'
        })
        .state('admin.technical.index', {
            url: '/list',
            component: 'admintechnical'
        })
}


export default MODULE_NAME;