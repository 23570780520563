import angular from 'angular';

const MODULE_NAME = 'admin-quiz';

import quiz from './quiz.component'

angular.module(MODULE_NAME, [])
    .component('quiz', quiz)
    .config(config)


function config($stateProvider) {
    'ngInject';

    $stateProvider
    //@todo there has to be a smarter way
        .state('admin.quizzes', {
            abstract: true,
            url: '/quizzes',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
            }

        })
        .state('admin.quizzes.detail', {
            url: 'sections/:sectionId/quizzes/:quizId',
            component: 'quiz',
            params: {
                quizId: { squash: true, value: null },
            }
        })
}


export default MODULE_NAME;
