class Service {
    constructor($state, $http, $log) {
        'ngInject';
        this.$http = $http;
        this.$log = $log;

        this.stateMachineObj = ['webinar', 'user', 'appointment', 'enrollment', 'match'];

    }

    $onInit() {

    }

    transition(objName, id, transition) {

        if (this.stateMachineObj.indexOf(objName) === -1) {
            this.$log.error('[statemachine service] invalid statemachine type name: ' + objName)
        }

        let url = 'admin/statemachine/' + objName + '/' + id + '/' + transition;

        return this.$http.post(url)
    }

}


export default  Service;
