import angular from "angular";

const MODULE_NAME = "admin-booking";

angular
  .module(MODULE_NAME, [])
  .service("BookingService", require("./bookings.service").default)

  .config(config);

function config($stateProvider) {}

export default MODULE_NAME;
