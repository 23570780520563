import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'


class Controller {
    constructor($controller, $scope, $confirm, $stateParams, $http, OrganizationsService, CurrentUserService, AuthenticationService, settings) {
        'ngInject';
        let options = {
            name: 'Organization',
            defaultValues: {
                "name": "",
                "keyaccountId": CurrentUserService.id,
                "servicelevel": "normal",
                "licence_booked": "0",
                "type": "company",
                privateWebinars: false
            },
            params: {
                'includes[]': ['programs', 'keyaccount'],
            }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/organizations', options);
        em.setDetailId($stateParams.id);


        em.run()

        this.id = $stateParams.id
        this.kpis = null
        this.em = em
        this.$http = $http
        this.settings = settings;
        this.authService = AuthenticationService
        this.$confirm = $confirm;

        this.OrganizationsService = OrganizationsService;

        this.forceNoDpa = false;


    }

    refresh() {
        this.em.refresh();

    }


    downloadInternalReport() {
        return this.$confirm({ text: 'I will not share this with the client and will remove it from my computer after 24hrs latest.' })
            .then(() => this.OrganizationsService.downloadXlsxReport(this.id, 'internal'));
    }


    transition(webinar, name) {
        const self = this
        this.StateMachineService.transition('webinar', webinar.id, name)
        self.em.refresh();
    }


    addProgramm(id) {
        // @todo: check if already exists?
        this.em.getModel().post('programs/' + id)
            .then(() => this.em.refresh())
    }

    removeProgramm(id) {
        this.em.getModel().one('programs', id).remove()
            .then(() => this.em.refresh())
    }

}

const component = {
    template: require('./organization.reporting.component.html'),
    controller: Controller
};
export default  component;
