import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $scope, $http, $stateParams) {
        'ngInject';
        let options = {
            name: 'Enrollment',
            defaultValues: {},
            params: { 'includes[]': ['program', 'user', 'participations'] }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/enrollments', options);


        em.setDetailId($stateParams.id);
        em.run()
        this.em = em;
        this.scope = $scope;
        this.$http = $http;

    }


    showButton(transitionName, state) {


        if (transitionName === 'noreply') {
            return (['NEW', 'ACTIVE'].indexOf(state) > -1)
        }
        if (transitionName === 'quit') {
            return (['NEW', 'ACTIVE', 'DONE'].indexOf(state) > -1);
        }
        if (transitionName === 'approve'){
            return (['APPROVAL'].indexOf(state) > -1);
        }
        return true;
    }

    transtion(name) {
        this.$http.post('admin/statemachine/enrollment/' + this.scope.model.id + '/' + name)
            .then(() => alert('transition ' + name + ' was applied successfully'))
            .then(() => this.em.refresh())

    }

    // transition(name){
    //
    //     const self = this;
    //     this.StateMachineService.transition('match', this.id, 'activate')
    //         .then(() => {
    //             self.em.refresh();
    //             alert('match was activated');
    //         })
    //
    //
    // }

}

const component = {
    template: require('./enrollment.component.html'),
    controller: Controller
};
export default  component;
