import RestHelper from './resthelper.class'
import _ from 'lodash';


let STORED_PARAMETER = {};


/***
 * @todo: document all options here.
 *
 *  options: {
 *      sort: { firstName: 'asc', lastName: 'desc',.
 *      filter: { firstName: 'Simon' }
 *      forceFilter:
 *      // @todo: complex filter ? (will be morged
 *    }
 *
 *  }
 *
 *  available Events (hooks):
 *   AFTER_LIST_GET,PRE_LIST_GET
 *
 *
 */
class RestHelperList extends RestHelper {

    constructor($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService) {
        'ngInject';
        super($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService)
    }

    setup($scope, endpoint, options) {
        super.setup($scope, endpoint, options);

        let self = this;

        if (!self._setup.$scope.rows) {
            self._setup.$scope.rows = [];
        }


        self._setup.restclient = this.Restangular.all(endpoint);
        this.$logger = this.$log.getInstance('[Rest-List][' + self.options.name || endpoint + ']');
    }

    inject($scope) {
        super.inject()
        let scope = this.getScope();
        let self = this;


        scope.add = () => self.$state.go('^.detail', { id: '' });
        scope.edit = (row) => self.$state.go('^.detail', { id: row.id || row });
        scope.refresh = self.refresh;
        scope.updateSortBy = function (field) {
            self.updateSortBy(field);
        };


        scope.sort = scope.sort || self.options.sort || {};
        scope.filter = scope.filter || self.options.filter || {};

    }

    run() {
        super.run();
        this.inject();
        this.reloadStoredParameter();
        this.reset(false);


        // this.refresh();

    }

    resetStoredParameter() {
        STORED_PARAMETER[this._setup.endpoint] = false;
    }

    reloadStoredParameter() {

        const storedValues = STORED_PARAMETER[this._setup.endpoint];
        let scope = this.getScope();

        if (!storedValues) {
            return;
        }
        ['params', 'filter', 'scope'].forEach((el) => {
            if (storedValues[el]) {
                scope[el] = storedValues[el];
            }
        });
    }

    saveStoredParameter() {
        if (!STORED_PARAMETER[this._setup.endpoint]) {
            STORED_PARAMETER[this._setup.endpoint] = {};
        }

        let scope = this.getScope();

        STORED_PARAMETER[this._setup.endpoint] = angular.copy({
            params: scope.params,
            filter: scope.filter,
            sort: scope.sort,
        });
    }


    /**
     * In scope there should be a value like:
     * scope.sort = { firstName: 'asc', ..}
     *
     * @returns {string}
     */
    getSortParameter() {
        //sort[0][key]=firstName&sort[1][key]=lastName&sort[1][direction]=desc
        // sort = [ { key: 'firstName', direction: 'asc' }, {key, direction}, ..]
        let scope = this.getScope();

        if (!scope.sort) {
            return;
        }
        let array = _.map(scope.sort, (value, key) => {
            return { key: key, direction: value }
        });
        return JSON.stringify(array);

    }

    setForceFilter(filter) {
        this.options.forceFilter = filter
    }

    /**
     *
     */
    getFilterGroups() {

        if (this.options.forceFilter) {
            return JSON.stringify([{
                'filters': this.options.forceFilter
            }])
        }

        let scope = this.getScope();
        if (!scope.filter) {
            return;
        }

        let filters = []
        _.mapValues(scope.filter, (value, key) => {
                if (!value) {
                    return;
                }
                if (value.key) { // key: 'key', value: 'value', operator:'='. filter
                    filters.push(value);
                }
                if ((typeof(value) === "string" || typeof(value) === "number") && value !== '') // key:value filter
                    filters.push({ key: key, value: value, operator: 'eq' })
            }
        )

        /*
         filters = [{
         'key': 'firstName',
         'value': 'Admin',
         'operator': 'eq'
         }]
         */
        return JSON.stringify([{
            'filters': filters
        }])

    }

    save(model) {

        this.isSaving = true
        const self = this

        return model.save()
            .then((res) => {
                this.isSaving = false
                if (!res.data) {
                    self.$logger.error(' >>> Error on Saving model - model:', model);
                    return;
                }

                self.AlertsService.showConstant('success', 'Successfully saved');
            })
            .catch((result) => {
                this.isSaving = false
                if (scope && result.data) scope.errors = result.data.errors;
            })

    }

    updateSortBy(sortField) {
        let scope = this.getScope();
        const newOrder = scope.sort[sortField] === 'desc' ? 'asc' : 'desc';
        ;
        scope.sort = {};
        scope.sort[sortField] = newOrder;
        this.refresh();
    }


    reset(force) {
        let scope = this.getScope();
        if (!scope.params || force) {
            scope.params = angular.copy(this.defaultOptions.params);
        }
        if (force) {
            scope.filter = {};
            scope.sort = {};
            this.resetStoredParameter();
        }


        this.refresh();
    }

    nextPage(offset) {
        let scope = this.getScope();
        if (scope.params.page >= scope.meta.last_page) {
            return;
        }
        scope.params.page = (scope.params.page || 1) + 1;

        this.refresh();
    }

    prevPage(offset) {
        let scope = this.getScope();
        scope.params.page = (scope.params.page || 1) - 1;
        if (scope.params.page < 1) {
            scope.params.page = 1
        }
        this.refresh();
    }

    refresh(query) {
        let self = this;

        query = query || {};
        query = angular.extend(query, self._setup.$scope.params);
        // query = this.renderQuery(query);

        if (this._emitEvent('PRE_LIST_GET', query) === false) {
            this.$logger.info(' <<< refreshList canceled by hook');
            return;
        }

        this.$logger.info(' === Refreshing list - query:', query);
        this.saveStoredParameter()

        let filter = this.getFilterGroups();
        if (filter) {
            query.filter_groups = filter
        }
        let sort = this.getSortParameter();
        if (sort) {
            query.sort = sort;
        }

        let scope = self.getScope();


        return self._setup.restclient
            .getList(query)
            .then(function (res) {

                let responseBody = res.data;

                let scope = self.getScope();

                self.$logger.info(' <<< got response:', responseBody, responseBody.meta);
                self._emitEvent('AFTER_LIST_GET', responseBody, responseBody.meta);
                scope.meta = responseBody.meta;
                scope.rows = responseBody;
                scope.isLoading = false;
                scope.lastReload = new Date();
            })
            .catch(function (err) {
                self.$logger.error(' Refreshing list - Error:', err);
                self._setup.$scope.isLoading = false;
            });
    }

    createModel(data) {
        return this._setup.restclient.post(data);
    }
}

export default  RestHelperList;
