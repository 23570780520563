import angular from 'angular';
import services from './services'

import 'angular-moment'


// vendor
import 'ng-quill'
import 'angular-animate'
import 'angular-sanitize'
import 'angular-ui-bootstrap'
import 'ng-file-upload'
import 'ui-select'
import '@uirouter/angularjs'
import 'restangular'
import 'angular-confirm'
import 'angular-promise-buttons';
import 'angular-jsoneditor';

import '../vendor.js'


// @todo http://mrsteele.github.io/ng-alerts/


import auth from './auth'
import admin from './admin'

import shared from './shared'
import settings from './settings'
import lib from './lib'
import 'lodash'
import toaster from 'angularjs-toaster'

import trix from 'trix'
import achartjs from 'angular-chart.js'
import angularTrix from 'angular-trix';

import acountries from 'angular-countries'
import chartjs from 'chart.js/dist/Chart'


import 'famfamfam-flags/dist/sprite/famfamfam-flags.min.css'
import '../style/fontawesome/svg-with-js/js/fa-solid'
import '../style/fontawesome/svg-with-js/css/fa-svg-with-js.css'

const MODULE_NAME = 'app';
const module =
    angular.module(MODULE_NAME, [

        'ngAnimate',
        'ngSanitize',
        'ui.bootstrap',
        'angularMoment',
        'ngFileUpload',

        'chart.js',
        'ui.select',
        'toaster',
        'ui.router',
        'ngCountries',
        'restangular',
        'angular-confirm',
        'angularPromiseButtons',
        'ngQuill', // test phase
        'angularTrix',

        lib,
        shared,

        services,
        auth,
        admin
    ])
;

module.constant('moment', require('moment-timezone'));


const app = {
    template: '<ui-view></ui-view>',
};

module
    .component('app', app)
    .provider('settings', settings)
;

module
    .config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
        'ngInject';
        $stateProvider
            .state('root', {
                url: '/',
                template: '<p class="text-center"><a ui-sref="auth.login">&raquo; Login</a><br/> Falls die Seite nicht läd, kontaktieren Sie uns bitte unter info@volunteer-vision.com </p>',
                resolve: {
                    auth: ($state, AuthenticationService) => {
                        'ngInject';
                        AuthenticationService.bootstrap()
                        return true;
                    }
                }
                ,
                controller: function (AuthenticationService, $state) {
                    'ngInject';
                    // auto login
                    if (AuthenticationService.hasCredentials()) {
                        $state.go('admin.dashboard.index');
                    } else {
                        $state.go('auth.login');
                    }
                }

            });

        $urlRouterProvider
            .otherwise('/');


        $locationProvider.html5Mode(false);
    })


;


module.config(function (uiSelectConfig) {
    uiSelectConfig.theme = 'bootstrap';
})

function config() {

}


export default module;
// export default MODULE_NAME;
