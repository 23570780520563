
import Constants from '../../lib/constants'
import _ from 'lodash'
import UAParser from 'ua-parser-js';

import RestHelperDetail from "../../lib/api/resthelperdetail.class";

const emailRequest= `
    Dear {{name}},
    
    thank you for your time to make a quick IT check. To find the issue very fast we may need the following links:
    
    
    1. Precall-Check: 
    
    https://tokbox.com/developer/tools/precall/
    
    2. Sample Account:
    
    user: sos.mentor@volunteer-vision.com
    password: volunteer!
    url: https://apvolunteer-vision.com
    
    
    3. Remote Support Tool (Teamviewer):
    
    https://get.teamviewer.com/volunteervision
    
   
   
    Looking forward to talk to you later.
       
    
    
`

class Controller {
    constructor($controller, $http, $scope, $stateParams, settings) {
        'ngInject';

        this.$http = $http;
        this.$scope = $scope;
        this.currentAnswers = [1]
        // this.selectedUser = false // for testing; false = i do not need one;
        this.$controller = $controller;
        this.emailTemplate = '';


    }

    userSelected(user){

        let options = {
            name: 'User',
            defaultValues: {},
            params: { 'includes[]': ['launches'] }
        };

        let em = this.$controller(RestHelperDetail);
        em.setup(this.$scope, 'admin/users', options);
        em.setDetailId(user.id);
        em.run()

        this.getEmailTemplate();

        this.selectedUser = user;
    }
    reset() {
        this.currentAnswers = [1]
        this.selectedUser = null;
    }
    formatBrowser(uastring){
        // var uastring = "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/535.2 (KHTML, like Gecko) Ubuntu/11.10 Chromium/15.0.874.106 Chrome/15.0.874.106 Safari/535.2";
        const parser = new UAParser();
        parser.setUA(uastring);
        const result = parser.getResult();
        const device = (result.device.vendor ? result.device.vendor : '') + ' ' + (result.device.model ? result.device.model : '') + ' ' +   (result.device.model ? result.device.type : '');

        return `${result.browser.name} (${result.browser.version}) | ${device} | ${result.os.name}  (${result.os.version})  |`;

    }

    answerSelected(answerId, index){

        this.currentAnswers = this.currentAnswers.slice(0,index + 1);
        this.currentAnswers.push(answerId)

    }

    getEmailTemplate(){
        this.emailTemplate = emailRequest;

    }


}

const component = {
    template: require('./itcheck.component.html'),
    controller: Controller
};
export default  component;
