
import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $stateParams, $httpParamSerializer) {
        'ngInject';
        let options = {
                name: 'Story',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '', 'includes[]': ['user'],
                }
            }
            ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/stories', options);
        em.run();

        // this.sortOptions = [
        //     { label: 'Title', field: 'title' },
        //     { label: 'Id', field: 'id' }
        // ];

    }
}

const component = {
    template: require('./stories.component.html'),
    controller: Controller,
}

export default  component;
