import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $http, DataStore) {
        'ngInject';
        this.$controller = $controller
        this.$scope = $scope;
        this.DataStore = DataStore;
        this.$http = $http;



    }

    $onInit() {


        let options = {
                name: 'Brandeddocument',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '', 'includes[]': ['program'],
                    limit: 10,
                    sort: { 'key': 'asc' },

                },
                filter: this.filter
            }
        ;
        let em = this.$controller(RestHelperList);
        em.setup(this.$scope, 'admin/brandeddocuments', options);
        em.run();
        this.overviewTable = [];
        em.addHook('AFTER_LIST_GET', (results) => this.generateOverviewTable(results))
    }

    renderAll() {
        const uri = (id) => `/admin/brandeddocuments/${id}/preview?time=` + (new Date()).getTime();
        let i = 0;
        for (let row of this.$scope.rows) {
            i = i + 1;
            row.renderState = "requesting";

            // throttle requests to avoid rate limit
            setTimeout(() =>
                    this.$http.get(uri(row.id))
                        .then((res) => row.renderState = (res.status === 200 ? 'ok' : res.status))
                        .catch(e => row.renderState = e.status)
                , 100 * i);

        }
    }

    generateOverviewTable(results) {
        let allObjects = {};
        _.each(results, (row) => {
            allObjects[row.key] = allObjects[row.key] || { key: row.key, programs: [] };

            allObjects[row.key]['lang_' + row.language] = true; // @otod: only counts iwthout brand and program.
            allObjects[row.key]['id_' + row.language] = row.id;

            allObjects[row.key]['title'] = row.internalTitle;
            allObjects[row.key]['key'] = row.key;
            allObjects[row.key]['type'] = row.type;
            if (row.program)
                allObjects[row.key]['programs'].push(row.program)

        })
        this.overviewTable = _.map(allObjects, (o) => o);

        let keys = this.DataStore.get('options').BrandedDocumentKeys;
        this.missingEntries = _.filter(keys, (k) => {
            return !!!allObjects[k];
        })


    }
}

const component = {
    template: require('./brandeddocuments.component.html'),
    controller: Controller,
    bindings: {
        filter: '<',
        embededView: '<'
    }
}

export default  component;
