import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $http, $scope, $stateParams, DataStore, StateMachineService) {
        'ngInject';
        let options = {
            name: 'Webinar',
            defaultValues: {
                title: '',
                durationMinutes: 0,
                targetAudience: 'mentor',
            },
            params: {
                'includes[]': ['enrollments', 'enrollments.user'], //speaker
            }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/webinars', options);

        em.setDetailId($stateParams.id);
        em.run()

        em.addHook('AFTER_SINGLE_GET', (e) => {
            if (e.startsAt) {
                e.startsAt = new Date(e.startsAt);
            }
            this.getCompaniesWithPrivateWebinars(e);

        })

        $scope.datepickerOptions = {minDate: new Date()};
        $scope.datepickerOptions.open = false;
        $scope.now = new Date();

        this.$scope = $scope;
        this.$http = $http;
        this.em = em;
        this.DataStore = DataStore;

        this.StateMachineService = StateMachineService;
        this.getCompaniesWithPrivateWebinars();

    }

    unregisterEnrollment(webinar, enrollment) {

        let url = 'webinars/' + webinar.id + '/attendees/' + enrollment.id + '/unenroll';
        const self = this;

        enrollment.loading = true;

        this.$http.post(url)
            .then(() => {
                enrollment.loading = false
                self.em.refresh();
            })
            .catch((e) => {
                console.error(e);
                //@todo show alerts service
                enrollment.loading = false
            })


    }

    getCompaniesWithPrivateWebinars(model) {
        if (!model) {
            model = this.$scope.model;
        }
        var programId = model.programId;

        if (!programId) {
            return;
        }

        let filter = JSON.stringify([{
            'filters': [{"key": "privateWebinar", "value": '' + programId, "operator": "eq"}]
        }])


        const programs = this.DataStore.get('programs');
        const program = _.find(programs, p => p.id === programId);
        if (program && program.webinarLink) {
            model.loginLink = program.webinarLink;
        }

        var params = {
                filter_groups: filter
            }
        ;

        this.$http.get('admin/organizations', {params})
            .then((res) => this.$scope.privateWebinarOrganizations = res.data.data);
    }

    openDatepicker() {
        this.$scope.datepickerOptions.open = !this.$scope.datepickerOptions.open;

    }

    transition(webinar, name) {
        const self = this
        this.StateMachineService.transition('webinar', webinar.id, name)
            .then(() => self.em.refresh())
    }

    markEnrollmentAsParticipated(webinar, enrollment, wasThere) {

        let action = wasThere ? 'attended' : 'missed'

        // route: 'api/v2/webinars/{webinarId}/attendees/{enrollmentId}';

        let url = 'webinars/' + webinar.id + '/attendees/' + enrollment.id + '/' + action;

        enrollment.loading = true;
        this.$http.post(url)
            .then(() => {
                enrollment.loading = false
                enrollment.attended = wasThere

            })
            .catch((e) => {
                //@todo show alerts service

                enrollment.loading = false
            })


    }
}


const
    component = {
        template: require('./webinar.component.html'),
        controller: Controller
    };
export default component;
