import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

class Controller {
    constructor($controller, $interpolate, $confirm, RestangularFull, DataStore, ConferenceService, $http, $scope) {
        'ngInject';

        $scope.DataStore = DataStore;
        this.$compile = $interpolate;
        this.$confirm = $confirm;
        this.Restangular = RestangularFull;

        this.$http = $http;
        this.program = null;
        this.ConferenceService = ConferenceService;
        this.receiverString = '';
        this.amountReceivers = null;
        this.amountSend = 0;
        this.receivers = [];
        this.userObjects = [];

        this.messageToSend = 'Hello {{name}}';

    }

    selectReceivers() {
        var list = this.receiverString.split(',');
        this.receivers = [];

        list = _.map(list, entry => parseInt(entry) ? parseInt(entry) : '')
            .filter(entry => Number.isInteger(entry))
        ;

        this.receiverString = list.join(',');
        if (!list.length || list.length === 0) {
            return;
        }
        this.amountReceivers = list.length;
        this.receivers = list;

    }

    _getAllUsers() {
        const self = this;

        const promises = _.map(this.receivers, (id) => {
            return self.Restangular.one('admin/users/' + id).get()
        })
        return Promise.all(promises)
            .then(allUsers => this.userObjects = allUsers)


    }

    sendAllMessges() {
        const self = this;
        const promises = _.map(this.userObjects, (user) => {

            const vars = {
                name: user.data.firstName
            };
            const parsedMessage = this.$compile(self.messageToSend)(vars);

            return this.Restangular.all('admin/users/' + user.data.id + '/sendwhatsapp')
                .post({
                    message: parsedMessage
                })
                .then(() => self.amountSend++);
        })

        return Promise.all(promises)

            ;

    }

    send() {

        if (!this.messageToSend) {
            alert('the message is required');
            return;
        }

        const self = this;
        return self.$confirm({ text: 'Sure? This cannot be undone!' })
            .then(() => this._getAllUsers())
            .then(() => this.sendAllMessges())
            .then(() => alert('all send!'))
            .catch((err) => err.message ?  alert('an error occured' + err.message): '')

    }


}

const component = {
    template: require('./multiMessage.component.html'),
    controller: Controller
};
export default  component;
