// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

class Controller {
    constructor(){

    }
}


const component = {
    template: `
    <div class="pull-left" style="padding-right:5px;">
     <div 
        uib-popover="{{$ctrl.text}}"  
        popover-trigger="'mouseenter'"
        popover-placement="right"     
     > <i class="fas fa-question-circle"></i></div>
    </div>
`,
    bindings: {
        text: '='
    },
    controller: Controller

};


export default  component;
