import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $http) {
        'ngInject';
        $scope.endpoint = 'users/me/availabilities';
        this.$http = $http;

        $scope.model = { date: new Date() };

        this.states = ['success', 'primary', 'complete', 'warning', 'danger', 'info','alternative', 'error','default','inverse'];

    }

}

const component = {
    template: require('./technical.component.html'),
    controller: Controller,
}

export default  component;
