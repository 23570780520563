// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

const component = {
    template: require('./login.component.html'),
    controller($state, $scope, settings, AuthenticationService) {
        'ngInject';
        const self = this;
        this.loginForm = {
            "client_id": settings.client_id,
            "client_secret": settings.client_secret,
            "grant_type": "password",
            "username": "",
            "password": ""
        };

        this.login = () => {
            if (self.loginForm.username === ''){
                return;
            }
            if (self.loginForm.username.indexOf('@') === -1){
                self.loginForm.username  = this.loginForm.username + '@volunteer-vision.com';
            }

            AuthenticationService.login(self.loginForm)
                .then(() => {
                    $state.go('admin.dashboard.index');
                })
                .catch((e)=>{

                })
        }
    }
};


export default  component;
