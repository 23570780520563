import RestHelperDetail from '../../lib/api/resthelperdetail.class'

class Controller {
    constructor($controller, $scope, $http, $stateParams, $uibModal, settings, StateMachineService, ConferenceService, MentorshipService) {
        'ngInject';

        let options = {
            name: 'Mentorship',
            defaultValues: {},
            params: { 'includes[]': ['mentorParticipation', 'menteeParticipation', 'program', 'appointments', 'licences'] }
        };
        this.$http = $http;
        this.$scope = $scope;
        this.StateMachineService = StateMachineService;
        this.$uibModal = $uibModal;

        this.id = $stateParams.id;
        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/mentorships', options);
        em.setDetailId($stateParams.id);
        em.run()

        this.em = em;
        this.MentorshipService = MentorshipService
        this.ConferenceService = ConferenceService
        this.settings = settings;

        this.activatedLicenceStates = ['DONE', 'CONFIRMED', 'ACTIVE', 'CANCELED', 'PAUSED'];
        this.pendingLicenceStates = ['NEW', 'UNCONFIRMED', 'APPROVED', 'REQUESTED'];
        this.hasLicenceStates = [...this.pendingLicenceStates, ...this.activatedLicenceStates];
    }


    refreshSessionCounter() {

        this.MentorshipService.refreshSessionCounter(this.id)
            .then(() => this.em.refresh())

    }

    openConfRoom(id) {

        this.ConferenceService.getPreviewRoomUrl(id, true)
            .then((url) => {
                window.open(url);
                return url;
            });
    }

    activateMentorship() {
        const self = this;
        this.StateMachineService.transition('mentorship', this.id, 'activate')
            .then(() => {
                self.em.refresh();
                alert('mentorship was activated');
            })
    }

    pauseMentorship() {
        const self = this;
        this.StateMachineService.transition('mentorship', this.id, 'pause')
            .then(() => {
                self.em.refresh();
                alert('mentorship was paused successfully');
            })
    }

    resumeMentorship() {
        const self = this;
        this.StateMachineService.transition('mentorship', this.id, 'resume')
            .then(() => {
                self.em.refresh();
                alert('mentorship was paused successfully');
            })
    }

    finishMentorship() {
        const self = this;
        this.StateMachineService.transition('mentorship', this.id, 'finish')
            .then(() => {
                self.em.refresh();
                alert('mentorship was set to finished');
            })
    }

    openCertificate(participation, mentorship) {
        window.open(this.settings.apiFullUrl + 'participations/' + participation.id + '/' + mentorship.hash + '/certificate');
    }

    async attachButtonClick(mentorship){
        await this.MentorshipService.attachLicence(mentorship.id)
        this.em.refresh();
    }

    openCancelOrMismatchDialog(mentorshipId) {
        const self = this;
        const modalInstance = this.$uibModal.open({
            component: 'cancelMentorshipWizard',
            resolve: {
                'mentorship-id': function () { return mentorshipId },
            },
        })

        modalInstance.result.then(() => {}, () => {
            self.em.refresh();
        })
    }
}

const component = {
    template: require('./mentorship.component.html'),
    controller: Controller
};
export default  component;
