import angular from 'angular';

const MODULE_NAME = 'demos';

import doDemo from './doDemo.component'


angular.module(MODULE_NAME, [])
    .component('demoComponent', doDemo )
    .config(config)


function config($stateProvider) {
    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.demo', {
            abstract: true,
            url: '/demos',
            template: '<ui-view></ui-view>',
            resolve: {
                datastore: (DataStore) => DataStore.ensureEntities(['programs'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }
        })
        .state('admin.demo.index', {
            url: '/do',
            component: 'demoComponent'
        })
}


export default MODULE_NAME;