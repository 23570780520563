import Constants from '../lib/constants'

/*
'userStates': ['NEW','REGISTERED','QUIT','BANNED'],
    'webinarStates': ['NEW','PLANNED','CANCELED','SUCCESS'],
    'mentorshipStates': ['NEW','UNCONFIRMED','CONFIRMED','REJECTED','ACTIVE','PAUSED','DONE','CANCELED'],
    'appointmentStates': ['NEW','PLANNED','CANCELED','NOSHOW','ONLINE','NOATTENDANCE','SUCCESS','HANGUP'],
    'organizationStates': ['NEW','READY','ACTIVE','EXIT'],
    'enrollmentStates': ['NEW','AVAILABLE','QUIT','ACTIVE','POSTPONED'],


 */

const translations =  {

    'ENROLLMENT_NEW': 'Enrollment: New',
    'ENROLLMENT_AVAILABLE': 'Enrollment: Available',
    'ENROLLMENT_ACTIVE': 'Enrollment: Active',
    'MENTORSHIP_NEW': 'Mentorship: NEW',
    'MENTORSHIP_UNCONFIRMED': 'Mentorship: UNCONFIRMED',
    'MENTORSHIP_CONFIRMED': 'Mentorship: Confirmed',
    'MENTORSHIP_ACTIVE': 'Mentorship: Active',
    'MENTORSHIP_DONE': 'Mentorship: Done',
    'MENTORSHIP_REJECTED': 'Mentorship was rejected',
    'MENTORSHIP_PAUSED': 'Paused',
    'MENTORSHIP_CANCELED': 'Mentorship was canceled',
}
class Controller {

    constructor() {
        this.states = null
        this.stateIndex = 0
    }

    $onInit(){
        this.states = this.getStatesForEntity()
        this.state = this.getActualState();
        this.stateIndex = this.states.indexOf(this.state) || 0

    }
    translate(text) {
        return translations[text]

    }
    getActualState() {
        if (this.participation.mentorship) {
            return 'MENTORSHIP_' + this.participation.mentorship.state;
        }
        if (!this.participation.enrollment){
            return 'NO_ENROLLMENT';
        }
        return 'ENROLLMENT_' + this.participation.enrollment.state;
    }
    getLabel($index){
        switch (true) {
            case $index <= this.stateIndex && $index < this.enrollmentStateLength:
                return 'label-info';

            case $index <= this.stateIndex && $index >= this.enrollmentStateLength:
                return 'label-success'

        }
        return 'label-default'
    }
    /**
     *
     * @param entityName
     * @returns {*}
     */
    getStatesForEntity(){
        let allStates = [];
        let enrollmentStates = Constants('enrollmentProcessStates');
        this.enrollmentStateLength = enrollmentStates.length;

        let mentorshipStates = Constants('matchProcessStates');
        _.map(enrollmentStates,(p)=> allStates.push('ENROLLMENT_' + p));
        _.map(mentorshipStates,(p)=> allStates.push('MENTORSHIP_' + p));

        return allStates

    }

}

const component = {
    template: `
    <div ng-if="$ctrl.states">
        <span 
            ng-if="$ctrl.states.indexOf($ctrl.state) !== -1" 
            ng-repeat="step in $ctrl.states" 
            uib-tooltip="{{$ctrl.translate(step)}}"
            ng-init="label = $ctrl.getLabel($index) "
            class="label {{ label }}"
            >
            <span ng-if="!$ctrl.hideLabels">{{step}}</span>&nbsp;
         </span>
        <span uib-tooltip="{{$ctrl.state}}" class="label label-info" 
            ng-if="$ctrl.states.indexOf($ctrl.state) === -1">{{$ctrl.translate($ctrl.state)}}
        </span>
        
        
    </div>
`,
//
    //One-way (“<”) data bindings; Expressions (“&”) bindings
    // Evaluated DOM attribute (“@”) bindings
    // = two way binding
    bindings: {
        participation: '<',
        hideLabels: '<',
    },
    controller: Controller
};


export default  component;
