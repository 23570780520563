import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'


let localEm;


class Controller {
    constructor($scope, $controller, $state, $httpParamSerializer) {
        'ngInject';
        let options = {
                name: 'Organization',
                defaultValues: {},
                filter: {
                    type: 'company',
                },
                params: {
                    usr_state: 'any',
                    search: '',
                    'includes[]': ['keyaccount'],
                    'withCount[]': ['users']
                },
                sort: { name: 'asc' }
            }
        ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/organizations', options);
        em.run();
        this.em = em;
        localEm = em;
        this.$scope = $scope;

        this.stateFilters = {
            'SUPPORTED': ['ACTIVE', 'ONBOARDING', 'NEW'],
            'OLD': ['EXIT', 'INACTIVE']
        };

        this.updateStateFilter('SUPPORTED');

        this.$state = $state

        this.sortOptions = [
            { label: 'Creation date', field: 'createdAt' },
            { label: 'Classification', field: 'classification' },
            { label: 'Name', field: 'name' },
            { label: 'Satisfaction', field: 'satisfactionLevel' },

            { label: 'Type', field: 'type' }
        ];

        // @todo: move to constants
        this.classifications = {
            'A': '>50.000 employees',
            'B': '>10.000 employees',
            'C': '<10.000 employees'
        };


    }

    /**
     * nice feature of custom server side operators!
     * @param value
     */
    updateStateFilter(value) {
        this.currentStateFilter = value;
        const validStates = this.stateFilters[value];
        const customFilter = { key: 'state', value: validStates, operator: 'in' }
        this.$scope.filter = this.$scope.filter || {};
        this.$scope.filter.state = customFilter;
        this.em.refresh();
    }

    reload() {
        setTimeout(() => localEm.refresh(), 50);
    }

    openReporting($event, companyId) {
        $event.stopPropagation();
        this.$state.go('^.reporting', { id: companyId })
    }

    openJf($event, companyId) {
        $event.stopPropagation();
        this.$state.go('^.jf', { id: companyId })
    }

}

const component = {
    template: require('./organizations.component.html'),
    controller: Controller,
}

export default  component;
