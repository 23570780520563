// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

const Translatons = {

    'validation.required': 'This field is required',
    'validation.exists':'Exists already',
    'validation.string':'Has to be a text value',
    'validation.filesize': 'The file is to large',
    'validation.no_file': 'File is missing',
    'validation.content_type': 'Content type is not allowed',
}

class Controller {

    translate(e){
        return Translatons[e] || e
    }
}

const component = {
    template: `
<div class="alert alert-danger" ng-if="$ctrl && $ctrl.errors">
    <span> Something went wrong </span><br/>
    <div><div ng-repeat="(key,error) in $ctrl.errors" ><p ng-repeat="e in error"><span>{{key}} : {{$ctrl.translate(e)}}</span></p></div>
</div>
`,
    bindings: {
        errors: '<'
    },
    controller:Controller


};


export default  component;
