export default function ($translateProvider) {
    'ngInject';

    $translateProvider.translations('en', {
        'common.days.day1': 'Monday',
        'common.days.day2': 'Tuesday',
        'common.days.day3': 'Wednesday',
        'common.days.day4': 'Thursday',
        'common.days.day5': 'Friday',
        'common.days.day6': 'Saturday',
        'common.days.day0': 'Sunday',
        'fileupload': {
            'state': {
                'WAITING': 'Waiting for a file to be chosen',
                'UPLOADING': 'Uploading the current file',
                'ERROR': 'An error has happened',
                'SUCCESS': 'The file was successfully uploaded',
            },
            'help': {
                'image': 'Please use a png or jpeg file'
            }
        },
        'FORM_INVALID': 'Form request invalid. Please check your input',
        'toastr': {
            'ACCESS_DENIED': {
                'hl':'Access denied',
                'text':'You do not have permission to see this'
            },
            'WHATSAPP_SEND': {
                'hl': 'Whatsapp send',
                'text': 'Whatsapp message was send'
            },
            'FAILED_REMOVING_QUESTION': {
                'hl': 'Error removing question',
                'text': 'Check if you removed all the answers first'
            },
            'TOO_MUCH_CORRECT_ANSWER': {
                'hl': 'Too many correct answer',
                'text': 'You already have correct answer'
            }
        }
    });


    $translateProvider.preferredLanguage('en');
    $translateProvider.useSanitizeValueStrategy('sanitize');



}
