
class Controller {
    constructor($controller, $http, $scope, $stateParams, StateMachineService) {
        'ngInject';

        this.$scope = $scope;
        this.$http = $http;
        this.issues = [];

        this.findIssues();
    }
    findIssues() {
        this.$http.get('/admin/webinars/issues')
            .then((result)=>{
                this.issues = result.data.warnings;

            })


    }

}


const
    component = {
        template: require('./webinarIssues.component.html'),
        controller: Controller
    };
export default component;
