class Controller {

    constructor($scope, $http, $uibModalStack, AlertsService) {
        'ngInject';

        this.$http = $http
        this.$uibModalStack = $uibModalStack
        this.suggestions = null
        this.isLoading = true
        this.AlertsService = AlertsService

    }

    $onInit() {
        this.participation = this.resolve.participation
        this.findMentorships()
            .then(() => this.isLoading = false)
    }


    findMentorships() {

        return this.$http.get('admin/participations/' + this.participation.id + '/match-suggestions')
            .then((res) => this.suggestions = _.map(res.data.data, (row) => row));

    }

    connectMentorship(suggestion) {
        const self = this
        this.isMatching = true

        return this.$http.post('admin/participations/' + this.participation.id + '/match', { participationId: suggestion.id })
            .then((res) => {
                    self.isMatching = false
                    self.AlertsService.showConstant('success', 'Successfully matched');
                    self.$uibModalStack.dismissAll()

                }
            )
            .catch((res) => {
                self.isMatching = false
            })
    }

    minToCol(min) {
        if (min > 100) {
            return this.perc2color(100);
        }
        if (min > 50) {
            return this.perc2color(50);
        }
        if (min > 10) {
            return this.perc2color(10);
        }
        return '#fff';
    }

    perc2color(percentage) {
        percentage = 1 - percentage;

        function shadeRGBColor(color, percent) {
            var f = color.split(","), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent,
                R = parseInt(f[0].slice(4)), G = parseInt(f[1]), B = parseInt(f[2]);
            return "rgb(" + (Math.round((t - R) * p) + R) + "," + (Math.round((t - G) * p) + G) + "," + (Math.round((t - B) * p) + B) + ")";
        }

        return shadeRGBColor('rbg(96,209,61)', percentage);


    }

    // perc2color(perc) {
    //     var r, g, b = 0;
    //     if(perc < 50) {
    //         r = 255;
    //         g = Math.round(5.1 * perc);
    //     }
    //     else {
    //         g = 255;
    //         r = Math.round(510 - 5.10 * perc);
    //     }
    //     var h = r * 0x10000 + g * 0x100 + b * 0x1;
    //     return '#' + ('000000' + h.toString(16)).slice(-6);
    // }
}

const component = {
    template: require('./findMentorship.component.html'),
    controller: Controller,
    bindings: {
        participation: '<',
        resolve: "<"
    }
}

export default  component;
