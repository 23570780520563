import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor(CurrentUserService) {
        'ngInject';

        this.isAdmin = CurrentUserService.hasRole('ADMIN');

    }
}

const component = {
    template: require('./reports.component.html'),
    controller: Controller,
}

export default  component;
