class Controller {

    constructor() {
        this.hideLabels = true;
    }

    $onInit() {

    }

    getClassForState(app) {

        switch (app.state) {
            case 'NEW':
            case 'PLANNED':
                return 'label-info'
            case 'PLANNED':
                return 'label-info'

            case 'NOSHOW':
                return 'label-danger'

            case 'NOATTENDANCE':
            case 'CANCELED':
            case 'HANGUP':
                return 'label-warning'


            case 'ONLINE':
                return 'label-success'
            case 'SUCCESS':
                return 'label-green'
        }


        // 'appointmentStates': ['NEW','PLANNED','CANCELED','NOSHOW','ONLINE','NOATTENDANCE','SUCCESS','HANGUP'],

    }
}

const component = {
    template: `
    <div >
    
        <div ng-if="!$ctrl.appointments || $ctrl.appointments.length === 0">
            No appointments yet.
        </div>
         <div ng-if="$ctrl.appointments && $ctrl.appointments.length > 0">

        <span   
            ng-repeat="app in $ctrl.appointments" 
            uib-tooltip="{{app.state}} {{app.plannedStart | date:'medium'}}"
            class="label {{$ctrl.getClassForState(app)}}">
                <span ng-if="!$ctrl.hideLabels">{{app.state}}</span>&nbsp;
         </span>
        </div>
    </div>
`,

    //One-way (“<”) data bindings; Expressions (“&”) bindings
    // Evaluated DOM attribute (“@”) bindings
    // = two way binding
    bindings: {
        appointments: '<'
    },
    controller: Controller
};


export default  component;
