import angular from 'angular';

const MODULE_NAME = 'messaging';


import smslogFeedback from './smslogFeedback.component'

angular.module(MODULE_NAME, [])
    .component('smslogFeedback', smslogFeedback )
    .config(config)


function config($stateProvider) {
    // $stateProvider
    //
    // //@todo there has to be a smarter way
    //     .state('admin.support', {
    //         abstract: true,
    //         url: '/support',
    //         template: '<ui-view></ui-view>',
    //         resolve: {
    //             // datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
    //             //ensureEntities(['Program', 'Company', 'Group'])
    //         }
    //     })
    //     .state('admin.support.index', {
    //         url: '/wizard',
    //         component: 'supportwizard'
    //     })
    //     .state('admin.support.wizard', {
    //         url: '/wizard',
    //         component: 'supportwizard'
    //     })

}


export default MODULE_NAME;