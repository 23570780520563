
class Controller {
    constructor() {
    }

}

const component = {
    template: `
<style>
.program-card  img {
    width:100%;

}
</style>
    <div class="card program-card">
    <img ng-src="{{$ctrl.program.cover.medium}}" >
    <div class="card-inner card-block">
        {{$ctrl.program.title}}
    </div>
     
     
     </div>
`,

    //One-way (“<”) data bindings; Expressions (“&”) bindings // Evaluated DOM attribute (“@”) bindings
    bindings: {
        program: '<',
    },
    controller: Controller
};


export default  component;
