import RestHelper from './resthelper.class'
import _ from 'lodash'


class RestHelperDetail extends RestHelper {


    constructor($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService) {
        'ngInject';
        super($log, $state, $confirm, $rootScope, RestangularFull, DataStore, AlertsService)
    }

    setup($scope, endpoint, options) {
        super.setup($scope, endpoint, options);
        this.$logger = this.$log.getInstance('[Rest-One][' + options.name || endpoint + ']');
    }

    run(id) {
        super.run()
        this.inject()
        this.refresh()

    }


    inject($scope) {
        super.inject()
        let self = this
        let scope = this.getScope()
        let id = self._setup.id

        scope.options = self.options;
        scope.model = null;

        if (!id || id === '') {
            // add
            scope.options.operation = 'Add';
            let defaults = angular.copy(self.options.defaultValues);
            scope.model = angular.extend(this.Restangular.one(self._setup.endpoint), defaults);
            self._setup.restclient = scope.model;
            scope.isLoading = false

        } else {
            scope.options.operation = 'Edit';
            scope.model = { id: id };
        }

        scope.refresh = () => this.refresh();

    }

    setDetailId(id) {
        let self = this;
        self._setup.id = id;

        if (!id || id === '') {
            return;
        }

        self._setup.restclient = this.Restangular.one(self._setup.endpoint, id);
    }

    getModel() {
        return this.getScope().model
    }

    saveAndNew() {
        return this.getModel().save()
            .then((res) => this.redirectIfNew(res.data))
            .then(() => this.$state.go("^.detail", { id: '' }));
    }

    save() {
        const self = this;
        let model = this.getModel();
        let scope = self.getScope();

        this.$logger.info(' >>> Saving model - model:', model);
        this._emitEvent('PRE_SAVE', model);


        scope.errors = null
        this.isSaving = true

        return model.save()
            .then(function (res) {
                self.isSaving = false


                if (!res.data) {
                    self.$logger.error(' >>> Error on Saving model - model:', model);
                    return;
                }
                self.AlertsService.showConstant('success', 'Successfully saved');
                self._emitEvent('AFTER_SAVE', res.data);
                self.redirectIfNew(res.data)


                return res.data;
            })
            .catch((res, b, c) => {

                self.isSaving = false
                //@todo: map error to form element if possible
                if (scope && res.data) scope.errors = res.data.errors;

                self.$logger.error(' >>> Catch on Saving model - model:', model, b, c);

            })
    }

    redirectIfNew(newData) {
        let scope = this.getScope();

        if (!scope.model.id && newData.id) {
            this.$state.go('^.detail', { id: newData.id });
        }

    }

    /**
     * Alias Only
     * @param model
     * @returns {*}
     */
    delete() {
        return this.remove()
    }

    remove() {
        const textSureDelete = 'Deleting something is dangerours\n\nMissing data can lead to user facing errors, missing data and wrong statistics. Please only delete if you are sure about what you do!';
        const self = this;
        return this.$confirm({ text: textSureDelete })
            .then(() => self.getScope().model.remove())
            .then(() => alert('successfully removed'))
            .then(() => history.back())
    }

    refresh(id, scope) {
        const self = this;

        scope = this.getScope() || scope;
        id = id || (scope.model ? scope.model.id : null);
        let query = _.extend({ id: id }, self.options.params)

        if (!id) {
            return;
        }
        this.$logger.info(' <<< Getting Single - query:', query);
        this._emitEvent('PRE_SINGLE_GET', query);



        return self._setup.restclient.get(query)
            .then(function (res) {

                let data = res.data;

                self._emitEvent('AFTER_SINGLE_GET', data, scope);

                scope.model = data;
                scope.isLoading = false;
                scope.lastReload = new Date();
                return data;

            }).catch(function (err) {
                scope.isLoading = false;
                self.$logger.error(' <<< Refreshing Single - query:', query, err);
            });


    }

    dismiss() {
        history.back()

    }

}

export default  RestHelperDetail;
