import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'
import CourseService from "./course.service";

class Controller {
    constructor($scope, $rootScope, $controller, CourseService) {

        'ngInject';
        this.$controller = $controller;
        this.$scope = $scope;
        this.newSection = { title: 'hello', description: 'hello' };
        this.CourseService = CourseService;
        this.$rootScope = $rootScope;

    }

    $onInit() {
        let { $controller, $scope } = this;
    }

    addAndAttachNewSection() {

        this.CourseService.createNewSection(this.newSection)
            .then((section) => this.CourseService.attachSectionToCourse(section, this.course.id)) // attach
            .then(() => this.$rootScope.$emit('refresh:model'))

    }
}

const component = {
    template: require('./course.sections.component.html'),
    controller: Controller,
    bindings: {
        course: '='
    }
}

export default  component;
