// let component = {
//     template: require('./sidebar.component.html'),
//     controller: ControllerSidebar,
//     scope:{}
//     // controllerAs: 'ctrl'
// };

const _ = require('lodash');

import moment from "moment";


class Controller {
    constructor($rootScope, $scope, $state, $http) {
        'ngInject';

        this.currentChart = 'appointmentsOverTime';


        this.$http = $http;

        this.kpis = null
        this.refresh()
        $scope.series = ['Count'];

        $scope.chartoptions =
            {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        ;
        // $scope.labels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];


        // $scope.data = [
        //     [65, 59, 80, 81, 56, 55, 40],
        //     [28, 48, 40, 19, 86, 27, 90]
        // ];

    }

    refresh() {
        this.$http.get('/admin/dashboardkpis')
            .then(res => this.postProcessKpis(res.data))

    }

    sortAndMapRows(rows, summaryFieldName) {
        rows.map(row => row.month = new Date(row.month));
        rows = _.sortBy(rows, 'month');


        return {
            labels: rows.map(p => moment(p.month).format('MM/YYYY')),
            data: [rows.map(p => p[summaryFieldName])],
        };

    }

    postProcessKpis(data) {
        this.charts = {}
        this.charts.feedback = { labels: true };

        this.charts.labels = data.feedbackOverTime.map(p => moment(p.month).format('MM/YYYY'));
        this.charts.data = [_.map(data.feedbackOverTime, p => p.rating)];


        this.charts.appointmentsOverTime = this.sortAndMapRows(data.appointmentsOverTime, 'count');
        this.charts.usersOverTime = this.sortAndMapRows(data.usersOverTime, 'count');
        this.charts.feedbackOverTime = this.sortAndMapRows(data.feedbackOverTime, 'rating');

        this.kpis = data

    }

    selectChart(name) {
        this.currentChart = name
    }

}

const
    component = {
        template: require('./dashboardkpis.component.html'),
        controller: Controller

    };


export default component;
