import angular from 'angular';

const MODULE_NAME = 'admin-tenant';

import TenantService from './tenancy.service'


angular.module(MODULE_NAME, [])
    // .component('tenantChooser', tenantchooser )
    .service('TenantService', TenantService )
    .config(config)


function config($stateProvider) {
    'ngInject';


}


export default MODULE_NAME;