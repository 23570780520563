import SettingsBase from "./settings.base";

class Settings extends SettingsBase {
    constructor($sceDelegateProvider) {
        'ngInject';
        super($sceDelegateProvider);
        //
        this.defaults()
    }

}

export default  Settings;


