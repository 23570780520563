const html = `<div> 
            <funnel-report-component></funnel-report-component>
        </div>`;

class Controller {

    constructor() {

    }

    $onInit() {


    }


}

const component = {
    template: html,
    controller: Controller
};


export default component;

