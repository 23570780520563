import angular from 'angular';

const MODULE_NAME = 'library';

import alertService from './uihelper/alerts.service'
import DataStore from './api/datastore.service'
import restangularConfig from './restangular.config'
import translations from './translation'
import showAdmin from './show.admin.directive'

import percentage from './filter/percentage'
import bytes from './filter/bytes'
import reverese from './filter/reverese.filter'
import secondsToDateTime from './filter/secondsToDateTime.filter'
import distance from './filter/distance.filter'
import trusted from './filter/trustedUrl.filter'
import 'sprintf-js'

import moment from "moment";
import translate from 'angular-translate'

import nl2br from './filter/nl2br.filter';


angular.module(MODULE_NAME, ['pascalprecht.translate'])
    .service('AlertsService', alertService)
    .directive('showAdmin', showAdmin)
    .service('DataStore', DataStore)
    .config(restangularConfig)
    .config(translations)
    .config(translations)
    .filter('percentage', percentage)
    .filter('distance', distance)
    .filter('bytes', bytes)
    .filter('reverse', reverese)
    .filter('trustedUrl', trusted)
    .filter('secondsToDateTime', secondsToDateTime)
    .filter('nl2br', nl2br)

// log enhancer //
    .provider('logEnhancer', logEnhancer)
    .config(function (logEnhancerProvider) {
        'ngInject';
        logEnhancerProvider.loggingPattern = '%s [%s]> ';
    })
    .run(function ($log, logEnhancer) {
        'ngInject';
        logEnhancer.enhanceAngularLog($log);
    })
    .factory('RestangularFull', function (Restangular) {
        'ngInject';
        return Restangular.withConfig(function (RestangularConfigurer) {
            RestangularConfigurer.setFullResponse(true);
        });
    })
    .filter('html', function ($sce) {
        'ngInject';
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    })
;


function logEnhancer() {
    this.loggingPattern = '%s - %s: ';

    this.$get = function () {
        var loggingPattern = this.loggingPattern;
        return {
            enhanceAngularLog: function ($log) {
                $log.getInstance = function (context) {
                    return {
                        log: enhanceLogging($log.log, context, loggingPattern),
                        info: enhanceLogging($log.info, context, loggingPattern),
                        warn: enhanceLogging($log.warn, context, loggingPattern),
                        debug: enhanceLogging($log.debug, context, loggingPattern),
                        error: enhanceLogging($log.error, context, loggingPattern)
                    };
                };

                function enhanceLogging(loggingFunc, context, loggingPattern) {
                    return function () {
                        var modifiedArguments = [].slice.call(arguments);
                        modifiedArguments[0] = [sprintf(loggingPattern, moment().format("HH:mm:ss"), context)] + modifiedArguments[0];
                        loggingFunc.apply(null, modifiedArguments);
                    };
                }
            }
        };
    };
}

export default MODULE_NAME;
