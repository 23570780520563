import RestHelperList from '../../lib/api/resthelperlist.class'
import constants from '../../lib/constants'

class Controller {
    constructor($scope, $controller, $stateParams, $httpParamSerializer, AuthenticationService, settings, CurrentUserService) {
        'ngInject';
        let options = {
                name: 'Program',
                defaultValues: {},
                params: {
                    usr_state: 'any', search: '', 'includes[]': [],
                },
                sort: { title: 'asc' }
            }
        ;
        let em = $controller(RestHelperList);
        em.setup($scope, 'admin/programs', options);
        em.run();
        this.AuthenticationService = AuthenticationService;
        this.settings = settings
        const user = CurrentUserService.getUser();

        const token = user.firebaseToken;

        let url = this.settings.conferenceAdminUrl + 'auth/' + user.firebaseToken;

// https://conference.volunteer-vision.com/room/17?lang=en&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjBmNGEwNTdlZTc2NzU2MTg1MzEwYWZmMTNlMzgwNGQ5NjdmNzdmZjljNzhiZjVjMmMxMzBkODg3MWU4MzUyYjFjYjIyYWYzZjBkMzU2ZjNjIn0.eyJhdWQiOiIxIiwianRpIjoiMGY0YTA1N2VlNzY3NTYxODUzMTBhZmYxM2UzODA0ZDk2N2Y3N2ZmOWM3OGJmNWMyYzEzMGQ4ODcxZTgzNTJiMWNiMjJhZjNmMGQzNTZmM2MiLCJpYXQiOjE1ODM5NDkxNTksIm5iZiI6MTU4Mzk0OTE1OSwiZXhwIjoxNjE1NDg1MTU5LCJzdWIiOiIxMjMiLCJzY29wZXMiOltdfQ.Ye0ARY7H3634Ey_Y3lxFf6o6_9oO-w0nGXSPmbpkvuUnNeWjptPsp3JTa-HCyuu5a2KFk3x41TecNH_WFXGwANAmC8CnMuKWCmAKcZ47ae6Y-4VSjnngrsIgNJpDMWLVzKgpowhusI8Iij0ewz8ewqpfXKE_MB1mWyHnRr3mncP-KTsX1-WydQEG9LaLHnnSCGiioDJ3lP3cMkPModMvYwYyryE1-AE-Qlg_oeQbmYFSps-pJHaTmyUceX2LEQAeCoqFrINTTE1ymUWBD5bw1XuhBFjS1xMbsB6X7ho4ekEn5ALXbm8JfJ0DcbS2gXSKFJcT2BSNxWXpz3X1qB-G_tXb_UG7mK6p5ezsJDNvY00mLYxiRShc6sdKIfxbjx76iePRyA31RnCOOdfVroeX7jmOSr2kNX9BsIy-0Uiq2CkPCGUsiqqEl2cqmKVcEkGHVYZnl7Migj5iMwUs9_FkSXy6vNF4M6KIvbTEKgMYeP2nO-DB0kBo9rwRFPiNMGmh7AG8gj5xEnsrT0BaAa_O73jtfQ-wWnuXK5GbbSv9uxFLzRT9RXq2HK9LnKO0651qoFlJpkKga0HDPHCBax9iKNKV2Ai6dheI1akv20Silr4r9aGweHe3D_WbtyH4wK4OGX-2iUuaXR4TR9b4wkaBZVEfFp3XewUH7_qu64gESuw&mode=noSync&language=en
        this.contentEditorUrl = url;


    }

    openContentEditor() {


    }
}

const component = {
    template: require('./programs.component.html'),
    controller: Controller,
}

export default  component;
