import angular from 'angular';

const MODULE_NAME = 'support';

import supportanswer from './supportanswerview.component'
import itcheck from './itcheck.component'


angular.module(MODULE_NAME, [])
    .component('supportinboundcall', require('./inboundcall.component').default  )
    .component('itcheck', itcheck )
    .component('supportanswerview', supportanswer )
    .component('supportwidget', require('./supportwidget.component').default )
    .component('inboundsupport', require('./inbound.component').default )
    .config(config)


function config($stateProvider) {
    $stateProvider

    //@todo there has to be a smarter way
        .state('admin.support', {
            abstract: true,
            url: '/support',
            template: '<ui-view></ui-view>',
            resolve: {
                // datastore: (DataStore) => DataStore.ensureEntities(['organizations'])
                //ensureEntities(['Program', 'Company', 'Group'])
            }
        })
        .state('admin.support.index', {
            url: '/wizard',
            component: 'supportwizard'
        })
        .state('admin.support.inbound', {
            url: '/inbound',
            component: 'inboundsupport'
        })
        .state('admin.support.itcheck', {
            url: '/itcheck',
            component: 'itcheck'
        })
        .state('admin.support.inboundcall', {
            url: '/inboundcall',
            component: 'supportinboundcall'
        })

}


export default MODULE_NAME;
