import RestHelperDetail from '../../lib/api/resthelperdetail.class'

import Constants from '../../lib/constants'


class Controller {
    constructor(DataStore, CurrentUserService) {
        'ngInject';
        const self = this
        this.CurrentUserService = CurrentUserService;

        DataStore.ensureEntity('ownusers')
            .then((users) => {
                self.users = DataStore.get('ownusers')
                self.updateSelection();
            })
        this.selectedUser = null;
    }

    $onInit() {
        this.updateSelection();
    }

    updateSelection() {
        if (this.userId && self.users) {
            this.selectedUser = _.find(self.users, { id: this.userId });
        }
        if (this.selectedUser && this.selectedUser.id){
            this.userId = selectedUser.id;
        }

    }

    selectMe() {
        this.userId = this.CurrentUserService.getUser().id
        this.updateSelection();
        this.changeEvent();
    }

    changeEvent(a, b, c) {

        if (this.selectedUser){
            this.userId = this.selectedUser.id;
            this.updateSelection();
        }


        if (this.onChange) {
            this.onChange(this.userId);
        }
    }
}

const template = `
<div >
    <span ng-if="!$ctrl.users" class="form-control"> Loading... </span>
    <div ng-if="$ctrl.users" class="text-right" >
    
    <!---->
                    <!--<input type="text" ng-model="$ctrl.selectedUser"-->
                            <!--typeahead-on-select="$ctrl.changeEvent($ctrl.selectedUser)"-->
                           <!--uib-typeahead="c as c.displayName for c in $ctrl.users | filter:$viewValue | limitTo:8"-->
                           <!--class="form-control">-->
                           
 <!--<ui-select ng-model="$ctrl.selectedUser"-->
             <!--title="Type the name">-->
    <!--<ui-select-match placeholder="The the username...">{{$select.selected.displayName}}</ui-select-match>-->
    <!--<ui-select-choices repeat="user in $ctrl.users | filter: $select.search" >-->
      <!--<div ng-bind-html="user.displayName | highlight: $select.search"></div>-->
    <!--</ui-select-choices>-->
  <!--</ui-select>-->
  <!---->
  <!--{{$ctrl.userId}}-->
                           <!---->
     <select   class="form-control"
                ng-model="$ctrl.userId"
                ng-change="$ctrl.changeEvent()"
                ng-options="c.id as c.displayName for c in $ctrl.users">
        </select>
               
        <a ng-click="$ctrl.selectMe()">Select me</a>
    </div>
</div>


`;
const component = {
    template: template,
    controller: Controller,
    bindings: {
        userId: '=',
        onChange: '<'
    },

};


export default  component;
