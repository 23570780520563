import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

import moment from "moment";


class Controller {
    constructor($controller, $scope, $http, $stateParams) {
        'ngInject';


        let lastQuarterNumber = 1;
        let lastQuarterStart = moment().subtract(lastQuarterNumber, 'quarter').startOf('quarter');
        let lastQuarterEnd = moment().subtract(lastQuarterNumber, 'quarter').endOf('quarter');

        let startDate = moment().subtract(2, 'quarter').startOf('quarter');

        this.quarters = [];
        for (var i = 0; i < 5; i++) {
            this.quarters.push(moment(startDate).add(i, 'quarter'));
        }


        // Start date of current quarter = moment().startOf('quarter');
        //
        // End date of current quarter = moment().endOf('quarter');

        this.startDate = lastQuarterStart;
        this.endDate = lastQuarterEnd;

        this.dateOptions = {
            maxDate: new Date(),
            datepickerMode: 'month'
        };

        this.$http = $http;
        this.refresh();
        this.fetchQuarterlyInformation();
    }

    /**
     *
     * @param quarter moment
     */
    setQuarter(quarter) {

        this.startDate = moment(quarter);
        this.endDate = moment(quarter).endOf('quarter');
        this.refresh();

    }

    showQuarter(quarter) {
        return (quarter.newRegistrationsByRole.length > 0);
    }


    fetchQuarterlyInformation() {
        this.$http.get('admin/reporting/investorkpisQuarterly')
            .then((res) => this.quarterly = this.postProcessQuarters(res.data))
    }

    /**
     * Calculates totals so far +
     */
    postProcessQuarters(quarters) {

        const totals = {
            reachedPeople: 0,
            newRegistrationsMentors: 0,
            newRegistrationsMentees: 0,
            mentorships: 0,
            mentorshipHours: 0,
        }

        // this is of the users of the pilot:
        totals.newRegistrationsMentors = 456;
        totals.newRegistrationsMentees = 107;
        totals.mentorships = 42;
        totals.mentorshipHours = 235;

        for (let i in quarters) {
            // quarters[i]
            let mentorRegistrationsCount = getRegistrationCount(quarters[i], 'mentor');
            let menteeRegistrationsCount = getRegistrationCount(quarters[i], 'mentee');
            const startDate = moment(quarters[i].start);


            totals.newRegistrationsMentors += mentorRegistrationsCount;
            totals.newRegistrationsMentees += menteeRegistrationsCount;
            totals.mentorshipHours += quarters[i].successfulSessionsCount.appointments;
            totals.mentorships += quarters[i].mentoringsWithOneSession

            quarters[i].mentorships = quarters[i].mentoringsWithOneSession;
            quarters[i].mentorshipsTotal = totals.mentorships;
            quarters[i].quarterName = 'Q' + startDate.quarter() + '/' + startDate.year();

            quarters[i].mentorRegistrationsDelta = mentorRegistrationsCount;
            quarters[i].mentorRegistrationsTotal = totals.newRegistrationsMentors;
            quarters[i].menteeRegistrationsDelta = menteeRegistrationsCount;
            quarters[i].menteeRegistrationsTotal = totals.newRegistrationsMentees;
            quarters[i].mentorshipHoursTotal = totals.mentorshipHours;


        }
        this.totals = totals

        function getRegistrationCount(quarter, role) {
            let row = quarter.newRegistrationsByRole.find(row => row.primaryRole === role)
            return row ? row.total : 0;
        }

        /*
        Input:
        {
    "start": "2017-04-01T00:00:00+00:00",
    "end": "2017-07-01T00:00:00+00:00",
    "reachedPeople": 0,
    "newRegistrationsByRole": [],
    "successfulSessionsCount": {
      "appointments": 0,
      "matcheswithsuccessfulappointment": 0
    },
    "activatedLicences": 0,
    "billableLicences": 0,
    "mentoringsWithOneSession": 0
  },
         */
        return quarters;

    }

    refresh() {
        let options = {
            params: {
                startDate: this.startDate,
                endDate: this.endDate,
            }
        };
        this.$http.post('APIadmin/reporting/investorkpis', options.params)
            .then((res) => this.processData(res.data))

    }

    processData(data) {
        const self = this;
        this.result = data;
        // newRegistrationsByRole
        this.registrations = {};

        _.each(data.newRegistrationsByRole, (r) => {
            if (!self.registrations[r.primaryRole]) {
                self.registrations[r.primaryRole] = {};
            }
            self.registrations[r.primaryRole].newRegistrations = r.total;
        });
        _.each(data.registeredUsersByRole, (r) => {
            if (!self.registrations[r.primaryRole]) {
                self.registrations[r.primaryRole] = {};
            }
            self.registrations[r.primaryRole].total = r.total;
        });

    }
}

const component = {
    template: require('./investorReport.component.html'),
    controller: Controller
};
export default  component;
