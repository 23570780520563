import RestHelperDetail from '../../lib/api/resthelperdetail.class'

let todoStateMachine = false;

let localEm;

class Controller {
    constructor($controller, $scope, $rootScope, $http, $stateParams, CurrentUserService, StateMachineService) {
        'ngInject';

        this.$http = $http;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.StateMachineService = StateMachineService;
        let options = {
            name: 'Todo',
            defaultValues: {},
            params: { 'includes[]': ['assignee', 'customer', 'creator'] }
        };

        let em = $controller(RestHelperDetail);
        em.setup($scope, 'admin/coordinatortodos', options);

        em.setDetailId($stateParams.id);
        em.run()

        this.em = em;
        localEm = em;
        this.stateMachineConfig = false;


        this.loadStatemachine();
        this.currentUserId = CurrentUserService.getUser().id;

    }
    autoSave() {
        setTimeout(() => localEm.save(), 50);

        ; //hack, but works;
    }


    isTransitionAllowed(transition) {
        if (!this.stateMachineConfig || !this.$scope.model) {
            return false;
        }

        if (!this.stateMachineConfig.transitions[transition]) {
            console.error('unknown transition', transition);
            return false;
        }
        if (this.stateMachineConfig.transitions[transition].from.indexOf(this.$scope.model.state) === -1) {
            return false;
        }
        return true;

    }

    loadStatemachine() {

        if (todoStateMachine) {
            this.stateMachineConfig = todoStateMachine;
        }
        this.$http.get('admin/statemachine/coordinatortodo')
            .then((res) => this.stateMachineConfig = todoStateMachine = res.data)

    }

    transition(todo, name) {
        const self = this
        this.StateMachineService.transition('coordinatortodo', todo.id, name)
            .then(() => this.$rootScope.$emit('refresh:all'))

        // .then(() => self.em.refresh())
        ;
    }

}

const component = {
    template: require('./todo.component.html'),
    controller: Controller
};
export default  component;
