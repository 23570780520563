import RestHelperDetail from '../../lib/api/resthelperdetail.class'
import Constants from '../../lib/constants'
import _ from 'lodash'

import moment from "moment";


class Controller {
    constructor($controller, $scope, $http, settings, DataStore, BookingService) {
        'ngInject';


        this.DataStore = DataStore;
        this.BookingService = BookingService;
        this.$scope = $scope;
        this.bookings = null;

        this.refresh();
    }

    

    async refresh() {

        const result  =await this.BookingService.getAllCompaniesOverview();
        this.bookings = result.data.data;
        this.$scope.$apply();

    }
    getClassForRow(row) {
        if (row.open < row.total * 0.1){
            return 'danger';
        }
        return '';
    }
}

const component = {
    template: require('./bookingReport.component.html'),
    controller: Controller
};
export default  component;
