import Constants from "../../lib/constants";

class Controller {
    constructor($scope, $http, settings) {
        'ngInject';
        this.$scope = $scope
        this.$http = $http;
        this.settings = settings;

    }

    reviewMessage(message) {

        let previewUrl = this.settings.apiBaseUrl + '/api/v2/admin/smslogs/' + message.id + '/review';
        this.$http.post(previewUrl)
            .then(()=> message.reviewedAt = new Date());


    }
}


const component = {
    template: `
 <div>

    <div ng-if="$ctrl.log.channel === 'whatsapp'">
        <whatsapp-status status="$ctrl.log.whatsappStatus"></whatsapp-status>

            <button ng-if="$ctrl.log.direction==='incoming' && !$ctrl.log.reviewedAt"
                    class="btn btn-success btn-xs"
                    ng-click="$ctrl.reviewMessage($ctrl.log)">I have responded to this message</button>

            <span class="badge badge-success" ng-if="$ctrl.log.reviewedAt">{{$ctrl.log.reviewedAt| date:'medium'}}</span>
    </div>

    <div ng-if="$ctrl.fullView">
        <span class="badge badge-info">{{$ctrl.log.direction}}</span>
        <span class="badge badge-info">{{$ctrl.log.channel}}</span>
        <span class="">{{$ctrl.log.to}} |
            {{$ctrl.log.createdAt | date:'medium'}}</span>
      </div>
  </div>

`,
    bindings: {
        log: '<',
        fullView: '<'
    },
    controller: Controller


};


export default  component;
