// In app.js or main.js or whatever:
// var myApp = angular.module('askchisne', ['ngSanitize', 'ngAnimate', 'ui.bootstrap', 'ui.bootstrap.tpls']);

// This filter makes the assumption that the input will be in decimal form (i.e. 17% is 0.17).
// Usage:
// <tr ng-repeat="i in items">
//     <td>{{i.statistic | bytes}}</td>
// </tr>

export default function () {
    'ngInject';

    return function(bytes, precision) {
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
        if (typeof precision === 'undefined') precision = 1;
        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
            number = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
    }
}
